import React,{useState} from "react";
import Container from "../globals/Container";
import { Globe, Location, People } from "../icons/icons";


export default function WorldWide(props) {

  const [readMore,setReadMore]=useState(false);

  // console.log(props,'worldwide')
  return (
    <Container>
    <div className="py-10 h-full lg:space-x-5 lg:space-y-0 space-y-4 lg:flex justify-center items-start">
        <div className="lg:h-[152px] lg:w-[152px]">
          <img
            src={props.img}
            alt="GEP"
            className="xl:max-w-[152px] max-w-[90px] md:max-w-48"
            style={{borderRadius:'12px',width:'100px',height:'100px'}}
          />
        </div>
        <div className="lg:space-y-2 space-y-1 h-full">
          <h1 className="font-bold dark:text-white text-lg md:text-2xl xl:text-[32px]">
            {props.companyName}
          </h1>
          <p className="xl:text-lg md:text-base text-sm dark:text-[#F9F6FB] text-[#454545]">
            {/* {!props.skip && (readMore)?props.description:props.description.slice(0,260)+'...'}
            {!props.skip && <span onClick={()=>setReadMore(!readMore)} className="underline font-bold text-[#843ECA]">
              {(!readMore)?"read more":""}
            </span>} */}
            {props.description}
          </p>
          {(readMore || props.skip) && 
          <div className="py-2 space-y-2">
              <div className="flex space-x-3 dark:text-[#F9F6FB] text-black">
                <Globe />
                <p className="text-[#257FF9] underline text-sm">{props.websiteUrl}</p>
              </div>
              <div className="flex space-x-3 dark:text-[#F9F6FB] text-black">
                <Location />
                <p className="text-sm dark:text-[#F9F6FB]">
                  {props.loc}
                </p>
              </div>
              <div className="flex space-x-3 dark:text-[#F9F6FB] text-black">
                <People />
                <p className="text-sm dark:text-[#F9F6FB]">{props.employees}</p>
              </div>
              <div onClick={()=>setReadMore(!readMore)} className="underline font-bold text-[#843ECA]">
              {(!readMore)?"":"read less"}
            </div>
            </div>
          }

        </div>
      </div>
    </Container>
  );
}
