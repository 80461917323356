import React, { useEffect, useState } from "react";
import Container from "../globals/Container";
import Card from "../globals/Card";


export default function FullReport({openModal,companyData,updateCompanyData,country,industry}) {


  const [cardData,setCardData]=useState([])

  

  console.log(companyData,'comp data')

  const getAppropriateCardColor=(score)=>{
    let val=parseInt(score);
    if(val<=20) return "[#08D176]"
    else if(val<=40) return "[#F4C65C]"
    else if(val<=60) return "[#F46A16]"
    else if(val<=80) return "[#FA372E]"
    return "[#C10303]"
  }
  const getAppropriateStatus=(score)=>{
    let val=parseInt(score);
    if(val<=20) return "Low"
    else if(val<=40) return "Medium"
    else if(val<=60) return "High"
    else if(val<=80) return "Very High"
    return "Extremely High"
  }
  
  useEffect(()=>{
    const data = [
      {
        id: 1,
        number: companyData?.people_attractiveness?companyData?.people_attractiveness:null,
        key:'people_attractiveness',
        color: getAppropriateCardColor(companyData?.people_attractiveness),
        text: getAppropriateCardColor(companyData?.people_attractiveness),
        status: getAppropriateStatus(companyData?.people_attractiveness),
        title: (
          <span>
            People <br className="lg:block hidden" /> Attractiveness
          </span>
        ),
        name:'People Attractiveness',
        desc: "Search for attractive human links within the organization for social engineering attacks.",
        risk_levels: companyData?.employees_risk_levels,
        tbl:'people'
      },
      // {
      //   id: 2,
      //   color: getAppropriateCardColor(companyData?.financial_attractiveness),
      //   text: getAppropriateCardColor(companyData?.financial_attractiveness),
      //   status: getAppropriateStatus(companyData?.financial_attractiveness),
      //   number: companyData?.financial_attractiveness?companyData?.financial_attractiveness:null,
      //   key:'financial_attractiveness',
      //   title: (
      //     <span>
      //       Financial <br className="lg:block hidden" /> Attractiveness
      //     </span>
      //   ),
      //   name:'Financial Attractiveness',
      //   desc: "Catalog valuable digital and physical assets for targeted theft or ransom.",
      //   risk_levels: companyData?.employee_risk_levels
      // },
      {
        id: 3,
        color: getAppropriateCardColor(companyData?.system_attractiveness),
        text: getAppropriateCardColor(companyData?.system_attractiveness),
        number: companyData?.system_attractiveness?companyData?.system_attractiveness:null,
        key:'system_attractiveness',
        status: getAppropriateStatus(companyData?.system_attractiveness),
        title: (
          <span>
            System <br className="lg:block hidden" /> Attractiveness
          </span>
        ),
        name:'System Attractiveness',
        desc: "Identify and exploit IT infrastructure vulnerabilities within the organization.",
        risk_levels: companyData?.systems_risk_levels,
        tbl:'systems'
      },
      {
        id: 4,
        color: getAppropriateCardColor(58),
        text: getAppropriateCardColor(58),
        status: getAppropriateStatus(58),
        key:'third_party_attractiveness',
        number: companyData?.third_party_attractiveness?companyData?.third_party_attractiveness:null,
        title:(
          <span>
            Third-Party <br className="lg:block hidden" /> Attractiveness
          </span>
        ),
        name:'Third-Party Attractiveness',
        desc: "Discover third-parties and how to use them to compromise the company.",
        risk_levels: companyData?.third_parties_risk_levels,
        tbl:'third parties'
      },
      {
        id: 5,
        color: getAppropriateCardColor(companyData?.attractiveness_score),
        text: getAppropriateCardColor(companyData?.attractiveness_score),
        number: companyData?.attractiveness_score?companyData?.attractiveness_score:null,
        key:'attractiveness_score',
        status: getAppropriateStatus(companyData?.attractiveness_score),
        title: (
          <span>
            Criminal <br className="lg:block hidden" /> Attractiveness
          </span>
        ),
        name:'Criminal Attractiveness',
        desc: "Combine people, assets, systems and third-parties into profitable attack vectors.",
        risk_levels: companyData?.attacks_risk_levels,
        tbl:'attacks'
      },
    ];
    // console.log(data,'print data')
    setCardData(data);
  },[companyData])

 

  const handleChangeCommited = (key, newValue) => {
    companyData[key]=newValue;
    // console.log(key,newValue,'updated')
    updateCompanyData(companyData)
  };
  
  return (
    <Container className="flex justify-center">
    <div className="md:block hidden space-y-2">
      <h1 className="font-bold dark:text-white text-lg md:text-2xl xl:text-[32px]">
        Threat Exposure Report
      </h1>
      <p className="xl:text-lg md:text-base text-sm dark:text-[#F9F6FB] text-[#454545]">
        This report takes the perspective of a profit-seeking attacker and provides an in-depth analysis of {companyData?.name}, focusing on People, Assets, Systems,
        <br />
        Third-Parties and Attack Vectors.
      </p>
    </div>
    <div className="py-3 grid xxl:grid-cols-4 lg:grid-cols-3 md:grid-cols-2 gap-5 justify-center">
      {cardData.length > 0 && 
        cardData.map((obj, index) => (
          <Card openModal={openModal} key={index} country={country} industry={industry} obj={obj} handleChangeCommited={handleChangeCommited} />
        ))}
    </div>
  </Container>
  );
}


