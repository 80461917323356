import React,{useEffect,useState} from "react";
import { Arrow, Cancel } from "../icons/icons";
import lock from "../../assets/lock.svg";
import less from "../../assets/arrow-up-left.svg";
import CardRadioGrp from "./CardRadioGrp";
import Slider from '@mui/material/Slider';
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import TextEditWrapper from "../TextEditWrapper";



export default function SystemCard({ unlockCard,data, match, setMatch , showImage, fr,isSelected,updateSystems,attack_vectors,changeTab}) {


  const [editable,setEditable]=useState(false);
  const [system,setSystem]=useState(data)
  const [value,setValue]=useState(data?.cardStatus?data?.cardStatus:0)
  const [intensity,setIntensity]=useState(data?.intensity)
  const [statusCheck,setStatusCheck]=useState(0);

  const {getToken}=useKindeAuth()

  const extractHexColor = (str) => {
    const startIndex = str.indexOf('#');
    if (startIndex === -1) return null; // '#' not found in the string
  
    return str.substring(startIndex, startIndex + 7);
  };





  const handleContentChange = (attr,newContent,idx) => {
  
  if(idx===-1){
     system[attr]=newContent; 
  }
  else{
      system[attr][idx]["data"]=newContent;
  }
   setSystem(system);
    updateSystems(system);
  };

  const handleSliderChange =(event, newValue) => {
    setIntensity(newValue);
  };
  
  const handleSliderChangeCommited= (event, newValue) => {
    setIntensityNew(newValue)
        setIntensity(newValue);
      };    


      const setIntensityNew=(val)=>{
        let sys=JSON.parse(JSON.stringify(data));
        sys.intensity=val;
        updateSystems(sys);
       }    

       const getAppropriateBg=()=>{
        if(!system?.risk_level || system?.risk_level==="0") return "bg-[#D9D9D9]"
        else if(system?.risk_level==="1") return "bg-[#08D176]"
        else if(system?.risk_level==="2") return "bg-[#F4C65C]"
        else if(system?.risk_level==="3") return "bg-[#F46A16]"
        return "bg-[#C10303]"
      }
  const getAVName=(id)=>{
    for(let i=0;i<attack_vectors?.length;i++){
      if(attack_vectors[i].id===id)
      return attack_vectors[i].name.substr(0,12)+((attack_vectors[i].name.length>12)?"...":"");
    }
    return "None"
  }

  const getCardData = async ()=>{
 
    let token= await getToken()
    try {
      let bdy=[system.uid];
      const response = await fetch(`https://scanner.x80security.com/reports/fetchCardsDetails`,
      {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
          // Add CORS headers
          "Access-Control-Allow-Origin": "*", // Allow requests from any origin
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS", // Allow specific HTTP methods
          "Access-Control-Allow-Headers": "Content-Type, Authorization", // Allow specific headers
          "Access-Control-Allow-Credentials": true // Allow credentials (cookies, authorization headers, etc.)
      },
       body:JSON.stringify({card_ids:bdy})
      });

      const responseData = await response.json();
      
      if (!response.ok) {

        throw new Error('Network response was not ok ' + response.statusText);
      }
      console.log(responseData?.system?.result,responseData?.result,'in get DAta')
      let data=responseData?.result[system.uid];
      if(data.cardStatus!==1){
        setSystem(data)
        setValue(data.cardStatus);
      }
      else{
        setStatusCheck(!statusCheck)
      }
      
      
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(()=>{ 

    if(system.cardStatus===1 || value===1){
      setTimeout(()=>{
        getCardData();
      },50000)
    }
  },[statusCheck,value])

  const getStaus=()=>{
    // let val=parseInt(intensity);
    if(!system?.risk_level || system?.risk_level==="0") return "Ready-to-Unlock"
    else if(system?.risk_level==="1") return "Low"
    else if(system?.risk_level==="2") return "Medium"
    else if(system?.risk_level==="3") return "High"
    return "Extremely High"
  }

let imgURL=system.screenshot?system.screenshot:'https://s44783.pcdn.co/in/wp-content/uploads/sites/3/2022/07/info-systems.jpg.optimal.jpg';
let preview=system.preview?system.preview:'https://s44783.pcdn.co/in/wp-content/uploads/sites/3/2022/07/info-systems.jpg.optimal.jpg';
    

function getCookie(name) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    const cookieParts = cookie.split('=');
    if (cookieParts[0] === name) {
      return cookieParts[1];
    }
  }
  return null;
}
useEffect(() => {
  const cookieValue = getCookie('editorAuth');
  if (cookieValue==='febf192c-8ff8-40ba-b18f-ca01bf119e7c') {
    setEditable(true);
  }
}, []);

useEffect(()=>{
  let sys=JSON.parse(JSON.stringify(data));
  sys.cardStatus=value;
  updateSystems(sys);
  },[value])
  
  return (
    <div className="relative h-full">
      <div  style={{borderWidth:(isSelected)?'2.5px':'0.1px',borderColor:(isSelected)?extractHexColor(getAppropriateBg()):'lightgray',borderStyle:'solid'}} className="w-full relative dark:bg-[#252229] space-y-3 px-4 pt-1 h-full overflow-hidden pb-6 shadow-lg rounded-[16px]">
        <div
          className={`flex mt-2 relative w-full ${
            system.uid === match ? "md:justify-center justify-end" : "justify-end"
          }`}
        >
          <button
              className={`rounded-lg md:text-sm ${
                system.uid === match ? "px-16 py-3" : "md:px-4 py-1 px-2 md:py-2"
              } text-xs text-white ${getAppropriateBg()}`}
          >
            {getStaus()}
          </button>
          <button
            onClick={() => setMatch(-1)}
            className={`h-11 text-[#2B2B2B] ${
              system.uid === match ? "md:flex hidden" : "hidden"
            } dark:bg-[#322E38] dark:text-white cursor-pointer absolute right-0 flex justify-center items-center bg-[#F1EEF4] rounded-full w-11`}
          >
            <Cancel />
          </button>
        </div>
        {/* {editable && <div>
            <CardRadioGrp value={value} setValue={setValue} />
          </div>} */}
        <div className="grid xxl:grid-cols-3 lg:grid-cols-2 grid-cols-1 mt-4 gap-3 xxl:gap-5">
          <div className={`space-y-3 ${system.uid !== match ? "col-span-3" : ""}`}>
            <div style={{visibility:!(value === 0 || value === 1)?"":"hidden"}}  className="mt-3 space-y-3">
              <img 
              onClick={()=>{
                showImage(imgURL);
            }}  
              
              src={preview} alt="" />
              <h1 className="font-semibold text-lg md:text-xl dark:text-[#F9F6FB]">
                <TextEditWrapper onContentChange={(content)=>{
                  
                  handleContentChange("title",content,-1);
              }} content={system?.title} />
              </h1>
            </div>

            <div className="space-y-1">
              <p className="text-[13px] text-[#888888]">Description</p>
              <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-normal lg:text-base text-sm">
              <TextEditWrapper onContentChange={(content)=>{
                  
                  handleContentChange("description",content,-1);
              }} content={system?.description.substr(0,140)} />
              </p>
            </div>

            {/* <div className="space-y-1">
              <p className="text-[13px] text-[#888888]">IP address</p>
              <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-normal lg:text-base text-sm">
                {system.ip}
              </p>
            </div> */}
            {system.findings.length>0 ? (
              <div>
                <p className="text-[13px] text-[#888888]">Findings</p>
                 
                 {system.findings.map((obj,_)=>{

                  return(<p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-semibold lg:text-base text-sm">
                    {`${obj.severity} - ${obj.count}`}
                  </p>)
                 })}
                 
              </div>
            ) : null}

           
{editable && <Slider value={intensity} 
                style={{width:'80%'}}
                max={100}
                onChangeCommitted={handleSliderChangeCommited}
                onChange={handleSliderChange} aria-labelledby="continuous-slider" />}
            <div
              onClick={() => setMatch(system.uid)}
              className={`text-[#843ECA] cursor-pointer flex space-x-2 items-center ${
                system.uid !== match ? "" : "hidden"
              }`}
            >
              <p className="font-semibold text-lg underline">Read more</p>
              <div className="mt-1">
                <Arrow />
              </div>
            </div>
          </div>
          <div
            className={`${
              system.uid === match ? "block" : "hidden"
            } mt-2 space-y-3`}
          >
             <p className="text-[13px] text-[#888888]">Attack Vectors</p>
              <div className="space-y-2 space-x-2">
                {system?.attack_vectors?.map((obj, _) => (
                  <button
                    className={`rounded-[40px] md:text-sm text-xs text-[#191919] md:py-2 bg-[#F1EEF4] md:px-4 py-1 px-2`}
                    onClick={()=>{
                      changeTab("attack-vectors",obj)
                    }}
                  >
                    {getAVName(obj)}
                  </button>
                ))}
                {!system?.attack_vectors?.length && 
                 <button
                 className={`rounded-[40px] md:text-sm text-xs text-[#191919] md:py-2 bg-[#F1EEF4] md:px-4 py-1 px-2`}
               >
                 None
               </button>}
             </div>
             <div
               
          >
          <p className="text-[13px] text-[#888888]">Exposed Files Count</p>
          <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-normal lg:text-base text-sm">
                {system.exposed_files_count}
          </p>
          </div>
          <div
             
          >
          <p className="text-[13px] text-[#888888]">Files Found Count</p>
          <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-normal lg:text-base text-sm">
                {system.files_found_count}
          </p>
          </div>
          <div
              
          >
          <p className="text-[13px] text-[#888888]">Valuable Files Count</p>
          <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-normal lg:text-base text-sm">
                {system.valuable_files_count}
          </p>
          </div>  


          <div
              
          >
          <p className="text-[13px] text-[#888888]">Exposed Endpoints</p>
          <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-normal lg:text-base text-sm">
                {system.exposed_endpoints}
          </p>
          </div>   
          <div className="">
              <p className="text-[13px] text-[#888888]">Additional Insights</p>
              <div className="mt-3">
              
              {system?.additional_insights?.map((obj,_)=>{
                if(_>0) return<></>
                return(
                <div>
                <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-semibold lg:text-base text-sm">
                  {obj.insight}
                </p>
                <ul
                  className={`"text-[#2B2B2B] pl-[20px] ${
                    system.uid === match ? "" : "truncate"
                  } dark:text-[#E6E0E9] font-normal list-disc  lg:text-base text-sm "`}
                >
                <li>
                {obj.data}
                </li>
                </ul>
                </div>)
              })}
                </div>
             
            </div>
          </div>
        

          <div
            className={`${
              system.uid === match ? "block" : "hidden"
            } mt-2 space-y-3`}
          >
            {system?.additional_insights?.map((obj,_)=>{
                if(_<=0 || _>=4) return<></>
                return(
                <div>
                <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-semibold lg:text-base text-sm">
                  {obj.insight}
                </p>
                <ul
                  className={`"text-[#2B2B2B] pl-[20px] ${
                    system.uid === match ? "" : "truncate"
                  } dark:text-[#E6E0E9] font-normal list-disc  lg:text-base text-sm "`}
                >
                <li>
                {obj.data}
                </li>
                </ul>
                </div>)
              })}
        </div>
        {/* <div
            className={`${
              system.uid === match ? "block" : "hidden"
            } mt-2 space-y-3`}
          >
            {system.additional_insights.map((obj,_)=>{
                if(_<=3 || _>=7) return<></>
                return(
                <div>
                <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-semibold lg:text-base text-sm">
                  {obj.insight}
                </p>
                <ul
                  className={`"text-[#2B2B2B] pl-[20px] ${
                    system.uid === match ? "" : "truncate"
                  } dark:text-[#E6E0E9] list-disc   font-normal lg:text-base text-sm"`}
                >
                  <li>
                {obj.data}
                </li>
                </ul>
                </div>)
              })}
        </div> */}
        
      
        </div>
        <div
          onClick={() => setMatch(-1)}
          className={`text-[#843ECA] cursor-pointer flex space-x-2 items-center ${
            system.uid === match ? "lg:hidden block" : "hidden"
          }`}
        >
          <p className="font-semibold text-lg underline">Read Less</p>
          <img src={less} alt="" />
        </div>
      </div>

      <div
        className={`w-full absolute rounded-[16px] h-full backdrop-blur-md bg-white/40 top-0  ${
          (value===0 || value===1) ? "" : "hidden"
        }`}
      >
        <div 
   style={{paddingRight:'20px'}}
          className={`flex mt-2 relative w-full ${
            system.uid === match ? "md:justify-center justify-end" : "justify-end"
          }`}
        >
          <button
              className={`rounded-lg md:text-sm ${
                system.uid === match ? "px-16 py-3" : "md:px-4 py-1 px-2 md:py-2"
              } text-xs text-white ${getAppropriateBg()}`}
          >
            {getStaus()}
          </button>
  </div>
  <div style={{textAlign:'center',marginTop:'20px'}} className="justify-center">
         <img   
          
              onClick={()=>{
                showImage(imgURL);
            }}  
              style={{width:'80%',border:'1px solid lightgray',margin:'auto'}}
              src={preview} alt="" />
              <h1 className="font-semibold text-lg md:text-xl dark:text-[#F9F6FB]">
                <TextEditWrapper onContentChange={(content)=>{
                  
                  handleContentChange("title",content,-1);
              }} content={system?.title} />
              </h1>
              <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 flex flex-col justify-center items-center">
      <img
        style={{ cursor: 'pointer', width: '38px', height: '38px' }}
        src={lock}
        alt="unlock"
      />
      <a
        onClick={async (e) => {
          if (value === 0) {
            let x=await unlockCard([system.uid], 'systems');
            console.log(x,'x logged')
            if(x){
              setStatusCheck(!statusCheck)
              setValue(1);
            }
            
          }
        }}
      >
        <h1
          style={{ cursor: 'pointer' }}
          className="text-[#843ECA] underline font-bold text-[20px]"
        >
          {value === 0 ? "Unlock" : "Unlocking in Progress"}
        </h1>
      </a>
    </div>
    </div>
      </div>
    </div>
  );
}
