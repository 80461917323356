import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';

const DataNotAvailableScreen = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <div className="bg-gray-200 dark:bg-gray-800 p-8 rounded-lg shadow-md text-center">
        {/* <img
          src={'/images/inprogress.jpeg'}
          alt="Data Not Available"
          className="mx-auto mb-4 max-w-full h-auto"
          style={{height:'240px'}}
        /> */}
        <div style={{textAlign:'center'}}>
              <CircularProgress style={{fontSize:'40px'}} />
          </div>
        <h2 className="text-2xl lg:text-3xl font-bold mb-4 text-gray-800 dark:text-white">We're working on it!</h2>
        <p className="text-lg lg:text-xl text-gray-600 dark:text-gray-400">
          We apologize, but the requested data is not currently available.
        </p>
        <p className="text-lg lg:text-xl mt-4 text-gray-600 dark:text-gray-400">
          Please check back later or explore other options while we work on your requested report!
        </p>
      </div>
    </div>
  );
};

export default DataNotAvailableScreen;