import React,{createContext,useState,useEffect} from 'react'
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";


export const GlobalDataContext = createContext()

const GlobalData = (props) =>{

    const [credits,setCredits] = useState(null)
    const {getToken}=useKindeAuth();
    

    const getCredits=async()=>{
        try {
           
            let api2='https://scanner.x80security.com/reports/getProfile'
            let token=await getToken();
            const response = await fetch(api2,
                {
                  method: 'GET',
                  headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`,
                    // Add CORS headers
                    "Access-Control-Allow-Origin": "*", // Allow requests from any origin
                    "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS", // Allow specific HTTP methods
                    "Access-Control-Allow-Headers": "Content-Type, Authorization", // Allow specific headers
                    "Access-Control-Allow-Credentials": true // Allow credentials (cookies, authorization headers, etc.)
                  }
                });
                if (!response.ok) {
                  throw new Error('Network response was not ok ' + response.statusText);
                }
                let data = await response.json();
                console.log(data.result,'result')
                setCredits(data.result);
                return data.result;
            
        } catch (error) {
            console.log('error in here')
        }
    }


    useEffect(()=>{
        getCredits();
    },[])

    return (
        <GlobalDataContext.Provider value={{credits,getCredits}}>
            {props.children}
        </GlobalDataContext.Provider>
    )
}



export default GlobalData;