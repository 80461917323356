export const orderReport = async (domain,level,token,bdy) => {
  
    
    try {
      const response = await fetch('https://scanner.x80security.com/reports/orderReport', {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
            // Add CORS headers
            "Access-Control-Allow-Origin": "*", // Allow requests from any origin
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS", // Allow specific HTTP methods
            "Access-Control-Allow-Headers": "Content-Type, Authorization", // Allow specific headers
            "Access-Control-Allow-Credentials": true // Allow credentials (cookies, authorization headers, etc.)
        },
        body:JSON.stringify(bdy)
      });
      const responseData = await response.json();
      console.log(responseData);
      return responseData;
   
    } catch (error) {
       throw error;  
    }
  };