// import {makeStyles} from "@mui/styles"
import React, { useState, useEffect, useCallback,useRef } from "react";
import Dialog from '@mui/material/Dialog';
import Grid from '@mui/material/Grid';
import ThirdParties from './ThirdParties/ThirdParties'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import TextEditWrapper from "./TextEditWrapper";
import WorldWide from "./WorldWide/WorldWide";
import FullReport from "./FullReport/FullReport";
import IconButton from '@mui/material/IconButton';
import { Arrow } from "./icons/icons";
import CompanyOverView from "./CompanyOverView/CompanyOverView";
import CloseIcon from '@mui/icons-material/Close';
import { useTheme } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import People from "./People/People";
import Assets from "./Assets/Assets";
import Systems from "./Systems/Systems";
import Threats from "./AttackVector/Threats";
import Snackbar from '@mui/material/Snackbar';
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import CustomModal from "./CustomModal";
import useGlobalContext from "../hooks/useGlobalContext";
// const useStyles = makeStyles({
// 	root: {
// 		padding:20
// 	},
// });
let imgurl='https://media.licdn.com/dms/image/D4D03AQEeeB6UbFg33A/profile-displayphoto-shrink_800_800/0/1677967819119?e=2147483647&v=beta&t=VLvmpIEnbvHuP6A02QbgdKpk3In6TFfBolz6OkrQr-c'


const ReportBody= (props)=>{

    const [expanded,setExpanded]=useState(false);
    const isInitialMount = useRef(0);
    const isInitialMountDomain = useRef(true);
    const [viewImg,setViewImg]=useState(false)
    const [imgUrl,setImgUrl]=useState('');
    const [attack_vectors,setAttackVectors]=useState(props?.companyData?.attack_vectorss?props?.companyData?.attack_vectorss?.sort((a,b)=> {
       let av=parseFloat(a.intensity)?parseFloat(a.intensity):0;
       let bv=parseFloat(b.intensity)?parseFloat(b.intensity):0;
      //  console.log(a,b,a.intensity,b.intensity,'av sorting')
       return bv-av;
    }):[]);
    const [employees_info,setEmployeesInfo]=useState(props?.companyData?.employees_info?props?.companyData?.employees_info.sort((a,b)=> {
      let av=parseFloat(a.intensity)?parseFloat(a.intensity):0;
      let bv=parseFloat(b.intensity)?parseFloat(b.intensity):0;
      return bv-av;
         }):[]);
    const [systems,setSystems]=useState(props?.companyData?.systemss?props?.companyData?.systemss.sort((a,b)=> {
      let av=parseFloat(a.intensity)?parseFloat(a.intensity):0;
      let bv=parseFloat(b.intensity)?parseFloat(b.intensity):0;
      return bv-av;
     }):[]);
     const [assets,setAssets]=useState(props?.companyData?.assets?props?.companyData?.assets?.sort((a,b)=> {
      let av=parseFloat(a.intensity)?parseFloat(a.intensity):0;
      let bv=parseFloat(b.intensity)?parseFloat(b.intensity):0;
      return bv-av;
     }):[]);
     const [thirdParties,setThirdParties]=useState(props?.companyData["third-parties"]?props?.companyData["third-parties"]?.sort((a,b)=> {
      let av=parseFloat(a.intensity)?parseFloat(a.intensity):0;
      let bv=parseFloat(b.intensity)?parseFloat(b.intensity):0;
      return bv-av;
   }):[]);
    const [appendix,setAppendix]=useState(props.companyData?.appendix);
    const [reportRequestStatus,setReportRequestStatus]=useState(0);
    const [companyName,setCompanyName]=useState(props.companyData?.name);
    const [description,setDescription]=useState(props.companyData?.description);
    const [websiteUrl,setWebsiteUrl]=useState(props.companyData?.website_url);
    const [location,setLocation]=useState(props.companyData?.location);
    const [numEmployees,setNumEmployees]=useState(props.companyData?.num_employees);
    const [editable,setEditable]=useState(false);
    const [viewFullReport,setViewFullReport]=useState(false);
    const [modalCompany,setModalCompany]=useState(props?.metaData.name)
    const [displayMsg,setDisplayMsg]=useState('');
    const [openModal,setModalOpen]=useState(false);
    const [open, setOpen] = React.useState(false);
    const {credits}=useGlobalContext();
    const {getToken}=useKindeAuth();
    const handleClick = () => {
      setOpen(true);
    };
  
    const handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setOpen(false);
    };

    const [currentAV,setCurrentAV]=useState(-1);

    const [currentTab,setCurrentTab]=useState('people');
    const [readMore,setReadMore]=useState(false);



    const theme=useTheme();
   
    function getCookie(name) {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          const cookieParts = cookie.split('=');
          if (cookieParts[0] === name) {
            return cookieParts[1];
          }
        }
        return null;
      }
    useEffect(() => {
        const cookieValue = getCookie('editorAuth');
        if (cookieValue==='febf192c-8ff8-40ba-b18f-ca01bf119e7c') {
          setEditable(true);
        }
      }, []);
    



   
    // const getReportRequestStatus = async ()=>{
    //     try {
          
    //         const response = await fetch('https://x80-text-editor-backend.vercel.app/reportRequest/getStatus?uuid='+props.uuid,
    //         {
    //           method: 'GET',
    //           headers: {
    //             'Content-Type': 'application/json',
    //           },
    //         });
    //         if (!response.ok) {
    //           throw new Error('Network response was not ok ' + response.statusText);
    //         }
    //         const data = await response.json();
    //         setReportRequestStatus(data.status);
            
    //         // await loadText(entityList);
    //       } catch (error) {
    //         console.error('There has been a problem with loading:', error);
    //       }
    // }


    useEffect(()=>{
      console.log(isInitialMount.current,'is initial mount use effeect 1')
      if (isInitialMount.current<2) {
        isInitialMount.current = isInitialMount.current +1
      } else {
        if(!props.companyData) return;
        let companyData=JSON.parse(JSON.stringify(props.companyData));
        companyData.attack_vectors=attack_vectors;
        companyData.employees_info=employees_info;
        companyData.systems=systems;
        companyData.appendix=appendix;
        companyData.assets=assets;
        companyData["third-parties"]=thirdParties;
        props.updateCompanyData(companyData)
      }

    },[attack_vectors,employees_info,systems,appendix,assets,thirdParties])

    // useEffect(()=>{
    //   getReportRequestStatus();  
    // },[])

    useEffect(()=>{
      if (isInitialMountDomain.current) {
        isInitialMountDomain.current = false;
      } else {
        let companyData=JSON.parse(JSON.stringify(props.companyData));
        companyData.websiteUrl=websiteUrl;
        companyData.description=description;
        companyData.num_employees=numEmployees;
        companyData.location=location;
        companyData.companyName=companyName;
     
        props.updateCompanyData(companyData)
      }
    },[websiteUrl,description,companyName,numEmployees,location])




    const updateAttackVector=(newAttackVector,shouldSaveReport)=>{
        let uid=newAttackVector.uid;
        let newAttackVectors=[];
        for(let i=0;i<attack_vectors.length;i++){
            if(attack_vectors[i].uid===uid){
                newAttackVectors.push(newAttackVector);
            }
            else{
                newAttackVectors.push(attack_vectors[i]);
            }
        }
        let companyData=JSON.parse(JSON.stringify(props.companyData));
        companyData.attack_vectors=attack_vectors;
        setAttackVectors(newAttackVectors);
        props.updateCompanyData(companyData,shouldSaveReport)
    }
    const updateContactInfo=(newContact)=>{
        let newContacts=[];
        let uid=newContact.uid;
        for(let i=0;i<employees_info.length;i++){
            if(employees_info[i].uid===uid){
                newContacts.push(newContact);
            }
            else{
                newContacts.push(employees_info[i]);
            }
        }
        setEmployeesInfo(newContacts);
    }

    const updateAsset=(newAsset)=>{
        let newAssets=[];
        let uid=newAsset.uid;
        // console.log(newAsset,'new asset')
        for(let i=0;i<assets.length;i++){
            if(assets[i].uid===uid){
                newAssets.push(newAsset);
            }
            else{
                newAssets.push(assets[i]);
            }
        }
        setAssets(newAssets);
    }
    const updateSystems=(newSystem)=>{
        let uid=newSystem.uid;
        let newSystems=[];
        for(let i=0;i<systems.length;i++){
            if(systems[i].uid===uid){
                newSystems.push(newSystem);
            }
            else{
                newSystems.push(systems[i]);
            }
        }
        setSystems(newSystems);
    }
    const updateThirdParties=(data)=>{
      let uid=data.uid;
      let newThirdParty=[];
      for(let i=0;i<thirdParties.length;i++){
          if(thirdParties[i].uid===uid){
              newThirdParty.push(data);
          }
          else{
              newThirdParty.push(thirdParties[i]);
          }
      }
      setThirdParties(newThirdParty);
  }

    const handleAppendixChange=(attr,content,index)=>{
        // console.log(attr,content,index,'lgoggg')
        if(index<=-1){
       return;
         }
         else{
    
             appendix[index][attr]=content;
         }
        //  console.log(appendix,'appendix set');
          setAppendix(JSON.parse(JSON.stringify(appendix)));
       
    }
    const showImage=(imgUrl)=>{
      // console.log('show image')
      setImgUrl(imgUrl)
      setViewImg(true);
  }
  const unShowImage=()=>{
      setViewImg(false);
  }

    const unShowReportReq=()=>{
        setViewFullReport(false);
    }
    const getTxtClr=()=>{
        return (props.theme==='dark')?'white':'rgb(25, 25, 25)'
       }
       const getBgClr=()=>{
         return (props.theme==='dark')?'rgb(25, 25, 25)':'white'
        }
        const TextComponent=(props)=>{
            return(
                <div style={{marginTop:'5px',style:'inline-block'}}>
                <span><p style={{fontSize:'0.8rem',fontWeight:'bold',color:getTxtClr()}}>
                    
                   
                    <TextEditWrapper onContentChange={(content)=>{
                    handleAppendixChange("title",content,props.idx);
                }} content={props.title} />
                    
                    </p></span>
                <span><p style={{fontSize:'0.8rem',fontWeight:'500',color:'#be87f4',fontStyle:(props.i)?'italic':'normal'}}>
                    
                    
                <TextEditWrapper onContentChange={(content)=>{
                    handleAppendixChange("value",content,props.idx);
                }} content={props.value} /></p></span>
                </div>
            )
        }


    const HeaderComp = (props)=>{
        return(<p style={{color:getTxtClr(),fontSize:'1.2rem',fontWeight:'bold',marginLeft:'1.5%',marginTop:'3%',display:'inline-block'}}>{props.title} {getAppropriateIcon(props.in)}</p>)
    }    

    const getAppropriateIcon = (isExpanded)=>{

        return((isExpanded)?<ExpandLessIcon style={{float:'right',fontSize:'1.4rem'}}/>:<ExpandMoreIcon style={{float:'right',fontSize:'1.4rem'}} />)
    }

    const deleteAttackVector=(uid)=>{
        console.log('new attack vector')
       let newAttackV= attack_vectors.filter((item)=>{
            return item.uid!==uid;
        })
        // console.log(newAttackV,'new attack ')
        setAttackVectors(newAttackV);
    }
    const deleteSystemsItem=(uid)=>{
       let newSys= systems.filter((item)=>{
            return item.uid!==uid;
        })
        console.log(newSys,'new sys ')
        setSystems(newSys);
    }
    const deleteContact=(uid)=>{
        let newContacts= employees_info.filter((item)=>{
             return item.uid!==uid;
         })
         setEmployeesInfo(newContacts);
     }
     const deleteAsset=(uid)=>{
        let newAssets= assets.filter((item)=>{
             return item.uid!==uid;
         })
         setAssets(newAssets);
     }


    const ScoreComponent=(props)=>{
        return(
           
                <div > 
                <Tooltip placement="bottom-start" title={props.tooltip} arrow>
                <p style={{fontSize:'0.95rem',fontWeight:'bold'}}>{props.title} : <span style={{fontWeight:'bold',color:getColorForValue(props.value)}}>{props.value}</span></p>
                </Tooltip>
            </div>
           

          
        )
    } 


    const action = (
        <React.Fragment>
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </React.Fragment>
      );


      const unlockCard=async (ids,section)=>{
        try {
          let token= await getToken();
          const response = await fetch(
            `https://scanner.x80security.com/reports/unlockDomainCards/${props.domain}/${section}` ,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
                // Add CORS headers
                "Access-Control-Allow-Origin": "*", // Allow requests from any origin
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS", // Allow specific HTTP methods
                "Access-Control-Allow-Headers": "Content-Type, Authorization", // Allow specific headers
                "Access-Control-Allow-Credentials": true // Allow credentials (cookies, authorization headers, etc.)
            },
              body:JSON.stringify({          
                card_ids:ids
              })
            },
          );
          let data = await response.json();
          if(data.result[0].status==='failed'){
            setOpen(true);
            setDisplayMsg('Not enough credits to unlock. Remaining credits:'+credits?.remaining_credits)
            return false;
          }
          return true;
          
        } catch (error) {
         console.log(error); 
        }
      }

    function getAppropriateTab(){
      
      if(currentTab==='people') return <People 
      reportOpts={props.reportOpts}
      startScan={props.startScan}
      domain={props.domain}
      invokeSnack={(msg)=>{
        setDisplayMsg(msg);
        setOpen(true);
      }} fr={props.companyData.full_report} changeTab={changeTab} unlockCard={unlockCard} attack_vectors={attack_vectors.map(item=>{return{id:item.uid,name:item.name}})} updateContactInfo={updateContactInfo} data={employees_info} showImage={showImage} unShowImage={unShowImage}/>
      else if(currentTab==='systems') return <Systems 
      startScan={props.startScan}
      domain={props.domain}
      reportOpts={props.reportOpts}
      invokeSnack={(msg)=>{
        setDisplayMsg(msg);
        setOpen(true);
      }} fr={props.companyData.full_report} changeTab={changeTab} unlockCard={unlockCard}   attack_vectors={attack_vectors.map(item=>{return{id:item.uid,name:item.name}})} updateSystems={updateSystems} data={systems} showImage={showImage} unShowImage={unShowImage}/>
      else if(currentTab==='assets') return <Assets 
      startScan={props.startScan}
      domain={props.domain}
      reportOpts={props.reportOpts}
      fr={props.companyData.full_report} changeTab={changeTab} unlockCard={unlockCard}  attack_vectors={attack_vectors.map(item=>{return{id:item.uid,name:item.name}})} updateAsset={updateAsset} data={assets}/>
      else if(currentTab==='attack-vectors') return <Threats 
      startScan={props.startScan}
      domain={props.domain}
      reportOpts={props.reportOpts}
      invokeSnack={(msg)=>{
        setDisplayMsg(msg);
        setOpen(true);
      }} darkMode={props.darkMode} fr={props.companyData.full_report} unlockCard={unlockCard}  currentAV={currentAV} systems={systems.map((item)=>{
        return {id:item.uid,name:item.title}})} people={employees_info.map(item=>{return{id:item.uid,name:item.name}})} assets={assets.map(item=>{return{id:item.uid,name:item.name}})} updateAttackVector={updateAttackVector} data={attack_vectors}/>
      else if(currentTab==='third-parties') return <ThirdParties
      openModal={(company)=>{
        setModalOpen(true)
        setModalCompany(company)
      }}
      unlockCard={unlockCard} 
      reportOpts={props.reportOpts}
      domain={props.domain}
      startScan={props.startScan}
      invokeSnack={(msg)=>{
        setDisplayMsg(msg);
        setOpen(true);
      }} fr={props.companyData.full_report} updateThirdParties={updateThirdParties} data={thirdParties} changeTab={changeTab} attack_vectors={attack_vectors.map(item=>{return{id:item.uid,name:item.name}})} showImage={showImage} />
    }

    function interpolateColor(color1, color2, factor) {
        const result = color1.map((c, i) => {
            return Math.round(c + (color2[i] - c) * factor);
        });
        return result;
    }
    
    function getColorForValue(value) {
        const green = [0, 255, 0];
        const yellow = [255, 255, 0];
        const orange = [255, 165, 0];
        const darkOrange = [255, 140, 0];
        const red = [255, 0, 0];
    
        let color;
        if (value < 25) {
            color = interpolateColor(green, yellow, value / 25);
        } else if (value < 50) {
            color = interpolateColor(yellow, orange, (value - 25) / 25);
        } else if (value < 75) {
            color = interpolateColor(orange, darkOrange, (value - 50) / 25);
        } else {
            color = interpolateColor(darkOrange, red, (value - 75) / 25);
        }
    
        return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
    }

    const changeTab=(tab,av_id)=>{
      // console.log(tab,'setting current tab')
      setCurrentTab(tab);
      setCurrentAV(av_id)
    }

    const toggleModal = () => {
      setModalOpen(false);
    };
    
    // console.log(props.companyData,'company data')

    return(
        <div >
      <WorldWide websiteUrl={props.metaData.websiteUrl} loc={props.metaData.location} employees={props.metaData.num_employees} img={props.metaData.logo} companyName={props.metaData?.name} description={props.metaData?.description} />
      <FullReport openModal={()=>{
          setModalCompany(props.domain)
          setModalOpen(true)
        }}  country={props?.country} industry={props?.industry}  updateCompanyData={props.updateCompanyData} companyData={props.metaData} />
      {/* {props.darkMode?<CompanyOverViewDark tab={currentTab} changeTab={changeTab}/>:<CompanyOverView  tab={currentTab} changeTab={changeTab}/>} */}
      

      
        <div style={{marginTop:'2%'}} className="flex justify-center items-center">
        <button 
        
        onClick={()=>{
          setModalCompany(props.domain)
          setModalOpen(true)
        }}
        className="md:w-[256px] w-[217px] space-x-3 font-semibold md:text-lg text-base dark:bg-[#F9F6FB] dark:text-[#2B2B2B] h-[49px] flex justify-center items-center rounded-[40px] bg-[#1F0048] text-white">
        <a href="#unlock">
          <p>Order Full Report</p>
          </a>
          <div className="mt-1">
            <Arrow />
          </div>
        </button>
        </div>
      
      <CompanyOverView p_cnt={props?.metaData?.employees} s_cnt={props?.metaData?.systems} tp_cnt={props?.metaData?.third_parties} th_cnt={props?.metaData?.attack_vectors} tab={currentTab} changeTab={changeTab}/>
      {getAppropriateTab()}
      <div style={{marginTop:'0%'}} className="flex justify-center items-center">
        <button 
        
        onClick={()=>{
          setModalCompany(props.domain)
          setModalOpen(true)
        }}
        className="md:w-[256px] w-[217px] space-x-3 font-semibold md:text-lg text-base dark:bg-[#F9F6FB] dark:text-[#2B2B2B] h-[49px] flex justify-center items-center rounded-[40px] bg-[#1F0048] text-white">
        <a href="#unlock">
          <p>Order Full Report</p>
          </a>
          <div className="mt-1">
            <Arrow />
          </div>
        </button>
        </div>
      {/* {!props.companyData.full_report && <AboutUs darkMode={props.darkMode} />} */}
      {/* {!props.companyData.full_report && <Trial />} */}
      <Dialog onClose={unShowImage} open={viewImg}>
            <Grid container>
            <Grid item xs={12} md={12} style={{textAlign:'center'}}>
            <img  src={imgUrl} width={'100%'} alt="Logo" />    
            </Grid>
            </Grid> 
        </Dialog>
        <CustomModal onSuccess={()=>{
          console.log('success')
        }} company={modalCompany} isOpen={openModal} onClose={toggleModal} />
        <Snackbar
        open={open}
        autoHideDuration={4000}
        onClose={handleClose}
        message={displayMsg}
        action={action}
        key={'bottomright'}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />

        </div>
    )

}
export default ReportBody;



