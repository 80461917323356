import React, { useEffect } from "react";
import Ellipse from "../../assets/ellipse.svg";
import { useState } from "react";

export default function Request() {
  const [state, setState] = useState();
  const [flyer, setFlyer] = useState(false);

  useEffect(() => {
    const mode = JSON.parse(localStorage.getItem("darkMode"));
    setState(mode);
  }, []);
  return (
    <div className="rounded-[30px] relative flex flex-col items-center py-5 h-full dark:bg-[#322E38] shadow-lg">
      <div
        className={`${
          state ? "absolute left-0 top-0 overflow-hidden" : "hidden"
        }`}
      >
        <img src={Ellipse} alt="" className="rounded-3xl" />
      </div>
      <div className="space-y-5 p-5">
        <h1 className="lg:text-4xl md:text-2xl dark:text-white  text-xl font-bold">
          Request to see X80's Threat Exposure Report
        </h1>
        <div className="lg:flex lg:space-x-5 lg:space-y-0 space-y-5">
          <div className="w-full space-y-1">
            <label
              htmlFor="firstName"
              className="font-regular dark:text-white text-base"
            >
              First name
            </label>
            <input
              type="text"
              className="md:h-12 h-10 w-full dark:bg-transparent rounded-2xl border"
            />
          </div>
          <div className="w-full space-y-1">
            <label
              htmlFor="lastName"
              className="font-regular dark:text-white text-base"
            >
              Last name
            </label>
            <input
              type="text"
              className="md:h-12 h-10 dark:bg-transparent w-full rounded-2xl border"
            />
          </div>
        </div>
        <div className="w-full space-y-1">
          <label
            htmlFor="companyEmail"
            className="font-regular dark:text-white text-base"
          >
            Company Email
          </label>
          <input
            type="text"
            className="md:h-12 h-10 dark:bg-transparent w-full rounded-2xl border"
          />
        </div>

        <div>
          <div className="w-full space-y-2">
            <label
              htmlFor="request"
              className="font-regular dark:text-white text-base"
            >
              Select a reason for requesting the Threat Exposure Report
            </label>
            <div className="relative z-10 w-full md:h-12 h-10 dark:bg-transparent rounded-2xl border">
              <button
                id="dropdownDelayButton"
                data-dropdown-toggle="dropdownDelay"
                data-dropdown-delay="500"
                data-dropdown-trigger="hover"
                className="text-black w-full justify-between outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2.5 text-center inline-flex items-center"
                type="button"
                onClick={() => {
                  setFlyer(!flyer);
                }}
              >
                <p className="text-[#D9D4DF] font-normal text-base md:text-lg">
                  Reason
                </p>
                <svg
                  className="w-6 h-6 ml-2"
                  aria-hidden="true"
                  fill="none"
                  stroke="#D9D4DF"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  ></path>
                </svg>
              </button>

              <div
                id="dropdownDelay"
                className={` ${
                  flyer ? "block" : "hidden"
                } z-40 absolute w-full rounded-2xl border bg-white divide-y divide-gray-100 shadow dark:bg-[#322E38] p-2`}
              >
                <ul
                  className="py-2 space-y-2 text-sm"
                  aria-labelledby="dropdownDelayButton"
                >
                  <li className="text-base cursor-pointer font-normal dark:text-white">
                    I am evaluating X80 as a potential vendor
                  </li>
                  <li className="text-base cursor-pointer font-normal dark:text-white">
                    I am curious to see how a Threat Exposure Report Looks like
                  </li>
                  <li className="text-base cursor-pointer font-normal dark:text-white">
                    other
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full">
          <button className="lg:h-[62px] md:h-12 h-10 lg:text-[22px] md:text-lg text-base text-white font-semibold rounded-2xl w-full bg-[#843eca]">
            Receive Report
          </button>
        </div>
      </div>
    </div>
  );
}
