const CircularProgressBar = ({ value }) => {
    const radius = 18; // Half of the desired diameter
    const stroke = 2; // Reduced border thickness
    const normalizedRadius = radius - stroke / 2;
    const circumference = normalizedRadius * 2 * Math.PI;
    const strokeDashoffset = circumference - (value / 100) * circumference;
  
    return (
      <svg height={radius * 2} width={radius * 2}>
        <g transform={`rotate(-270, ${radius}, ${radius})`}>
          <circle
            stroke="lightgray"
            fill="transparent"
            strokeWidth={stroke}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
          />
          <circle
            stroke="currentColor" // Use currentColor to match the accent color
            fill="transparent"
            strokeWidth={stroke}
            strokeDasharray={`${circumference} ${circumference}`}
            style={{ strokeDashoffset }}
            r={normalizedRadius}
            cx={radius}
            cy={radius}
            className="text-accent" // Assuming you have a CSS class for accent color
          />
        </g>
        <text
          x="50%"
          y="50%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize="16px" // Adjust font size to fit within smaller circle
          fill="currentColor" // Use currentColor to match the accent color
          className="text-accent font-bold text-center" // Assuming you have a CSS class for accent color
        >
          {value}
        </text>
      </svg>
    );
  };
  
  export default CircularProgressBar;
  