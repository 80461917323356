// import {makeStyles} from "@mui/styles"
import React, { useRef, useState, useEffect } from 'react';

const TextEditWrapper = ({ content, onContentChange, uid }) => {
    const [contentLocal, setContentLocal] = useState(content);
    const [editable,setEditable]=useState(false)
    const editableDivRef = useRef(null);
    function getCookie(name) {
        const cookies = document.cookie.split(';');
        for (let i = 0; i < cookies.length; i++) {
          const cookie = cookies[i].trim();
          const cookieParts = cookie.split('=');
          if (cookieParts[0] === name) {
            return cookieParts[1];
          }
        }
        return null;
      }
    

    useEffect(() => {
        const cookieValue = getCookie('editorAuth');
        if (cookieValue==='febf192c-8ff8-40ba-b18f-ca01bf119e7c') {
          setEditable(true);
        }
      }, []);
  
    // Update local content state on every input change
    const handleInput = () => {
        setContentLocal(editableDivRef.current.innerText);
    };
  
    // Propagate changes to the parent component when the user finishes editing
    const handleBlur = () => {
      onContentChange(contentLocal);
    };
  
    // Update the internal state when the initial content changes
    useEffect(() => {
        setContentLocal(content);
    }, [content]);

    
    
    return ((editable)?
      <span
        ref={editableDivRef}
        contentEditable
        onInput={handleInput}
        onBlur={handleBlur}
        style={{ border: '1px solid black', minHeight: '50px', cursor: 'text' }}
        suppressContentEditableWarning={true}
      >
        {content?content:' '}
      </span>:<span>
        {content}
      </span>
    );
  };

  export default TextEditWrapper;