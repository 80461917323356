import React,{useState,useEffect} from "react";
import { Arrow, Cancel } from "../icons/icons";
import lock from "../../assets/lock.svg";
import less from "../../assets/arrow-up-left.svg";
import CardRadioGrp from "./CardRadioGrp";
import Slider from '@mui/material/Slider';
import TextEditWrapper from "../TextEditWrapper";
import Avatar from '@mui/material/Avatar';
import {useParams,useNavigate,useLocation} from 'react-router-dom'
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";

export default function ThirdPartyCard({ unlockCard,data, match, isSelected,setMatch ,attack_vectors,fr,showImage,updateThirdParties,changeTab}) {


  const [editable,setEditable]=useState(false);
  const [thirdParty,setThirdParty]=useState(data)
  const [value,setValue]=useState(data?.cardStatus?data?.cardStatus:0)
  const [intensity,setIntensity]=useState(thirdParty.intensity)
  const [statusCheck,setStatusCheck]=useState(0);

  const {getToken}=useKindeAuth()

  const navigate=useNavigate();
  const handleContentChange = (attr,newContent) => {
    thirdParty[attr]=newContent; 
    setThirdParty(thirdParty);
    updateThirdParties(thirdParty);
    };
  const handleSliderChange =(event, newValue) => {
    setIntensity(newValue);
  };
  
  const handleSliderChangeCommited= (event, newValue) => {
    setIntensityNew(newValue)
        setIntensity(newValue);
      };    


      const setIntensityNew=(val)=>{
        let sys=JSON.parse(JSON.stringify(data));
        sys.intensity=val;
        updateThirdParties(sys);
       }    

       const getCardData = async ()=>{
 
        let token= await getToken()
        try {
          let bdy=[thirdParty.uid];
          const response = await fetch(`https://scanner.x80security.com/reports/fetchCardsDetails`,
          {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
              // Add CORS headers
              "Access-Control-Allow-Origin": "*", // Allow requests from any origin
              "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS", // Allow specific HTTP methods
              "Access-Control-Allow-Headers": "Content-Type, Authorization", // Allow specific headers
              "Access-Control-Allow-Credentials": true // Allow credentials (cookies, authorization headers, etc.)
          },
           body:JSON.stringify({card_ids:bdy})
          });
    
          const responseData = await response.json();
          
          if (!response.ok) {
    
            throw new Error('Network response was not ok ' + response.statusText);
          }
          let data=responseData?.result[thirdParty.uid];
          if(data.cardStatus!==1){
            setThirdParty(data)
            setValue(data.cardStatus);
          }else{
            setStatusCheck(!statusCheck)
          }
          
          
        } catch (error) {
          console.log(error);
        }
      }
      useEffect(()=>{ 

        if(thirdParty.cardStatus===1 || value===1){
          setTimeout(()=>{
            getCardData();
          },50000)
        }
      },[statusCheck,value])
  
  const getAVName=(id)=>{
    for(let i=0;i<attack_vectors?.length;i++){
      if(attack_vectors[i].id===id)
      return attack_vectors[i].name.substr(0,12)+((attack_vectors[i].name.length>12)?"...":"");
    }
    return "None"
  }




let imgURL=thirdParty.third_party_fullview?thirdParty.third_party_fullview:'https://s44783.pcdn.co/in/wp-content/uploads/sites/3/2022/07/info-systems.jpg.optimal.jpg';
let preview=thirdParty.third_party_fullview?thirdParty.third_party_fullview:'https://s44783.pcdn.co/in/wp-content/uploads/sites/3/2022/07/info-systems.jpg.optimal.jpg';
    

function getCookie(name) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    const cookieParts = cookie.split('=');
    if (cookieParts[0] === name) {
      return cookieParts[1];
    }
  }
  return null;
}
const getStaus=()=>{
  // let val=parseInt(intensity);
  if(!thirdParty?.risk_level || thirdParty?.risk_level==="0") return "Ready-to-Unlock"
  else if(thirdParty?.risk_level==="1") return "Low"
  else if(thirdParty?.risk_level==="2") return "Medium"
  else if(thirdParty?.risk_level==="3") return "High"
  return "Extremely High"
}

const getAppropriateBg=()=>{
  if(!thirdParty?.risk_level || thirdParty?.risk_level==="0") return "bg-[#D9D9D9]"
  else if(thirdParty?.risk_level==="1") return "bg-[#08D176]"
  else if(thirdParty?.risk_level==="2") return "bg-[#F4C65C]"
  else if(thirdParty?.risk_level==="3") return "bg-[#F46A16]"
  return "bg-[#C10303]"
}
useEffect(() => {
  const cookieValue = getCookie('editorAuth');
  if (cookieValue==='febf192c-8ff8-40ba-b18f-ca01bf119e7c') {
    setEditable(true);
  }
}, []);
const extractHexColor = (str) => {
  const startIndex = str.indexOf('#');
  if (startIndex === -1) return null; // '#' not found in the string

  return str.substring(startIndex, startIndex + 7);
};
useEffect(()=>{
  let sys=JSON.parse(JSON.stringify(data));
  sys.cardStatus=value;
  updateThirdParties(sys);
  },[value])



  return (
    <div title="ThirdParties" className="relative h-full">
      <div style={{borderWidth:(isSelected)?'2.5px':'0.1px',borderColor:(isSelected)?extractHexColor(getAppropriateBg()):'lightgray',borderStyle:'solid'}} className="w-full relative dark:bg-[#252229] space-y-3 px-4 pt-1 h-full overflow-hidden pb-6 shadow-lg rounded-[16px]">
        <div
        style={{visibility:!(value === 0 || value === 1)?"":"hidden"}} 
          className={`flex mt-2 relative w-full ${
            thirdParty.uid === match ? "md:justify-center justify-end" : "justify-end"
          }`}
        >
          <button
            className={`rounded-lg md:text-sm text-xs text-white ${
              thirdParty.uid === match
                ? "lg:px-16 lg:py-3 md:px-4 py-1 px-2 md:py-2"
                : "md:px-4 py-1 px-2 md:py-2"
            } ${getAppropriateBg()}`}
          >
            {getStaus()}
          </button>
          <button
            onClick={() => setMatch(-1)}
            className={`h-11 text-[#2B2B2B] ${
              thirdParty.uid === match ? "md:flex hidden" : "hidden"
            } dark:bg-[#322E38] dark:text-white cursor-pointer absolute right-0 flex justify-center items-center bg-[#F1EEF4] rounded-full w-11`}
          >
            <Cancel />
          </button>
        </div>
        {/* {editable && <div>
            <CardRadioGrp value={value} setValue={setValue} />
          </div>} */}
        <div className="grid xxl:grid-cols-3 lg:grid-cols-2 grid-cols-1 mt-4 gap-3 xxl:gap-5">
        <div className={`space-y-3 ${thirdParty.uid !== match ? "col-span-3" : ""}`}>
            {/* <div className="mt-3 space-y-3">
              <img 
              onClick={()=>{
                showImage(imgURL);
            }}  
              
              src={preview} alt="" />
              <h1 className="font-semibold text-lg md:text-xl dark:text-[#F9F6FB]">
                <TextEditWrapper onContentChange={(content)=>{
                  
                  handleContentChange("title",content);
              }} content={thirdParty.title} />
              </h1>
            </div> */}
            {<div style={{visibility:!(value === 0 || value === 1)?"":"hidden"}}  className="flex space-x-3">
              <Avatar sx={{ width: [44, 84], height: [44, 84] }} src={thirdParty.logo} alt="" />
              <div>
                <h1 className="lg:text-[22px] text-base font-bold dark:text-white text-[#2B2B2B]">
                  {thirdParty.title}
                </h1>
                {/* <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-normal lg:text-base text-sm">
                  {contact.description}
                </p> */}
              </div>
            </div>}

            <div className="space-y-1">
              <p className="text-[13px] text-[#888888]">Description</p>
              <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-normal lg:text-base text-sm">
                <TextEditWrapper onContentChange={(content)=>{
                  
                  handleContentChange("description",content);
              }} content={thirdParty.description} />
              </p>
            </div>

    

            <div className="space-y-1">
            <p className="text-[13px] text-[#888888]">Attack Vectors</p>
              <div className="space-y-2 space-x-2">
                {thirdParty?.attack_vectors?.map((obj, _) => (
                  <button
                    className={`rounded-[40px] md:text-sm text-xs text-[#191919] md:py-2 bg-[#F1EEF4] md:px-4 py-1 px-2`}
                    onClick={()=>{
                      changeTab("attack-vectors",obj)
                    }}
                  >
                    {getAVName(obj)}
                  </button>
                ))}
                {!thirdParty?.attack_vectors?.length && 
                 <button
                 className={`rounded-[40px] md:text-sm text-xs text-[#191919] md:py-2 bg-[#F1EEF4] md:px-4 py-1 px-2`}
               >
                 None
               </button>}
             </div>
            </div>

            <div>
              {/* <p className="text-[13px] text-[#888888]">
                Third party attractiveness
              </p> */}
              <button 
                   onClick={()=>{
                   
                window.location.href='/v/'+thirdParty.domain
                   }}
                   className="bg-[#7B57E0] text-[#fff] font-extrabold font-poppins w-[134px] h-11 rounded-lg">
                     {"View Report"}
                   </button>

              <ul
                className={`text-[#2B2B2B] list-disc ${
                  thirdParty.uid === match ? "" : "truncate"
                } dark:text-[#E6E0E9] font-normal lg:text-base text-sm pl-[20px] `}
              >
                {/* {thirdParty?.third_party_attractiveness.map((obj, index) => {
              if (index > 0) return null; 
              return (
                <li key={index}>
                  {obj}
                </li>
              );
            })} */}
              </ul>
              
            </div>
          
            {editable && <Slider value={intensity} 
                style={{width:'80%'}}
                max={100}
                onChangeCommitted={handleSliderChangeCommited}
                onChange={handleSliderChange} aria-labelledby="continuous-slider" />}
            <div
              onClick={() => setMatch(thirdParty.uid)}
              className={`text-[#843ECA] cursor-pointer flex space-x-2 items-center ${
                thirdParty.uid !== match ? "" : "hidden"
              }`}
            >
              <p className="font-semibold text-lg underline">Read more</p>
              <div className="mt-1">
                <Arrow />
              </div>
            </div>
          </div>

          <div
            className={`${
              thirdParty.uid === match ? "block" : "hidden"
            } mt-2 space-y-3`}
          >
            <ul className="text-[#2B2B2B] list-disc dark:text-[#E6E0E9] font-normal lg:text-base text-sm pl-[20px]">
            {/* {thirdParty?.third_party_attractiveness.map((obj, index) => {
              if (index ==0) return null; 
              return (
                <li key={index}>
                  {obj}
                </li>
              );
            })} */}
            <div>
            <p className="text-[13px] text-[#888888]">People Attractiveness</p>
            <h4  
              style={{color:'#6495ed',marginTop:'3px'}}
              className={`md:text-[18px] text-[14px] font-black`}
            >
              Ready-to-Unlock
            </h4>
            </div>
            <div>
            <p className="text-[13px] text-[#888888]">Financial Attractiveness</p>
            <h4  
              style={{color:'#6495ed',marginTop:'3px'}}
              className={`md:text-[18px] text-[14px] font-black`}
            >
              Ready-to-Unlock
            </h4>
            </div>
            <div>
            <p className="text-[13px] text-[#888888]">System Attractiveness</p>
            <h4  
              style={{color:'#6495ed',marginTop:'3px'}}
              className={`md:text-[18px] text-[14px] font-black`}
            >
              Ready-to-Unlock
            </h4>
            </div>
            <div>
            <p className="text-[13px] text-[#888888]">Third-Parties Attractiveness</p>
            <h4  
              style={{color:'#6495ed',marginTop:'3px'}}
              className={`md:text-[18px] text-[14px] font-black`}
            >
              Ready-to-Unlock
            </h4>
            </div>
            <div>
            <p className="text-[13px] text-[#888888]">Criminal Attractiveness</p>
            <h4  
              style={{color:'#6495ed',marginTop:'3px'}}
              className={`md:text-[18px] text-[14px] font-black`}
            >
              Ready-to-Unlock
            </h4>
            </div>

            </ul>
          </div>

          <div
            className={`${
              thirdParty.uid === match ? "block" : "hidden"
            } space-y-3 mt-2`}
          >
            <div className="space-y-1">
              {/* <p className="text-[13px] text-[#888888]">Headquarters</p>
              <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-normal lg:text-base text-sm">
                {thirdParty.origin_country}
              </p> */}
              <p className="text-[13px] text-[#888888]">Company Facts</p>
            </div>
          </div>
        </div>
        <div
          onClick={() => setMatch(-1)}
          className={`text-[#843ECA] cursor-pointer flex space-x-2 items-center ${
            thirdParty.uid === match ? "lg:hidden block" : "hidden"
          }`}
        >
          <p className="font-semibold text-lg underline">Read Less</p>
          <img src={less} alt="" />
        </div>
      </div>

      <div
  className={`w-full absolute rounded-[16px] h-full backdrop-blur-md bg-white/40 top-0 ${
    (value === 0 || value === 1) ? "" : "hidden"
  }`}
>
<div
   style={{paddingRight:'20px'}}
          className={`flex mt-2 relative w-full ${
            thirdParty.uid === match ? "md:justify-center justify-end" : "justify-end"
          }`}
        >
          <button
            className={`rounded-lg md:text-sm text-xs text-white ${
              thirdParty.uid === match
                ? "lg:px-16 lg:py-3 md:px-4 py-1 px-2 md:py-2"
                : "md:px-4 py-1 px-2 md:py-2"
            } ${getAppropriateBg()}`}
          >
            {getStaus()}
          </button>
          <button
            onClick={() => setMatch(-1)}
            className={`h-11 text-[#2B2B2B] ${
              thirdParty.uid === match ? "md:flex hidden" : "hidden"
            } dark:bg-[#322E38] dark:text-white cursor-pointer absolute right-0 flex justify-center items-center bg-[#F1EEF4] rounded-full w-11`}
          >
            <Cancel />
          </button>
        </div>
  <div className="flex justify-center items-start w-full h-full">

    <div className="text-left w-full p-4">

    <div className="flex space-x-3">
              <Avatar sx={{ width: [44, 84], height: [44, 84] }} src={thirdParty.logo} alt="" />
              <div>
                <h1 className="lg:text-[22px] text-base font-bold dark:text-white text-[#2B2B2B]">
                  {thirdParty.title}
                </h1>
              
              </div>
            </div>
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center">
      <img
        style={{ cursor: 'pointer', width: '38px', height: '38px' }}
        src={lock}
        alt="unlock"
      />
      <a
        onClick={(e) => {
          if (value === 0) {
            unlockCard([thirdParty.uid], thirdParty.domain);
            // setValue(1);
            // setStatusCheck(!statusCheck)
          }
        }}
      >
        <h1
          style={{ cursor: 'pointer' }}
          className="text-[#843ECA] underline font-bold text-[20px]"
        >
          {value === 0 ? "Unlock" : "Unlocking in Progress"}
        </h1>
      </a>
    </div>
    </div>
  </div>
</div>
    </div>
  );
}
