import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Avatar,
  useMediaQuery,
  Typography,
  Box,
  Stack
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import {useParams,useNavigate,useLocation} from 'react-router-dom'
import CustomModal from '../components/CustomModal';
import { useEffect } from 'react';
const data = [
  {
    id: 1,
    name: 'Company A',
    image: 'https://via.placeholder.com/40',
    type: 'Tech',
    a: 10,
    b: 20,
    c: 30,
    d: 40,
  },
  {
    id: 2,
    name: 'Company B',
    image: 'https://via.placeholder.com/40',
    type: 'Finance',
    a: 15,
    b: 25,
    c: 35,
    d: 45,
  },
  // Add more company data here...
];

const CompanyTable = ({dataa,unlockReport}) => {
    const {user,login,register,isAuthenticated,isLoading,getToken}=useKindeAuth()
    const [domain,setDomain]=React.useState(
      ''
    )
    const [name,setName]=React.useState(
      ''
    )

    const [isAllowed, setIsAllowed] = React.useState([]);
    const [isOpen, setIsOpen] = React.useState(false);
    const getAppropriateCardColor=(val)=>{
        if(!val) return "text-[##A9A9A9]"
        if(val<=20) return "text-[#08D176]"
        else if(val<=40) return "text-[#F4C65C]"
        else if(val<=60) return "text-[#F46A16]"
        else if(val<=80) return "text-[#FA372E]"
        return "text-[#C10303]"
      }

      const onSuccess=()=>{
          setIsAllowed([...isAllowed,domain+name])
      }

      const navigate=useNavigate();
    //   useEffect(()=>{
    //     if(!isLoading){
    //         if(isAuthenticated && user.email.includes("x80security")){
                
    //             setIsAllowed(true);
    //         }
    //     }
    //   },[isLoading])

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleModal=()=>{
    setIsOpen(false);
  }

  return (
    <div>
  <div>
    <div>
      <div className="relative overflow-x-auto">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs">
            <tr>
              <th scope="col" className="py-3 text-[#66626C] font-poppins text-sm font-normal pl_m">
                Company
              </th>
              
              <th scope="col" style={{paddingRight:'70px'}} className="pr-7 py-3 text-[#66626C] font-poppins text-sm font-normal text-right">
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            {dataa.map((company) => {
              return (
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th scope="row" className="py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    <div className="flex">
                      <div>
                        <img
                          className="img"
                          src={company.logo}
                          style={{ width: '45px', height: '45px' }}
                          alt=""
                        />
                      </div>
                      <div className="pl-6 pt-2">
                        <Stack  direction="row" spacing={4}>
                        <div style={{minWidth:'150px'}} className="font-extrabold font-poppins  text-xl">
                          {company.name}
                        </div>
                        <div style={{color:'#66626C',fontSize:'0.9rem'}} className={`px-6  font-poppins text-2md`}> 
                        {company.website_url}
                        </div>
                        </Stack>
                      </div>
                    </div>
                  </th>
              
                  <td className="pr-6 py-4 text-right">
                    {company.report_status === 0 && !isAllowed.includes(company.website_url + company.name) && (
                      <button
                        onClick={() => {
                          setDomain(company.website_url);
                          setName(company.name);
                          setIsOpen(true);
                        }}
                        className="bg-[#7B57E0] text-[#fff] font-extrabold font-poppins w-[134px] h-11 rounded-lg"
                      >
                        Unlock
                      </button>
                    )}
                    {company.report_status === 2 && (
                      <button
                        onClick={() => {
                          navigate('/v/' + company.website_url);
                        }}
                        className="bg-[#123456] text-[#fff] font-extrabold font-poppins w-[134px] h-11 rounded-lg"
                      >
                        View Report
                      </button>
                    )}
                    {((company.report_status === 1) || isAllowed.includes(company.website_url + company.name)) && (
                      <span className="text-[#F3BB39] font-poppins text-sm font-extrabold">
                        In Progress
                      </span>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <CustomModal onSuccess={onSuccess} company={domain} isOpen={isOpen} onClose={toggleModal} />
</div>
  );
};

export default CompanyTable;


{/* <TableContainer >
        <h1 style={{fontWeight:'bold',fontSize:'1.4rem'}}> My Reports</h1>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Company</TableCell>
            <TableCell align="center">Criminal Attractiveness</TableCell>
            {!isMobile && <TableCell align="center">People Attractiveness</TableCell>}
            {!isMobile && <TableCell align="center">System Attractiveness</TableCell>}
            {!isMobile && <TableCell align="center">Third Parties Attractiveness</TableCell>}
            <TableCell align="center">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {dataa.map((company) => (
            <TableRow key={company.name}>
              <TableCell component="th" scope="row">
                <Box display="flex" alignItems="flex-start">
                  <Avatar
                    alt={company.name}
                    src={company.logo}
                    style={{ marginRight: '10px' ,height:'60px',width:'60px'}}
                  />
                  <Box>
                    <Typography variant="body1">{company.name}</Typography>
                    <Typography variant="body2" color="textSecondary">
                      {company.domain}
                    </Typography>
                  </Box>
                </Box>
              </TableCell>
              <TableCell align="center">
              <div className={`text-[34px] poppins_bold_extra ${getAppropriateCardColor(company.criminal_attractiveness)}`}>{company.criminal_attractiveness?company.criminal_attractiveness:"-"}</div>
              </TableCell>
              {!isMobile && (
                <TableCell align="center">
                <div className={`text-[34px] poppins_bold_extra ${getAppropriateCardColor(company.people_attractiveness)}`}>{company.people_attractiveness?company.people_attractiveness:"-"}</div>
                </TableCell>
              )}
              {!isMobile && (
                <TableCell align="center">
              <div className={`text-[34px] poppins_bold_extra ${getAppropriateCardColor(company.system_attractiveness)}`}>{company.system_attractiveness?company.system_attractiveness:"-"}</div>
                </TableCell>
              )}
              {!isMobile && (
                <TableCell align="center">
           <div className={`text-[34px] poppins_bold_extra ${getAppropriateCardColor(company.third_party_attractiveness)}`}>{company.third_party_attractiveness?company.third_party_attractiveness:"-"}</div>
                </TableCell>
              )}
              <TableCell align="center">
                <Button style={{backgroundColor:'#7B57E0'}} variant="contained" color="primary">
                  Unlock
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer> */}