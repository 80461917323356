import React, { useContext ,useEffect ,useState,useRef} from "react";
import {useParams,useNavigate} from 'react-router-dom'
import Navbar from "../components/Navbar/Navbar";
import ReportBody from "../components/CompanyInfo";
import Footer from "../components/Footer/Footer";
import { v4 as uuidv4 } from 'uuid';
import {gapi} from 'gapi-script'
import Backdrop from '@mui/material/Backdrop';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import CircularProgress from '@mui/material/CircularProgress';
import DataNotAvailableScreen from "../components/DataNotAvailable";
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import { IoMdLogOut } from "react-icons/io";




const clientId="164155978292-3pqh450tqt0rlf2dlfinapkpf3d7s3lj.apps.googleusercontent.com"

 const  CustomReportViewer = (props)=> {
    // const { refreshToken, getSession } = useContext(AccountContext);
    const {magicId}=useParams();
    const [magic_id,setMagicId]=useState(magicId)
    const [reportContent,setReportContent]=useState('init');
    const [companyData,setCompanyData]=useState(null);
    const [openMarketing,setOpenMarketing]=useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoggedIn,setIsLoggedIn]=useState(false);
    const [domain,setDomain]=useState('');
    const [reportOpts,setReportOpts]=useState(null)
    const [companyName,setCompanyName]=useState('');
    const [errorScreen,setErrorScreen]=useState(false);
    const [open, setOpen] = React.useState(false);
    const [openSnack, setOpenSnack] = React.useState(false);
    const {user,login,register,logout,isAuthenticated,isLoading,getToken}=useKindeAuth()
    const [metaData,setMetaData]=useState(null);

    const handleSnackClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
  
      setOpenSnack(false);
    };
    const action = (
      <React.Fragment>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleSnackClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );

    const [msg,setMsg]=useState('Checking your session...');
    const handleClose = () => {
      setOpen(false);
    };
    const [i, setI] = useState(1); // useState to manage the state
  const intervalId = useRef(null); 
    const handleOpen = () => {
      setOpen(true);
    };

    const navigate = useNavigate();
    const [darkMode, setDarkMode] = useState(() => {
      const savedMode = JSON.parse(localStorage.getItem("darkMode"));
      return savedMode || false;
    });
  
    const toggleDarkMode = () => {
      setDarkMode((prevMode) => !prevMode);
    };

    const getCreditScore=()=>{

    }


    useEffect(() => {

      const checkReportType=async ()=>{
        if(domain==='') return;
        let token=await getToken()
        let bdy={
          domain_name:domain
        }
        try {
          const response = await fetch('https://scanner.x80security.com/reports/getReportsStats', {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
                // Add CORS headers
                "Access-Control-Allow-Origin": "*", // Allow requests from any origin
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS", // Allow specific HTTP methods
                "Access-Control-Allow-Headers": "Content-Type, Authorization", // Allow specific headers
                "Access-Control-Allow-Credentials": true // Allow credentials (cookies, authorization headers, etc.)
            },
            body:JSON.stringify(bdy)
          });
          const responseData = await response.json();
          setReportOpts(responseData?.result)
       
        } catch (error) {
        
        }
  
      }

      checkReportType();
    }, [domain]);

    const debounce = (func, delay) => {
      let timeoutId;
      return (...args) => {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
          func(...args);
        }, delay);
      };
    };
  
    useEffect(() => {
      localStorage.setItem("darkMode", JSON.stringify(darkMode));
      const htmlClasses = document.querySelector("html").classList;
      darkMode ? htmlClasses.add("dark") : htmlClasses.remove("dark");
    }, [darkMode]);
    

    function getCookie(name) {
      const cookies = document.cookie.split(';');
      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i].trim();
        const cookieParts = cookie.split('=');
        if (cookieParts[0] === name) {
          return cookieParts[1];
        }
      }
      return null;
    }
    function setCookie(name, value, days) {
      const expires = new Date();
      expires.setDate(expires.getDate() + days);
    
      const cookieString = `${name}=${value || ''}; expires=${expires.toUTCString()}; path=/`;
    
      document.cookie = cookieString;
    }


    useEffect(()=>{
      const cookieValue = getCookie('x80member');
      if (cookieValue==='15de7b48-9a2d-44ee-a65c-ce405f9bce98') {
        return;
      }
      const uidCookie=getCookie('user_id');
      let uid=uuidv4();
      if(uidCookie){
        uid=uidCookie;
      }
      else{
        setCookie('user_id',uid,100);
      }

    },[])
    const setReport=async (base64,uuid)=>{
      
      // let token=await getToken();
      // try {
          
      //   const response = await fetch('https://scanner.x80security.com/reports/setReportContent ',
      //     {
      //       method: 'POST',
      //       headers: {
      //         "Content-Type": "application/json",
      //         "Authorization": `Bearer ${token}`,
      //         // Add CORS headers
      //         "Access-Control-Allow-Origin": "*", // Allow requests from any origin
      //         "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS", // Allow specific HTTP methods
      //         "Access-Control-Allow-Headers": "Content-Type, Authorization", // Allow specific headers
      //         "Access-Control-Allow-Credentials": true // Allow credentials (cookies, authorization headers, etc.)
      //     },
      //       body: JSON.stringify({ base64_content: base64 ,magic_id:uuid}),
      //     });
      //     if(response.status===401){
      //       // logout()
      //       // navigate("/search-companies");
      //     }
          
      //   } catch (error) {
      //     console.error('There has been a problem with loading:', error);
      //   }
      }
    
    const debouncedSaveReport = debounce(setReport, 2000);


    const updateCompanyData=(newCompanyData,shouldSaveReport)=>{
      // console.log(newCompanyData,'new company data')
      setCompanyData(newCompanyData);
      let newJson=JSON.stringify(newCompanyData);
      // console.log(newJson, ' new json')
      const encodedData =  jsonToBase64(newJson);

      debouncedSaveReport(encodedData, magic_id);
      // console.log(encodedData,'encoded data');
      // if(shouldSaveReport)
      // { 
      //   try {
      //   saveReport(encodedData,magic_id);
      //   } catch (error) {
          
      //   }
      // }
    }

   
   
      const openn = Boolean(anchorEl);
      const id = openn ? 'simple-popover' : undefined;


    const getReport=async (uuid)=>{
      
      setDomain(uuid)
    try {
      let token= await getToken()

        const response = await fetch('https://scanner.x80security.com/reports/getDomainMetadata/'+uuid,
        {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
            // Add CORS headers
            "Access-Control-Allow-Origin": "*", // Allow requests from any origin
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS", // Allow specific HTTP methods
            "Access-Control-Allow-Headers": "Content-Type, Authorization", // Allow specific headers
            "Access-Control-Allow-Credentials": true // Allow credentials (cookies, authorization headers, etc.)
        }
        });
        console.log(uuid,'get report after')
       
        if (!response.ok) {

          throw new Error('Network response was not ok ' + response.statusText);
        }
        const data = await response.json();
        setMetaData(data.result)
        
      } catch (error) {
        console.error('There has been a problem with loading:', error);
      }
    }


    


      function jsonToBase64(jsonObject) {
        // const jsonString = JSON.stringify(jsonObject);
        // const utf8Bytes = new TextEncoder().encode(jsonObject);
        const base64String = btoa(unescape(encodeURIComponent(jsonObject)));
        return base64String;
    }
    // useEffect(()=>{

    //   if(!companyData) return;
    //   // console.log(companyData?.attack_vectors,'updated company data')
    

    // },[companyData])


    useEffect(()=>{

      console.log(magicId,'magic id')
      if(!isLoading){
        getReport(magicId);
      }
     

     
        
    },[isLoading,magicId])
   
    const checkIfCompanyExists=async (domain)=>{
 
      if(!domain) return;
      let token= await getToken()
      const response = await fetch(
        "https://scanner.x80security.com/reports/getDomainReport" ,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
            // Add CORS headers
            "Access-Control-Allow-Origin": "*", // Allow requests from any origin
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS", // Allow specific HTTP methods
            "Access-Control-Allow-Headers": "Content-Type, Authorization", // Allow specific headers
            "Access-Control-Allow-Credentials": true // Allow credentials (cookies, authorization headers, etc.)
        },
          body:JSON.stringify({
            domain_name:domain
          })
        },
      );
      let data = await response.json();
      if(!data.result?.available){
        setOpen(false)
        setErrorScreen(true);
      }
      else{
        setOpen(false)
        setErrorScreen(false)
        setMagicId(data.result.magic_id)
        // navigate('/v/'+data.result.magic_id)
        // window.location.href='/v/'+data.result.magic_id;
      }
      setMsg('Checking your session...')
    }
    const startDomainScan=async (domain)=>{
      if(!domain) return;
      let token= await getToken()
      const response = await fetch(
        "https://scanner.x80security.com/reports/startPartialScan" ,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
            // Add CORS headers
            "Access-Control-Allow-Origin": "*", // Allow requests from any origin
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS", // Allow specific HTTP methods
            "Access-Control-Allow-Headers": "Content-Type, Authorization", // Allow specific headers
            "Access-Control-Allow-Credentials": true // Allow credentials (cookies, authorization headers, etc.)
        },
          body:JSON.stringify({
            domain_name:domain
          })
        },
      );
    
      let data = await response.json();
      console.log(data,'data');
      setMsg('Checking your session...')
    }
    useEffect(()=>{
      
     if(isLoading){
      return;
     }
     console.log(isLoading,user,isAuthenticated)
      if(isAuthenticated){
        setIsLoggedIn(true);
        checkIfCompanyExists(companyName);
        if(msg==='Checking your session...')
        setOpen(false)
      }else{
        console.log(magicId,'hellooossss')
        login({
          app_state: {
            redirectTo: '/v/'+magicId
          }
        })
      }
    },[isLoading,companyName])

  // const scheduleNextCall = (currentI) => {
  //   const intervalTime = 3 * currentI * currentI * 1000; // Convert to milliseconds
  //   console.log(`Scheduling next call in ${intervalTime / 1000} seconds`);

  //   intervalId.current = setTimeout(() => {
  //     // makeApiCall();
      
  //     checkIfCompanyExists()
  //     setI(prevI => {
  //       const nextI = prevI + 1;
  //       scheduleNextCall(nextI); // Schedule the next call
  //       return nextI;
  //     });
  //   }, intervalTime);
  // };

  // const resetInterval = () => {
  //   clearTimeout(intervalId.current); // Clear the current timeout
  //   setI(1); // Reset i to 1
  // };

  // useEffect(() => {
  //   scheduleNextCall(i); // Start the scheduling process

  //   return () => {
  //     clearTimeout(intervalId.current); // Clear the interval on component unmount
  //   };
  // }, []); 

    
    return (
      <div className="dark:bg-[#141218]">
      <Navbar 
      startScan={()=>{
        console.log('start scan clicked')
        startDomainScan(domain)
      }}
       onCompanySelect={(companyName)=>{
        if(companyName.length>0)
        setCompanyName(companyName)
        setOpen(true)
        // resetInterval();
        setMsg('Fetching details for '+companyName+' ...')
      }}
      reportOpts={reportOpts}
      setErrorScreen={()=>setErrorScreen(false)} hideSearch={false} fr={companyData?.full_report} toggleDarkMode={toggleDarkMode} darkMode={darkMode} />
      {errorScreen && <DataNotAvailableScreen/>}
      {metaData && !errorScreen && <ReportBody  startScan={()=>{
       startDomainScan(domain)
      }}  reportOpts={reportOpts} country={metaData?.location} industry={metaData?.industry} darkMode={darkMode}  magicId={magic_id} domain={domain} updateCompanyData={updateCompanyData} metaData={metaData} companyData={metaData}/>}
      <Footer />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
        <p style={{marginLeft:'1.5%'}}>{msg}</p>
      </Backdrop>
      <Snackbar
        open={openSnack}
        autoHideDuration={3000}
        onClose={handleSnackClose}
        message="Report has been ordered!"
        action={action}
        key={'bottomright'}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />
    </div>
    );
}




export default CustomReportViewer;    
