import React, { useState,useEffect } from "react";
import Container from "../globals/Container";
import SystemCard from "../globals/SystemCard";
import System from "../../assets/system1.svg";
import System1 from "../../assets/system2.svg";
import { Arrow } from "../icons/icons";
import Dropdown from "../Dropdown";
import SearchBar from "../SearchBar";
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import InfiniteScroll from 'react-infinite-scroll-component';

export default function Systems(props) {
  const [match, setMatch] = useState(-1);
  const [systems,setSystems]=useState([])
  const [editable,setEditable]=useState(false);
  const [searchTerm,setSearchTerm]=useState('');
  const [subData,setSubData]=useState([]);
  const [isDblClicked,setIsDblClicked]=useState(false);
  const [selectedCards, setSelectedCards] = useState(new Set());
  const [action,setAction]=useState(0);
  const [disable,setDisable]=useState(false)
  const [page, setPage] = useState(0);
  const [refresh,setRefresh]=useState(0);
  const [hasMore, setHasMore] = useState(true);
  const {logout,isAuthenticated,getToken}=useKindeAuth();

  useEffect(()=>{
    console.log(selectedCards,isDblClicked)
    if(!selectedCards.size){
      setIsDblClicked(false);
    }
  },[selectedCards.size])
 

  const toggleValue = (value) => {
    setSelectedCards(prevSet => {
      const newSet = new Set(prevSet);
      if (newSet.has(value)) {
        newSet.delete(value);
      } else {
        newSet.add(value);
      }
      return newSet;
    });
  };

  function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      const cookieParts = cookie.split('=');
      if (cookieParts[0] === name) {
        return cookieParts[1];
      }
    }
    return null;
  }
  useEffect(() => {
    const cookieValue = getCookie('editorAuth');
    if (cookieValue==='febf192c-8ff8-40ba-b18f-ca01bf119e7c') {
      setEditable(true);
    }
  
  }, []);

  const getAppropriateText = ( )=>{
    if(props?.reportOpts?.partial_report) return "Order full report"
    else return "Order partial report"
  }
  // console.log(props.data,'sys')

  const moveObjectInArray=(arr, uid, targetIndex)=> {
    let array=JSON.parse(JSON.stringify(arr))
    const currentIndex = array.findIndex(obj => obj.uid === uid);
  
    if (currentIndex === -1 || targetIndex < 0 || targetIndex >= array.length) {
      return array;
    }
  
    const movedObject = array.splice(currentIndex, 1)[0];
  
    array.splice(targetIndex, 0, movedObject);
  
    return array;
  }

  const getApprIndex=(array,uid)=>{
    const currentIndex = array.findIndex(obj => obj.uid === uid);
    let i=parseInt(currentIndex/3);
    let x=3*i
    return x;
  }

  useEffect(()=>{
    if(match===-1){   
      return;
    }
    setSystems([]);
    let idx=getApprIndex(systems,match);
    let newArray=moveObjectInArray(systems,match,idx);
    setSystems(newArray);
   
  },[match])

  function getUniqueObjects(array) {
    const uniqueObjects = [];
    const uniqueIds = new Set();
    let x=0;
  
    for (const obj of array) {
      if(obj.cardStatus===1){
        x=1;
      }
      if (!uniqueIds.has(obj.uid)) {
        uniqueIds.add(obj.uid);
        uniqueObjects.push(obj);
      }
    }
    if(x){
      setRefresh(refresh+1);
    }
    else{
      setRefresh(0);
    }
  
    return uniqueObjects;
  }


  const getSysData=async (page,searchTerm,isrefresh)=>{
    try {
      let token= await getToken()

      let limit=15;
      let skp=subData.length;
      if(isrefresh){
        limit=subData.length;
        setSubData([]);
        skp=0;
      }
      

        const response = await fetch(`https://scanner.x80security.com/reports/getCardsSearchResults/${props.domain}/systems?query=${searchTerm}&limit=${limit}&skip=${skp}`,
        {
          method: 'GET',
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
            // Add CORS headers
            "Access-Control-Allow-Origin": "*", // Allow requests from any origin
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS", // Allow specific HTTP methods
            "Access-Control-Allow-Headers": "Content-Type, Authorization", // Allow specific headers
            "Access-Control-Allow-Credentials": true // Allow credentials (cookies, authorization headers, etc.)
        }
        });
        
       
        if (!response.ok) {

          throw new Error('Network response was not ok ' + response.statusText);
        }
        const data = await response.json();
        let newData=data.result;
        if(searchTerm===''){
          setHasMore(true);
          let newArray=[...subData,...newData];
          let filtered=getUniqueObjects(newArray)
          setSystems(filtered)
          setSubData(filtered);
          if(page!==0 || (page===0 && data.result.length===15))
          setPage(page + 1);
          if (newData.length<15) {
            setHasMore(false);
          }
        }
        else{
          setSystems(newData);
          setSubData(newData);
          setHasMore(false)
        }
       
        
      } catch (error) {
        console.error('There has been a problem with loading:', error);
      }
  }

  const fetchMoreData = async () => {
    await getSysData(page, searchTerm)
  
  };
  useEffect(() => {
    // let newSys=JSON.parse(JSON.stringify(systems));
		// const results = newSys.filter((sub) =>{
    //  let a=sub.name.toLowerCase().includes(searchTerm.toLowerCase())
    //  let b=sub.title.toLowerCase().includes(searchTerm.toLowerCase());
  
    //  return a | b;
    // }
		// );
    // // console.log(systems);
		// setSubData(results);

    
    getSysData(0,searchTerm);
	}, [searchTerm]);

  // useEffect(()=>{

  //   if(refresh){
  //     setTimeout(()=>{
  //       setSubData([]);
  //       setPage(0);
  //       setHasMore(true);
  //       getSysData(0,searchTerm,true);
  //     },15000)
  //   }
  // },[refresh])

  const unlockCardRefresh=async (ids,param)=>{
    let response=props.unlockCard(ids,param);
    setRefresh(refresh+1);
    return response;
  }

  const performAction=async ()=>{
  
    if(!selectedCards.size) return;
      try {
        let token= await getToken()
        const response = await fetch('https://scanner.x80security.com/reports/executeAction', {
          method: 'POST',
          headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
              // Add CORS headers
              "Access-Control-Allow-Origin": "*", // Allow requests from any origin
              "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS", // Allow specific HTTP methods
              "Access-Control-Allow-Headers": "Content-Type, Authorization", // Allow specific headers
              "Access-Control-Allow-Credentials": true // Allow credentials (cookies, authorization headers, etc.)
          },
          body:JSON.stringify({
            action:'exportPDF',
            section:'systems',
            ids:Array.from(selectedCards)
          })
        });
        // Handle response from the backend
        const responseData = await response.json();
        props.invokeSnack(responseData.result)
        console.log(responseData,'logging response data')
       
      } catch (error) {
        localStorage.removeItem('email')
        localStorage.removeItem('token')
        return false;
      }
  }
  
  useEffect(()=>{
    if(action!==0)
    performAction();
  },[action])

  return (
    <Container>
      <div className="w-full py-5">
        <h1 className="lg:text-[36px] text-xl font-bold text-[#2B2B2B] dark:text-[#F9F6FB]">
          Systems
        </h1>
        <p className="xl:text-lg pt-2 md:text-base text-sm dark:text-white text-[#2B2B2B]">
          Map out and exploit vulnerabilities within the organization’s IT
          infrastructure. Focus on outdated
          <br className="lg:block hidden" /> software, unpatched systems, weak
          network defences, and poorly configured cloud services.
        </p>
        <div style={{marginTop:'30px'}}  className="flex items-center justify-between">
    <div style={{ width: "33%" }}>
      <SearchBar 
       searchValue={searchTerm}
       handleClear={(()=>{
         setSearchTerm('');
       })}
       searchText={"Search Systems"}
      handleOnChange={(e) => setSearchTerm(e.target.value)} />
    </div>
    <div className="w-[350px]">
      <Dropdown
        handleSelectExecute={() => {
          setAction(action + 1);
        }}
      />
    </div>
    
  </div>

      </div>
      <InfiniteScroll
      dataLength={subData.length}
      next={fetchMoreData}
      hasMore={hasMore}
      loader={<h4>Loading...</h4>}
      endMessage={<p style={{ textAlign: 'center' }}>You have seen all cards</p>}
    >
      <div className="grid lg:grid-cols-3 grid-cols-1 py-5 gap-5">
        {/* {props?.data.map((obj, _) => (
          <div
            key={obj.uid}
            className={`${obj.uid === match ? "lg:col-span-3" : ""}`}
          >
            <SystemCard data={obj} match={match} setMatch={setMatch} />
          </div>
        ))} */}
        {match===-1?(subData.map((obj, _) => { 
             if(!editable && obj.cardStatus==="3") return null;
       return(  <div
            key={obj.uid}
            onDoubleClick={()=>{
             
              setIsDblClicked(true)
              toggleValue(obj.uid)
            
          }}
          onClick={()=>{
            if(isDblClicked)
            toggleValue(obj.uid)
          }}
          >
            <SystemCard unlockCard={unlockCardRefresh}  isSelected={selectedCards.has(obj.uid)} fr={props.fr} attack_vectors={props.attack_vectors} updateSystems={props.updateSystems} data={obj} showImage={props.showImage} match={match} setMatch={setMatch} />
          </div>
        )})):(subData.map((obj, _) => {
          if(!editable && obj.cardStatus==="3") return null;
          if(obj.uid === match)
          return(<div
            key={obj.uid}
            onDoubleClick={()=>{
             
              setIsDblClicked(true)
              toggleValue(obj.uid)
            
          }}
          onClick={()=>{
            if(isDblClicked)
            toggleValue(obj.uid)
          }}
            className={"lg:col-span-3"}
          >
            <SystemCard unlockCard={unlockCardRefresh}  isSelected={selectedCards.has(obj.uid)} fr={props.fr} changeTab={props.changeTab} attack_vectors={props.attack_vectors} updateSystems={props.updateSystems} data={obj} showImage={props.showImage} match={match} setMatch={setMatch} />
          </div>)
          else{
            return(<div
              key={obj.uid}
              onDoubleClick={()=>{
             
                setIsDblClicked(true)
                toggleValue(obj.uid)
              
            }}
            onClick={()=>{
              if(isDblClicked)
              toggleValue(obj.uid)
            }}
            >
              <SystemCard unlockCard={unlockCardRefresh}  isSelected={selectedCards.has(obj.uid)} fr={props.fr} changeTab={props.changeTab} attack_vectors={props.attack_vectors} updateSystems={props.updateSystems} data={obj} showImage={props.showImage} match={match} setMatch={setMatch} />
            </div>)
          }
}))}
      </div>
      </InfiniteScroll>
      {!props.fr && !props?.reportOpts?.full_report &&  <div className="py-7 flex justify-center flex-col items-center">
        {/* <button disabled={disable} 
        
        onClick={()=>{
          
          props.startScan()
          setDisable(true)
        }}
        
        className="md:w-[256px] w-[217px] space-x-3 font-semibold md:text-lg text-base dark:bg-[#F9F6FB] dark:text-[#2B2B2B] h-[49px] flex justify-center items-center rounded-[40px] bg-[#1F0048] text-white">
        <a href="#unlock">
          <p> {getAppropriateText()}</p>
          </a>
          <div className="mt-1">
            <Arrow />
          </div>
        </button>
        <hr className="w-full lg:mt-10 mt-5" /> */}
      </div>}
    </Container>
  );
}
