import React,{useState,useEffect} from "react";
import lock from "../../assets/lock.svg";
import CardRadioGrp from "./CardRadioGrp";
import Slider from '@mui/material/Slider';
import TextEditWrapper from "../TextEditWrapper";



export default function AssetCard({ data,updateAsset ,attack_vectors,changeTab,fr}) {

  const [editable,setEditable]=useState(false);
  const [asset,setAsset]=useState(data);
  const [value,setValue]=useState(data?.cardStatus?data?.cardStatus.toString():"0")
  const [intensity,setIntensity]=useState(data.intensity)

  const handleContentChange = (attr,newContent) => {
        
    asset[attr]=newContent; 
     setAsset(asset);
     updateAsset(asset);
    };

  const handleSliderChange =(event, newValue) => {
    setIntensity(newValue);
  };
  
  const handleSliderChangeCommited= (event, newValue) => {
    setIntensityNew(newValue)
        setIntensity(newValue);
      };    


      const setIntensityNew=(val)=>{
        let newAsset=JSON.parse(JSON.stringify(data));
        newAsset.intensity=val;
        updateAsset(newAsset);
       }    
  // console.log(attack_vectors,'asset ')

  const getAppropriateBg=()=>{
    let val=parseInt(intensity);
    if(val<=20) return "bg-[#08D176]"
    else if(val<=40) return "bg-[#F4C65C]"
    else if(val<=60) return "bg-[#F46A16]"
    else if(val<=80) return "bg-[#FA372E]"
    return "bg-[#C10303]"
  }
  const getAVName=(id)=>{
    for(let i=0;i<attack_vectors?.length;i++){
      if(attack_vectors[i].id===id)
      return attack_vectors[i].name.substr(0,12)+((attack_vectors[i].name.length>12)?"...":"");
    }
    return "None"
  }

  const getAssetValueLabel=(val)=>{
    // if(editable)
    // return val;
    let i=parseInt(val);
    if(i>=0 && i<=5) return "Low"
    else if(i>=6 && i<=10) return "Medium"
    else if(i>=11 && i<=15) return "High"
    else if(i>=16 && i<=20) return "Very High"
    return "Extremely High"
}
const getAppropriateTxt=()=>{
  let val=parseInt(asset.value);
  let i=parseInt(val);
  if(i>=0 && i<=5) return "text-[#08D176]"
  else if(i>=6 && i<=10)return "text-[#F4C65C]"
  else if(i>=11 && i<=15) return "text-[#F46A16]"
  else if(i>=16 && i<=20) return "text-[#FA372E]"
  return "text-[#C10303]"
}

const getStaus=()=>{
  let val=parseInt(intensity);
  if(val<=20) return "Low"
  else if(val<=40) return "Medium"
  else if(val<=60) return "High"
  else if(val<=80) return "Very High"
  return "Extremely High"
}

function getCookie(name) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    const cookieParts = cookie.split('=');
    if (cookieParts[0] === name) {
      return cookieParts[1];
    }
  }
  return null;
}
useEffect(() => {
  const cookieValue = getCookie('editorAuth');
  if (cookieValue==='febf192c-8ff8-40ba-b18f-ca01bf119e7c') {
    setEditable(true);
  }

}, []);

useEffect(()=>{
  let asset=JSON.parse(JSON.stringify(data));
  asset.cardStatus=value;
  updateAsset(asset);
  },[value])


  return ( 
    <div className="relative h-full">
      <div className="w-full relative dark:bg-[#252229] space-y-3 px-4 pt-1 h-full overflow-hidden pb-6 shadow-lg rounded-[16px]">
        <div className={`flex mt-2 relative w-full justify-end`}>
          <button
            className={`rounded-lg md:text-sm text-xs text-white md:py-2 ${getAppropriateBg()} md:px-4 py-1 px-2`}
          >
            {getStaus()}
          </button>
        </div>
        {editable && <div>
            <CardRadioGrp value={value} setValue={setValue} />
          </div>}
        <div className="">
          <div className={`space-y-3 `}>
            <div className="">
              <h1 className="font-bold xxl:w-3/4 w-full text-lg md:text-xl dark:text-[#F9F6FB]">
                <TextEditWrapper onContentChange={(content)=>{
                  
                  handleContentChange("name",content);
              }} content={asset.name} />
              </h1>
            </div>

            <div className="space-y-1">
              <p className="text-[13px] text-[#888888]">Department</p>
              <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-normal lg:text-base text-sm">
              <TextEditWrapper onContentChange={(content)=>{
                  
                  handleContentChange("department",content);
              }} content={asset.department} />
              </p>
            </div>

            

            <div className="space-y-1">
              <p className="text-[13px] text-[#888888]">Description</p>
              <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-normal lg:text-base text-sm">
                {/* {asset.description.substr(0,140)} */}
                <TextEditWrapper onContentChange={(content)=>{
                  
                  handleContentChange("description",content);
              }} content={asset?.description.substr(0,140)} />
              </p>
            </div>
            <p className="text-[13px] text-[#888888]">Attack Vectors</p>
              <div className="space-y-2 space-x-2">
              {asset?.attack_vectors?.map((obj, _) => (
                  <button
                    className={`rounded-[40px] md:text-sm text-xs text-[#191919] md:py-2 bg-[#F1EEF4] md:px-4 py-1 px-2`}
                    onClick={()=>{
                      changeTab("attack-vectors",obj);
                    }}
                  >
                    {getAVName(obj)}
                  </button>
                ))}
                {!asset?.attack_vectors?.length && 
                 <button
                 className={`rounded-[40px] md:text-sm text-xs text-[#191919] md:py-2 bg-[#F1EEF4] md:px-4 py-1 px-2`}
               >
                 None
               </button>}
              </div>
            <div>
              <p className="text-[13px] text-[#888888]">Value</p>
              <p className={`font-semibold text-lg ${getAppropriateTxt()}`}>
                {editable?<TextEditWrapper onContentChange={(content)=>{
                  
                  handleContentChange("value",content);
              }} content={asset.value} />:getAssetValueLabel(asset.value)}
               
              </p>
            </div>
            
          </div>
        </div>
        {editable && <Slider value={intensity} 
                style={{width:'80%'}}
                max={100}
                onChangeCommitted={handleSliderChangeCommited}
                onChange={handleSliderChange} aria-labelledby="continuous-slider" />}
      </div>
   

      <div
        className={`w-full absolute rounded-[16px] flex justify-center flex-col items-center h-full backdrop-blur-md bg-white/40 top-0 ${
          (!editable && !fr && (value === "1" || value==="2"))? "" : "hidden"
        }`}
      >
        <img src={lock} alt="unlock" />
        {/* <p className="text-[#843ECA] font-semibold text-xl">+13 Assets</p> */}
        {/* <h1 className="text-[#843ECA] underline font-bold text-[22px]">
          Unlock
        </h1> */}
         <a onClick={(e)=>{
          if(value === "1"){
           e.preventDefault(); 
            setValue("0")
          }
          
        }} href={(value === "2")?"#unlock":""}>
        <h1 className="text-[#843ECA] underline font-bold text-[22px]">
        {(value=== "2")?"Unlock Report":"Unlock"}
        </h1>
        </a>
      </div>
    </div>
  );
}
