
import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// A custom theme for this app
const theme = createTheme({
	palette: {
		type:"dark",
		primary: {
			main: "#141218",
			light: "#252229",
			dark: "black",
			contrastText: "#F9F6FB",
		},
		secondary: {
			main: "#A161DF",
			light: "#D0BCFF",
			dark: "#381E72",
			contrastText: "#F9F6FB",
			
		},
		background: {
			default: "#141218",
			paper: "#252229"
		},
		error: {
			main: red.A400,
		},
	},
	typography: {
		fontFamily: ["Mulish", "Roboto", "Arial", "sans-serif"].join(","),
		button: {
			fontFamily: ["Mulish", "Roboto", "Arial", "sans-serif"].join(","),
		},
	},
});

export default theme;