import React,{useEffect,useState} from "react";
import { Arrow, Cancel, Hash } from "../icons/icons";
import Google from "../../assets/google.svg";
import linkedin from "../../assets/linkedIn3.svg";
import lock from "../../assets/lock.svg";
import less from "../../assets/arrow-up-left.svg";
import Avatar from '@mui/material/Avatar';
import CardRadioGrp from "./CardRadioGrp";
import Slider from '@mui/material/Slider';
import TextEditWrapper from "../TextEditWrapper";
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";


export default function PeopleCard({ unlockCard,data, match, setMatch,updateContactInfo,attack_vectors,changeTab,fr,isSelected}) {

  const [editable,setEditable]=useState(false);
  const [contact,setContact]=useState(data);
  const [value,setValue]=useState(data?.cardStatus?data?.cardStatus:0)
  const [intensity,setIntensity]=useState(data.intensity)
  const [statusCheck,setStatusCheck]=useState(0);

  const {getToken}=useKindeAuth()

  const handleContentChange = (attr,newContent) => {
  contact[attr]=newContent; 
  setContact(contact);
  updateContactInfo(contact);
  };

  const handleSliderChange =(event, newValue) => {
    setIntensity(newValue);
  };
  
  const handleSliderChangeCommited= (event, newValue) => {
    setIntensityNew(newValue)
        setIntensity(newValue);
      };    


      const setIntensityNew=(val)=>{
        let contact=JSON.parse(JSON.stringify(data));
        contact.intensity=val;
        updateContactInfo(contact);
       }    
  const getStaus=()=>{
    // let val=parseInt(intensity);
    if(!contact?.risk_level || contact?.risk_level==="0") return "Ready-to-Unlock"
    else if(contact?.risk_level==="1") return "Low"
    else if(contact?.risk_level==="2") return "Medium"
    else if(contact?.risk_level==="3") return "High"
    return "Extremely High"
  }
  const getAppropriateTxt=(val)=>{
    if(val==="Low") return "text-[#08D176]"
    else if(val==="Medium") return "text-[#F4C65C]"
    else if(val==="High") return "text-[#F46A16]"
    else if(val==="Very High") return "text-[#FA372E]"
    return "text-[#C10303]"
  }

  const getAppropriateAccessTxt=(val)=>{
    if(!val) return "Medium"
    console.log(val,'valueeeeee')
    if(val.length>1) return val;

    if(val==="1") return "Low"
    else if(val==="2") return "Medium"
    else if(val==="3") return "High"
    else if(val==="4") return "Very High"
    return "Extremely High"
  }

  const getAppropriateBg=()=>{
    if(!contact?.risk_level || contact?.risk_level==="0") return "bg-[#D9D9D9]"
    else if(contact?.risk_level==="1") return "bg-[#08D176]"
    else if(contact?.risk_level==="2") return "bg-[#F4C65C]"
    else if(contact?.risk_level==="3") return "bg-[#F46A16]"
    return "bg-[#C10303]"
  }

  const extractHexColor = (str) => {
    const startIndex = str.indexOf('#');
    if (startIndex === -1) return null; // '#' not found in the string
  
    return str.substring(startIndex, startIndex + 7);
  };

  function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      const cookieParts = cookie.split('=');
      if (cookieParts[0] === name) {
        return cookieParts[1];
      }
    }
    return null;
  }

  const getAVName=(id)=>{
    for(let i=0;i<attack_vectors?.length;i++){
      if(attack_vectors[i].id===id)
      return attack_vectors[i].name.substr(0,12)+((attack_vectors[i].name.length>12)?"...":"");
    }
    return "None"
  }
useEffect(() => {
    const cookieValue = getCookie('editorAuth');
    if (cookieValue==='febf192c-8ff8-40ba-b18f-ca01bf119e7c') {
      setEditable(true);
    }
  }, []);

  const getCardData = async ()=>{
 
    let token= await getToken()
    try {
      let bdy=[contact.uid];
      const response = await fetch(`https://scanner.x80security.com/reports/fetchCardsDetails`,
      {
        method: 'POST',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
          // Add CORS headers
          "Access-Control-Allow-Origin": "*", // Allow requests from any origin
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS", // Allow specific HTTP methods
          "Access-Control-Allow-Headers": "Content-Type, Authorization", // Allow specific headers
          "Access-Control-Allow-Credentials": true // Allow credentials (cookies, authorization headers, etc.)
      },
       body:JSON.stringify({card_ids:bdy})
      });

      const responseData = await response.json();
      
      if (!response.ok) {

        throw new Error('Network response was not ok ' + response.statusText);
      }
      console.log(responseData?.data?.result,responseData?.result,'in get DAta')
      let data=responseData?.result[contact.uid];
      if(data.cardStatus!==1){
        setContact(data)
        setValue(data.cardStatus);
      }else{
        setStatusCheck(!statusCheck)
      }
      
      
    } catch (error) {
      console.log(error);
    }
  }
  useEffect(()=>{ 

    if(contact.cardStatus===1 || value===1){
      setTimeout(()=>{
        getCardData();
      },50000)
    }
  },[statusCheck,value])

useEffect(()=>{
let newEmployee=JSON.parse(JSON.stringify(data));
newEmployee.cardStatus=value;
updateContactInfo(newEmployee);
},[value])

  return (
    <div  className="relative h-full">
      <div style={{borderWidth:(isSelected)?'2.5px':'0.1px',borderColor:(isSelected)?extractHexColor(getAppropriateBg()):'lightgray',borderStyle:'solid'}} className="w-full relative dark:bg-[#252229] space-y-3 px-4 pt-1 h-full overflow-hidden pb-6 shadow-lg rounded-[16px]">
        <div
        style={{visibility:!(value === 0 || value === 1)?"":"hidden"}} 
          className={`flex mt-2 relative w-full ${
            contact.uid === match ? "md:justify-center justify-end" : "justify-end"
          }`}
        >
          <button
              className={`rounded-lg md:text-sm ${
                contact.uid === match ? "px-16 py-3" : "md:px-4 py-1 px-2 md:py-2"
              } text-xs text-white ${getAppropriateBg()}`}
          >
            {getStaus()}
          </button>
          <button
            onClick={() => {
              setMatch(-1)
            }}
            className={`h-11 text-[#2B2B2B] ${
              contact.uid === match ? "md:flex hidden" : "hidden"
            } dark:bg-[#322E38] dark:text-white cursor-pointer absolute right-0 flex justify-center items-center bg-[#F1EEF4] rounded-full w-11`}
          >
            <Cancel />
          </button>
        </div>
        {/* {editable && <div>
            <CardRadioGrp value={value} setValue={setValue} />
          </div>} */}
        <div className="grid xxl:grid-cols-3 lg:grid-cols-2 grid-cols-1 mt-3 gap-3 xxl:gap-5">
          <div className={`space-y-3 ${contact.uid !== match ? "col-span-3" : ""}`}>
            {<div style={{visibility:!(value === 0 || value === 1)?"":"hidden"}} className="flex space-x-3">
              <Avatar sx={{ width: [44, 84], height: [44, 84] }} src={contact.profile_image} alt="" />
              <div>
                <h1 className="lg:text-[22px] text-base font-bold dark:text-white text-[#2B2B2B]">
                  {contact.name}
                </h1>
                <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-normal lg:text-base text-sm">
                  {contact.description}
                </p>
              </div>
            </div>}

            

            <div className="space-y-1">
              <p className="text-[13px] text-[#888888]">Breached Plls</p>
              <div className="space-y-1">
                {contact?.breached_piis.map((obj,idx)=>{
                  if(idx>2) return<></>
                  return(
                    <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-medium lg:text-base text-sm">
                    {obj.pii} <span className="font-bold">{`(${obj.occurence}x)`}</span>
                  </p>
                  )
                })}

               
                {/* <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-medium lg:text-base text-sm">
                  Names <span className="font-bold">(7x)</span>
                </p>
                <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-medium lg:text-base text-sm">
                  Passwords <span className="font-bold">(7x)</span>
              </p> */}
                {contact?.breached_piis.length>3 && <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-bold lg:text-base text-sm">
                  {`+${contact?.breached_piis.length-3} more`}
                </p>}
              </div>
            </div>

            <div>
              <p className="text-[13px] text-[#888888]">Emails</p>
              {contact?.emails.map((obj, _) => (
                <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-medium lg:text-base text-sm">
                  {obj}
                </p>
              ))}
            </div>
            <div >
              <p className="text-[13px] text-[#888888]">Department</p>
              <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-medium text-base">
                <TextEditWrapper onContentChange={(content)=>{
                  
                    handleContentChange("department",content);
                }} content={contact.department} />
              
              </p>
            </div>
           
            {editable && <Slider value={intensity} 
                style={{width:'80%'}}
                max={100}
                onChangeCommitted={handleSliderChangeCommited}
                onChange={handleSliderChange} aria-labelledby="continuous-slider" />}
            <div
              onClick={() => {
                setMatch(-1);
                console.log(contact.uid,'uid logged')
                setMatch(contact.uid)
              }}
              className={`text-[#843ECA] cursor-pointer flex space-x-2 items-center ${
                contact.uid !== match ? "" : "hidden"
              }`}
            >
              <p className="font-semibold text-lg underline">Read more</p>
              <div className="mt-1">
                <Arrow />
              </div>
            </div>
          </div>

          {/* <div  className={`${contact.uid === match ? "block space-y-1" : "hidden"}`}>
             
            </div> */}
         

        

          <div
            className={`${
              contact.uid === match ? "block" : "hidden"
            } space-y-3 py-1`}
          >
             <p className="text-[13px] text-[#888888]">Attack Vectors</p>
              <div className="space-y-2 space-x-2">
                {contact?.attack_vectors?.map((obj, _) => (
                  <button
                    className={`rounded-[40px] md:text-sm text-xs text-[#191919] md:py-2 bg-[#F1EEF4] md:px-4 py-1 px-2`}
                    onClick={()=>{
                      changeTab("attack-vectors",obj)
                    }}
                 >
                    {getAVName(obj)}
                  </button>
                ))}
                {!contact?.attack_vectors?.length && 
                 <button
                 className={`rounded-[40px] md:text-sm text-xs text-[#191919] md:py-2 bg-[#F1EEF4] md:px-4 py-1 px-2`}
               >
                 None
               </button>}
              </div>
              {/* <div className={`${contact.uid === match ? "block" : "hidden"}`}>
              <p className="text-[13px] text-[#888888]">Department</p>
              <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-medium text-base">
                HR
              </p>
            </div> */}
            <div className="space-y-1">
              <p className="text-[13px] text-[#888888]">Access</p>
              <p className={`${getAppropriateTxt(getAppropriateAccessTxt(contact?.access[0]))} font-bold lg:text-base text-xs`}>
                {getAppropriateAccessTxt(contact?.access[0])}
              </p>
            </div>
            <div className="space-y-2">
              {/* <p className="text-[13px] text-[#888888]">Hashtag</p>
              <div className="md:space-x-3 space-x-1 flex">
                <div className="text-[#2B2B2B] dark:text-white flex items-center">
                  <Hash />
                  <p className="text-[#2B2B2B] dark:text-white font-medium lg:text-base text-sm">
                    Phishing Target
                  </p>
                </div>
                <div className="text-[#2B2B2B] dark:text-white flex items-center">
                  <Hash />
                  <p className="text-[#2B2B2B] dark:text-white font-medium lg:text-base text-sm">
                    One more Target
                  </p>
                </div>
                <div className="text-[#2B2B2B] dark:text-white flex items-center">
                  <Hash />
                  <p className="text-[#2B2B2B] dark:text-white font-medium lg:text-base text-sm">
                    Target 4
                  </p>
                </div>
              </div> */}
            </div>
            <div className={`${contact.uid === match ? "block" : "hidden"}`}>
              <p className="text-[13px] text-[#888888]">Connected Accounts</p>
              <div className="flex space-x-1 mt-3">
                {/* {contact?.connected_accounts.map((obj,_)=>{
                  console.log(obj.icon,'icon')
                  return(
                    <img src={obj.icon} alt={obj.name} />
                  )
                })} */}
                 {/* <img src={Google} alt="google" />
                <img src={linkedin} alt="linkedin" /> */}
                {contact?.connected_accounts.map((item,key)=>{
              return(
                <Avatar alt={item.name} src={item.icon} />
              )
            })}
              </div>
             
            </div>
            
           
          </div>
          <div className={`${contact.uid === match ? "block" : "hidden"}`}>
            <p className="text-[13px] text-[#888888]">Facts</p>
            <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-normal text-base">
             {contact?.facts.map((obj,_)=>{
              return (<><p>
                {obj}
              </p><br/></>)
             })}
            </p>
          </div>
        </div>
        <div
          onClick={() => setMatch(-1)}
          className={`text-[#843ECA] cursor-pointer flex space-x-2 items-center ${
            contact.uid === match ? "lg:hidden block" : "hidden"
          }`}
        >
          <p className="font-semibold text-lg underline">Read Less</p>
          <img src={less} alt="" />
        </div>
      </div>

      <div
  className={`w-full absolute rounded-[16px] h-full backdrop-blur-md bg-white/40 top-0 ${
    (value === 0 || value === 1) ? "" : "hidden"
  }`}
>
   <div 
   style={{paddingRight:'20px'}}
          className={`flex mt-2 relative w-full ${
            contact.uid === match ? "md:justify-center justify-end" : "justify-end"
          }`}
        >
          <button
              className={`rounded-lg md:text-sm ${
                contact.uid === match ? "px-16 py-3" : "md:px-4 py-1 px-2 md:py-2"
              } text-xs text-white ${getAppropriateBg()}`}
          >
            {getStaus()}
          </button>
  </div>
  <div className="relative w-full h-full">
    
    <div className="text-left w-full p-4">
      <div style={{ marginTop: '45px' }} className="flex space-x-3">
        <Avatar sx={{ width: [44, 84], height: [44, 84] }} src={contact.profile_image} alt="" />
        <div>
          <h1 className="lg:text-[22px] text-base font-bold dark:text-white text-[#2B2B2B]">
            {contact.name}
          </h1>
        </div>
      </div>
    </div>
    <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center">
      <img
        style={{ cursor: 'pointer', width: '38px', height: '38px' }}
        src={lock}
        alt="unlock"
      />
      <a
        onClick={async(e) => {
          if (value === 0) {
            let x=await unlockCard([contact.uid], 'people');
            if(x)
            {
              setValue(1);
              setStatusCheck(!statusCheck)
            }
            
          }
        }}
      >
        <h1
          style={{ cursor: 'pointer' }}
          className="text-[#843ECA] underline font-bold text-[20px]"
        >
          {value === 0 ? "Unlock" : "Unlocking in Progress"}
        </h1>
      </a>
    </div>
  </div>
</div>

    </div>
  );
}
