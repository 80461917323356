import React,{useState} from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Avatar,
  useMediaQuery,
  Typography,
  Box,
  Stack
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTheme } from '@mui/material/styles';
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import {useParams,useNavigate,useLocation} from 'react-router-dom'
import CustomModal from '../components/CustomModal';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useEffect } from 'react';
const data = [
  {
    id: 1,
    name: 'Company A',
    image: 'https://via.placeholder.com/40',
    type: 'Tech',
    a: 10,
    b: 20,
    c: 30,
    d: 40,
  },
  {
    id: 2,
    name: 'Company B',
    image: 'https://via.placeholder.com/40',
    type: 'Finance',
    a: 15,
    b: 25,
    c: 35,
    d: 45,
  },
  // Add more company data here...
];

const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
  })(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  }));

const ScoreCard=(props)=>{


    const [expanded,setExpanded]=useState(false)

    console.log(props.total,'total')
    useEffect(()=>{

        if(props.total===undefined)
        return;
       setExpanded(props.exp)
       console.log(props.exp,'inside it')
    },[props.exp])
    
    return(
        <div>
            <Stack spacing={10} direction="row">
            <div>
             {props.total!==undefined && <><span style={{fontWeight:'bold',color:'black',fontSize:'1rem'}}>{props?.total-(props?.risk_levels?props?.risk_levels["0"]:0)} </span><span style={{color:'lighgray',fontWeight:'400',fontSize:'1rem'}}>out of {props?.total}</span></>}
             {props.total===undefined && <span style={{color:'lighgray',fontWeight:'400',fontSize:'1rem'}}>NA</span>}   
            </div>
            {props.total!==undefined && <ExpandMore
          expand={expanded}
          onClick={()=>{
            if(props.total===undefined) return;
            setExpanded(!expanded);
          }}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>}
            </Stack>
        <div>
        <Collapse in={expanded} timeout="auto" unmountOnExit>
        <div style={{marginTop:'12px'}} className="flex justify-between">
          <div style={{fontWeight:'bold'}} className=" text-[12px] text-[#FA372E]">
           Very High
          </div>
          <div className=" text-[12px] text-[#000000] pr-4">
          {props?.risk_levels?props?.risk_levels["4"]:0}
          </div>
        </div>
        <div style={{marginTop:'1px',fontSize:'0.9rem'}} className="flex justify-between">
          <div style={{fontWeight:'bold'}} className=" text-[12px] text-[#F46A16]">
            High
          </div>
          <div className=" text-[12px] text-[#000000] pr-4">
          {props?.risk_levels?props?.risk_levels["3"]:0}
          </div>
        </div>
        <div style={{marginTop:'1px',fontSize:'0.9rem'}} className="flex justify-between">
          <div style={{fontWeight:'bold'}} className=" text-[12px] text-[#F4C65C]">
            Medium
          </div>
          <div className=" text-[12px] text-[#000000] pr-4">
          {props?.risk_levels?props?.risk_levels["2"]:0}
          </div>
        </div>
        <div style={{marginTop:'1px',fontSize:'0.9rem'}} className="flex justify-between">
          <div style={{fontWeight:'bold'}} className=" text-[12px] text-[#09B467]">
            Low
          </div>
          <div className=" text-[12px] text-[#000000] pr-4">
          {props?.risk_levels?props?.risk_levels["1"]:0}
          </div>
        </div>
        </Collapse>
        </div>
        </div>
    )
}

const MyReports = ({dataa,unlockReport}) => {
    const {user,login,register,isAuthenticated,isLoading,getToken}=useKindeAuth()
    const [domain,setDomain]=React.useState(
      ''
    )
    const [name,setName]=React.useState(
      ''
    )
    const [expandAll,setExpandAll]=useState(new Array(dataa.length).fill(false));

    const [isAllowed, setIsAllowed] = React.useState([]);
    const [isOpen, setIsOpen] = React.useState(false);
    const getAppropriateCardColor=(val)=>{
        if(!val) return "text-[##A9A9A9]"
        if(val<=20) return "text-[#08D176]"
        else if(val<=40) return "text-[#F4C65C]"
        else if(val<=60) return "text-[#F46A16]"
        else if(val<=80) return "text-[#FA372E]"
        return "text-[#C10303]"
      }


      const onSuccess=()=>{
          setIsAllowed([...isAllowed,domain+name])
      }

      const navigate=useNavigate();
    //   useEffect(()=>{
    //     if(!isLoading){
    //         if(isAuthenticated && user.email.includes("x80security")){
                
    //             setIsAllowed(true);
    //         }
    //     }
    //   },[isLoading])

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const toggleModal=()=>{
    setIsOpen(false);
  }




  return (
    <div>
    <div>
    <div>

          <div className="relative overflow-x-auto">
            <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
              <thead className="text-xs">
                <tr>
                  <th
                    scope="col"
                    className="py-3 text-[#66626C] font-poppins text-sm font-normal pl_m"
                  >
                    Company
                  </th>
                  <th
                    scope="col"
                    className="pr-7 py-3 text-[#66626C] font-poppins text-sm font-normal"
                  >
                    People
                  </th>
                  <th
                    scope="col"
                    className="pr-7 py-3 text-[#66626C] font-poppins text-sm font-normal"
                  >
                    Systems
                  </th>
                  <th
                    scope="col"
                    className="pr-7 py-3 text-[#66626C] font-poppins text-sm font-normal"
                  >
                    Third Parties
                  </th>
                  <th
                    scope="col"
                    className="pr-7 py-3 text-[#66626C] font-poppins text-sm font-normal"
                  >
                    Threats
                  </th>
                  <th
                    scope="col"
                    className="pr-7 py-3 text-[#66626C] font-poppins text-sm font-normal text-center"
                  >
                    Status
                  </th>
                </tr>
              </thead>
              <tbody>
              {dataa.map((company,index) => {
        return(
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                  <th
                    scope="row"
                    className="pr-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    <div className="flex" onClick={()=>{
                        expandAll[index]=!expandAll[index];
                        let newArr=JSON.parse(JSON.stringify(expandAll));
                        console.log(newArr)
                        setExpandAll(newArr)
                    }} style={{cursor:'pointer'}} >
                      <div>
                        <img
                          className="img"
                          src={company.logo}
                          style={{width:'45px',height:'45px'}}
                          alt=""
                        ></img>
                      </div>
                      <div className=" pl-3">
                        <div className=" font-bold font-poppins c_n">
                          {company.name}
                        </div>
                        <div className=" text-[#66626C] email_text">
                          {company.domain}
                        </div>
                      </div>
                    </div>{" "}
                  </th>
                
                  <td className={`${getAppropriateCardColor(company.people_attractiveness)} px-6 py-4  font-poppins font-extrabold text-2xl`}>
                  <ScoreCard exp={expandAll[index]} total={company?.employees} risk_levels={company.employees_risk_levels} />
                  </td>
                  <td className={`${getAppropriateCardColor(company.system_attractiveness)} px-6 py-4  font-poppins font-extrabold text-2xl`}>
                  <ScoreCard exp={expandAll[index]} total={company?.systems} risk_levels={company.systems_risk_levels} />
                  </td>
                  <td className={`${getAppropriateCardColor(company.third_party_attractiveness)} px-6 py-4  font-poppins font-extrabold text-2xl`}>
                  <ScoreCard exp={expandAll[index]} total={company?.third_parties} risk_levels={company.third_parties_risk_levels} />
                  </td>
                  <td  className={`${getAppropriateCardColor(company.criminal_attractiveness)} px-6 py-4  font-poppins font-extrabold text-2xl`}>
                  <ScoreCard exp={expandAll[index]} total={company?.attack_vectors} risk_levels={company.attacks_risk_levels}/>
                  </td>
                  {company?.report_status===0 && !isAllowed.includes(company.domain+company.name) &&
                  <td className="pr-6 py-4 text-right">
                  <button 
                    onClick={()=>{
                        // setIsAllowed([...isAllowed,company.domain+company.name])
                        setDomain(company.domain)
                        setName(company.name)
                        setIsOpen(true)
                        // unlockReport(company.domain);
                    }}
                  className="bg-[#7B57E0] text-[#fff] font-extrabold font-poppins w-[134px] h-11 rounded-lg">
                    {"Unlock"}
                  </button>
                  </td>
                  }

                  {company?.report_status===2 && <td className="pr-6 py-4 ">
                   <button 
                   onClick={()=>{
                 
                navigate('/v/'+company.domain)
                   }}
                   className="bg-[#123456] text-[#fff] font-extrabold font-poppins w-[134px] h-11 rounded-lg">
                     {"View Report"}
                   </button>
                  
                 </td>}

                 {((company?.report_status===1) || isAllowed.includes(company.domain+company.name)) && <td  className="pr-6 py-4 text-[#F3BB39] font-poppins text-sm font-extrabold">
                    In Progress
                  </td>}
                </tr>
        )
    })}

              </tbody>
            </table>
          </div>
          </div>
    
    </div>
    <CustomModal onSuccess={onSuccess} company={domain} isOpen={isOpen} onClose={toggleModal} />
  </div>
  );
};

export default MyReports;

