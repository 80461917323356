import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";

// A custom theme for this app
const theme = createTheme({
	palette: {
		type:"normal",
		primary: {
			main: "#fff",
		},
		secondary: {
			main: "#A161DF",
			light: "#D0BCFF",
			dark: "#381E72",
			contrastText: "#F9F6FB",
		},
		error: {
			main: red.A400,
		},
		background: {
			default: "#FCFCFC",
			paper: "#FCFCFC",
		},
		titleBar: {
			main: "#FCFCFC",
			contrastText: "#0f0f0f",
		},
	},
	typography: {
		fontFamily: ["Inter", "Roboto", "Arial", "sans-serif"].join(","),
		button: {
			fontFamily: ["Mulish", "Roboto", "Arial", "sans-serif"].join(","),
		},
	},
});

export default theme;