import React from "react";
import AppRouter from "./pages/AppRouter";
import "./App.css";
import {KindeProvider} from "@kinde-oss/kinde-auth-react";
function App() {
  return (
  <AppRouter />
 )
  ;
}

export default App;