import React, { useState, useEffect,useContext } from "react";


import {GlobalDataContext} from '../components/GlobalData'


const useGlobalContext = ()=>{
    return useContext(GlobalDataContext)
}

export default useGlobalContext;