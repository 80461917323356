import React from "react";

export default function Container({ children }) {
  return (
    <div
      className={`container dark:bg-[#141218] max-w-xxl mx-auto px-4 sm:px-8 lg:px-14 xl:px-16 xxl:px-24`}
    >
      {children}
    </div>
  );
}
