import React from "react";
import Container from "../globals/Container";
import { Arrow } from "../icons/icons";
import TrustCard from "../globals/TrustCard";
import Trust1 from "../../assets/trust1.svg";
import Trust2 from "../../assets/trust2.svg";
import Trust3 from "../../assets/trust3.svg";
import Trust4 from "../../assets/trust4.svg";
import Request from "../globals/Request";

export default function TrustCenter() {
  const data = [
    {
      id: 1,
      trust: Trust1,
      heading: "Threat Exposure Monitoring",
      para: "We continuously use the X80 Engine on ourselves to monitor any threat exposure to our People, Systems and Third-Parties. You can Request to see our Threat Exposure Report below.",
    },
    {
      id: 2,
      trust: Trust2,
      heading: "Security principles",
      para: "Least priviledge access everywhere, networks and systems segmentation by default, allow-lists instead of deny-lists, multi-factor authentification, consistent rotation of secrets.",
    },
    {
      id: 3,
      trust: Trust3,
      heading: "Minimal Attack Surface",
      para: "We limit public endpoints to only those that are absolutely necessary. Our software supply-chain is kept simple and we use as few components and third-parties as possible. Data is stored in air-gaped environments.",
    },
    {
      id: 4,
      trust: Trust4,
      heading: "Security Compliance",
      para: "We hold ISO27001 and SOC 2 type 2 attestations. We architect our systems in compliance with GDPR and CCPA privacy laws. We only store the data we need and remove any unused data after 90 days.",
    },
  ];
  return (
    <Container>
      <div className="py-5">
        <div className="flex items-center relative h-11">
          <div className="absolute">
            <div className="md:w-11 md:h-11 w-8 h-8 flex justify-center text-[#2B2B2B] dark:bg-[#322E38] dark:text-white items-center rotate-180 bg-[#F1EEF4] rounded-full">
              <Arrow />
            </div>
          </div>
          <div className="w-full flex justify-center">
            <h1 className="md:text-[26px] text-lg dark:text-white font-bold">
              Trust Center
            </h1>
          </div>
        </div>
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-5 py-10">
          {data?.map((obj, _) => (
            <div key={obj.id}>
              <TrustCard data={obj} />
            </div>
          ))}
        </div>
        <Request  />
      </div>
    </Container>
  );
}
