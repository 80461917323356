import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import CssBaseline from "@mui/material/CssBaseline";
import App from "./App";
import { CustomThemeProvider } from "./themes/CustomThemeProvider";
// import Account from './auth/Account'
// import GlobalData from "./components/GlobalDataContext";
// import axios from "axios";


ReactDOM.render(
	// <Account>
	<React.Fragment>
		{/* <CustomThemeProvider> */}
			{/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
			<CssBaseline />
      {/* <GlobalData> */}
			<App />
      {/* </GlobalData> */}
		{/* </CustomThemeProvider>  */}
	</React.Fragment>
	,
	document.getElementById("root")
);