import { useState } from "react";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

const Dropdown = (props) => {
  const [active, setActive] = useState(false);
  const [selected, setSelected] = useState("More Options");
  const handleSelected = (value) => {
    props.handleSelectExecute();
    setSelected(value);
    setActive((prevState) => !prevState);
  };
  return (
    <div className="w-full flex items-center justify-center p-2">
      <div className="w-full relative flex ">
        {/* Select Field */}
        <div className={`flex items-center justify-center w-[75%] p-3 ${selected!=="More Options"?"bg_sc text-white":"bg_clr"} rounded-l-full text-sm font-semibold cursor-none pointer-events-none`}>
          <p>{selected}</p>
        </div>
        {/* Values */}
        <div
          className={`w-[25%] ${selected!=="More Options"?"bg_clr text-black":"bg_sc text-white"}  rounded-r-full cursor-pointer text-lg flex p-3 items-center justify-center`}
          onClick={() => setActive((prevState) => !prevState)}
        >
          {active ? <IoIosArrowUp /> : <IoIosArrowDown />}
        </div>
        {/* Container */}
        {active ? (
          <div className="text-center px-3 py-2 bg-gray-100 w-full rounded-md text-semibold absolute z-10 bottom-[-4.5em]">
            <p
              className="mx-1 py-2 w-full rounded-md transition hover:bg-gray-300 cursor-pointer"
              onClick={() => handleSelected("Export as PDF")}
            >
          Export as PDF
            </p>
            {/* <p
              className="mx-1 py-2 w-full rounded-md transition hover:bg-gray-300 cursor-pointer"
              onClick={() => handleSelected("Send Email")}
            >
             Send Email
            </p>
            <p
              className="mx-1 py-2 w-full rounded-md transition hover:bg-gray-300 cursor-pointer"
              onClick={() => handleSelected("Send Awareness training")}
            >
            Send Awareness training
            </p>
            <p
              className="mx-1 py-2 w-full rounded-md transition hover:bg-gray-300 cursor-pointer"
              onClick={() => handleSelected("Run Phishing simulation")}
            >
            Run Phishing simulation
            </p>
            <p
             style={{display:'none'}}
              className="mx-1 py-2 w-full rounded-md transition hover:bg-gray-300 cursor-pointer"
              onClick={() => handleSelected("Export to JSON")}
            >
          Export to JSON
            </p>
            <p
             style={{display:'none'}}
              className="mx-1 py-2 w-full rounded-md transition hover:bg-gray-300 cursor-pointer"
              onClick={() => handleSelected("Order data removal")}
            >
          Order data removal
            </p>
            <p
             style={{display:'none'}}
              className="mx-1 w-full py-2 rounded-md transition hover:bg-gray-300 cursor-pointer"
              onClick={() => handleSelected("Order background check")}
            >
           Order background check
            </p>
            <p 
            style={{display:'none'}}
              className="mx-1 w-full py-2 rounded-md transition hover:bg-gray-300 cursor-pointer"
              onClick={() => handleSelected("Order manual analysis")}
            >
          Order manual analysis
            </p> */}
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default Dropdown;