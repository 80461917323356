import React,{useState,useEffect} from "react";
import Intero from "../../assets/interrogation.svg";
import Slider from '@mui/material/Slider';
import { Tooltip as ReactTooltip } from "react-tooltip";
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';

export default function Card({ obj,handleChangeCommited , blur,openModal,country,industry}) {

  console.log(obj,'obj')
  const [sliderVal,setSliderVal]=useState(obj.number)
  const [tooltip,setTooltip]=useState(false)
  const [editable,setEditable]=useState(false);
  const getAppropriateCardColor=(score)=>{
    let val=parseInt(score);
    if(val<=20) return "text-[#08D176]"
    else if(val<=40) return "text-[#F4C65C]"
    else if(val<=60) return "text-[#F46A16]"
    else if(val<=80) return "text-[#FA372E]"
    return "text-[#C10303]"
  }
  const getAppropriateStatus=(score)=>{
    if(!score) return "bg-[#8A99A0]"
    let val=parseInt(score);
    if(val>0 && val<=20) return "bg-[#08D176]"
    else if(val<=40) return "bg-[#F4C65C]"
    else if(val<=60) return "bg-[#F46A16]"
    else if(val<=80) return "bg-[#FA372E]"
    else if(val<=100) return "bg-[#C10303]"
    return "bg-[#8A99A0]";
  }
 
  const getAppropriateStatusText=(score)=>{
    let val=parseInt(score);
    if(val>0 && val<=20) return "Low"
    else if(val<=40) return "Medium"
    else if(val<=60) return "High"
    else if(val<=80) return "Very High"
    else if(val<=100) return "Extremely High"
    return "Ready to Unlock"
  }

  function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      const cookieParts = cookie.split('=');
      if (cookieParts[0] === name) {
        return cookieParts[1];
      }
    }
    return null;
  }
useEffect(() => {
    const cookieValue = getCookie('editorAuth');
    if (cookieValue==='febf192c-8ff8-40ba-b18f-ca01bf119e7c') {
      setEditable(true);
    }
  }, []);


  return (
    <div key={obj.id}>
    <div className="flex mt-4 ">
      <div  className="pl-4 pr-2 pb-4 rounded-xl border pt-4 mr-3">
        <div className="flex justify-end">
          <div onClick={()=>{
            if(getAppropriateStatusText(sliderVal)==="Ready to Unlock"){
              openModal()
            }
          }} style={{maxHeight:'32px',textAlign:'center',cursor:'pointer'}} className={`mb-3 pt-2 pb-2 text-[#fff] w-[160px] h-12 text-[13px] text-center rounded-md pl-2 pr-2 ${getAppropriateStatus(sliderVal)}`}>
           {getAppropriateStatusText(sliderVal)}
          </div>
        </div>
        <div className="">
          <div className="flex">
            {!sliderVal?<img  

              onClick={openModal}
              style={{cursor:'pointer'}}
            src="/images/lock.svg" alt="" />:
           <h1
            className={`md:text-[44px] text-[26px] ${getAppropriateCardColor(
              sliderVal
            )} font-black`}
          >
              <p style={{ position: 'relative', zIndex: '1' }}>
                {sliderVal}
              </p>
          </h1>}
            <div className="pl-2">
              <div>out of 100 companies</div>
              <div style={{fontSize:'0.8rem'}} >{`${industry}, ${country}`}</div>
            </div>
          </div>
        </div>
        <div className="text-[#2B2B2B] pt-7 leading-normal  font-black text-[20px] pb-3">
          {obj.title}
          
        </div>
        <div className="text-[#2B2B2B] text-[13px]">
        {obj.desc}
        </div>
        <div className="flex justify-between pt-7">
          <div className=" text-[12px] text-[#000000]">
            Risk Level:
          </div>
          <div className=" text-[12px] text-[#000000] pr-4">
           {`Number of ${obj.tbl} :`}
          </div>
        </div>
        <div style={{marginTop:'18px'}} className="flex justify-between">
          <div style={{fontWeight:'500'}} className=" text-[12px] text-[#FA372E]">
           Very High
          </div>
          <div className=" text-[12px] text-[#000000] pr-4">
          {obj?.risk_levels["4"]}
          </div>
        </div>
        <div style={{marginTop:'12px'}} className="flex justify-between">
          <div style={{fontWeight:'500'}} className=" text-[12px] text-[#F46A16]">
            High
          </div>
          <div className=" text-[12px] text-[#000000] pr-4">
          {obj?.risk_levels["3"]}
          </div>
        </div>
        <div style={{marginTop:'12px'}} className="flex justify-between">
          <div style={{fontWeight:'500'}} className=" text-[12px] text-[#F4C65C]">
            Medium
          </div>
          <div className=" text-[12px] text-[#000000] pr-4">
          {obj?.risk_levels["2"]}
          </div>
        </div>
        <div style={{marginTop:'12px'}} className="flex justify-between">
          <div style={{fontWeight:'500'}} className=" text-[12px] text-[#09B467]">
            Low
          </div>
          <div className=" text-[12px] text-[#000000] pr-4">
          {obj?.risk_levels["1"]}
          </div>
        </div>
        <div style={{marginTop:'12px'}} className="flex justify-between">
          <div style={{fontWeight:'500'}} className=" text-[12px] text-[#8A99A0]">
            Unknown
          </div>
          <div className=" text-[12px] text-[#000000] pr-4">
           {obj?.risk_levels["0"]}
          </div>
        </div>
      </div>
    </div>
  </div>
      )
    }




  //   <div
  //   key={obj.id}
  //   style={{paddingBottom:'50px'}}
  //   className="w-full dark:bg-[#252229] px-4 h-auto pb-6 shadow-lg rounded-[16px] relative"
  // >
  //   {sliderVal && (
  //     <div>
  //       <div className="pt-3 flex justify-end">
  //         <button
  //           className={`rounded-lg md:text-sm text-xs text-white md:py-2 md:px-4 py-1 px-2 ${getAppropriateStatus(sliderVal)}`}
  //         >
  //           {blur ? (
  //             <p style={{ filter: 'blur(8px)', position: 'relative', zIndex: '1' }}>
  //               {getAppropriateStatusText(sliderVal)}
  //             </p>
  //           ) : (
  //             getAppropriateStatusText(sliderVal)
  //           )}
  //         </button>
  //       </div>
  //       <div className="flex items-center h-13 w-full">
  //         <h1
  //           className={`md:text-[44px] text-[26px] ${getAppropriateCardColor(
  //             sliderVal
  //           )} font-black`}
  //         >
  //           {blur ? (
  //             <p style={{ filter: 'blur(8px)', position: 'relative', zIndex: '1' }}>
  //               {sliderVal ? sliderVal : 'NA'}
  //             </p>
  //           ) : (
  //             sliderVal
  //           )}
  //         </h1>
  //         <div className="pl-2">
  //           <div className="poppins_bold_extra text-[10px]">out of 100 companies</div>
  //           <div className="poppins_medium text-[12px]">{`${industry}, ${country}`}</div>
  //         </div>
  //         <div
  //           className="hidden md:block h-full"
  //           data-tooltip-id={obj.id}
  //           style={{ float: 'right' }}
  //         >
  //           {/* <img src={Intero} alt="interogetion" /> */}
  //         </div>
  //         <ReactTooltip
  //           id={obj.id}
  //           place="right-start"
  //           variant="info"
  //           content={`Ranked more attractive than ${sliderVal}% of similar companies`}
  //           style={{
  //             fontWeight: 'bold',
  //             zIndex: 3,
  //             backgroundColor: '#252229',
  //             opacity: 0,
  //             border: '10px solid black',
  //           }}
  //         />
  //       </div>
  //     </div>
  //   )}
  //   {!sliderVal && (
  //     <div>
  //       <h2
  //         style={{ color: '#8A99A0' }}
  //         className={`md:text-[24px] text-[14px] ${getAppropriateCardColor(
  //           sliderVal
  //         )} font-black`}
  //       >
  //         Unknown
  //       </h2>
  //     </div>
  //   )}
  //   <div className="md:mt-5 mt-2 space-y-1">
  //     <h1 className="font-semibold dark:text-white md:text-xl text-lg text-[#2B2B2B]">
  //       {obj.title}
  //     </h1>
  //     <p className="font-normal dark:text-[#F9F6FB] text-sm md:text-base">
  //       {obj.desc}
  //     </p>
  //   </div>
  //   <div style={{ textAlign: 'center' }}>
  //     {editable && (
  //       <Slider
  //         style={{ width: '80%' }}
  //         value={sliderVal}
  //         onChangeCommitted={(event, value) => {
  //           handleChangeCommited(obj.key, value);
  //         }}
  //         onChange={(event, val) => setSliderVal(val)}
  //         aria-labelledby="continuous-slider"
  //       />
  //     )}
  //   </div>
  //   <div className="absolute bottom-4 left-0 right-0 flex justify-center">
  //     <Stack direction="row" spacing={1}>
  //     <div  className="flex items-center">
  //           <Avatar sx={{ bgcolor: '#D9D9D9', width: 16, height: 16 ,color:'#D9D9D9' }} >.</Avatar>
  //           <h3 className="ml-1" >{obj?.risk_levels["0"]}</h3>
  //         </div>
  //         <div  className="flex items-center">
  //             <Avatar sx={{ bgcolor: '#039D09', width: 16, height: 16 ,color:'#08D176' }} >.</Avatar>
  //           <h3 className="ml-1">{obj?.risk_levels["1"]}</h3>
  //         </div>
  //         <div  className="flex items-center">
  //             <Avatar sx={{ bgcolor: '#FF9B04', width: 16, height: 16 ,color:'#F4C65C' }} >.</Avatar>
  //           <h3 className="ml-1">{obj?.risk_levels["2"]}</h3>
  //         </div>
  //         <div  className="flex items-center">
  //             <Avatar sx={{ bgcolor: '#FA372E', width: 16, height: 16 ,color:'#F46A16' }} >.</Avatar>
  //           <h3 className="ml-1">{obj?.risk_levels["3"]}</h3>
  //         </div>
  //         <div  className="flex items-center">
  //             <Avatar sx={{ bgcolor: '#C10303', width: 16, height: 16 ,color:'#C10303' }} >.</Avatar>
  //           <h3 className="ml-1">{obj?.risk_levels["4"]}</h3>
  //         </div>
  //     </Stack>
  //   </div>
  // </div>
     