import React, { useState,useEffect } from "react";
import Container from "../globals/Container";
import { useNavigate } from "react-router-dom";
import LockIcon from '@mui/icons-material/Lock';

export default function CompanyOverView(props) {
  const [state, setState] = useState(props.tab);
  const navigate = useNavigate();
  const handleNavigate = (value) => {
 
    navigate(`${value}`);
  };

  useEffect(()=>{
    setState(props.tab);
  },[props.tab])

  return (
    <Container>
      <div className="py-5">
        <h1 className="md:text-[40px] text-[22px] py-3 text-[#2B2B2B] font-bold dark:text-white">
          Company Overview
        </h1>
        <div className="h-11 relative">
          <div class="overflow-x-auto border h-full rounded-full relative">
            <table class="table-auto w-full h-full rounded-full min-w-max">
              <thead className="h-full">
                <tr className="flex w-full h-full">
            <th
              onClick={() => {
                // handleNavigate("");
                props.changeTab('people');
                setState('people');
              }}
              className={`${
                state === "people"
                  ? "w-full h-full px-4 py-2 cursor-pointer flex justify-center items-center bg-[#1F0048] dark:bg-[#F9F6FB]  dark:text-[#2B2B2B] text-white border-[#D9D4DF] font-semibold md:text-base text-xs border"
                  : "w-full h-full px-4 py-2 cursor-pointer flex justify-center items-center bg-[#F1EEF4] dark:bg-[#252229] dark:text-[#E6E0E9] border-[#D9D4DF] font-semibold md:text-base text-xs border"
              } `}
            >
              People {"("+props.p_cnt+")"}
            </th>
            {/* <th
              onClick={() => {
                // handleNavigate(`/v/${magicId}/assets`);
                props.changeTab('assets');
                setState('assets');
              }}
              className={`${
                state === "assets"
                  ? "w-full h-full px-4 py-2 cursor-pointer flex justify-center items-center bg-[#1D1B20] dark:bg-[#F9F6FB]  dark:text-[#2B2B2B] text-white border-[#D9D4DF] font-semibold md:text-base text-xs border"
                  : "w-full h-full px-4 py-2 cursor-pointer flex justify-center items-center bg-[#F1EEF4] dark:bg-[#252229] dark:text-[#E6E0E9] border-[#D9D4DF] font-semibold md:text-base text-xs border"
              } `}
            >
              Assets
            </th> */}
            <th
              onClick={() => {
                // handleNavigate(`/v/${magicId}/systems`);
                props.changeTab('systems');
                setState('systems');
              }}
              className={`${
                state === "systems"
                  ? "w-full h-full px-4 py-2 cursor-pointer flex justify-center items-center bg-[#1F0048] dark:bg-[#F9F6FB]  dark:text-[#2B2B2B] text-white border-[#D9D4DF] font-semibold md:text-base text-xs border"
                  : "w-full h-full px-4 py-2 cursor-pointer flex justify-center items-center bg-[#F1EEF4] dark:bg-[#252229] dark:text-[#E6E0E9] border-[#D9D4DF] font-semibold md:text-base text-xs border"
              } `}
            >
              Systems {"("+props.s_cnt+")"}
            </th>
           
  {/* <button
    className="w-full whitespace-nowrap dark:bg-[#252229] dark:text-[#E6E0E9] bg-[#F1EEF4] border-[#D9D4DF] font-semibold md:text-base text-xs border"
    
  >
     <div style={{ position: 'relative', display: 'inline-block' }}>
    <p style={{ filter: 'blur(8px)', position: 'relative', zIndex: '1' }}>Third Parties</p> 
    <LockIcon
    className="absolute top-0 left-0 w-full h-full dark:text-[#E6E0E9]"
    style={{ width: '100%', height: '100%'}}
    />
  </div>
  </button> */}
      {/* <th
        onClick={() => {
          // handleNavigate(`/v/${magicId}/systems`);
          props.changeTab('third-parties');
          setState('third-parties');
        }}
      

        style={{backgroundColor:'grey'}}
        className="w-full h-full cursor-pointer flex justify-center items-center px-4 py-2 sm:px-0 whitespace-nowrap dark:bg-[#252229] dark:text-[#E6E0E9] bg-[#F1EEF4] border-[#D9D4DF] font-semibold md:text-base text-xs border"  
      >
        <span className="flex items-center justify-center">
          Third Parties
          <LockIcon style={{ width: '20px', height: '20px', marginLeft: '8px' }} />
        </span>
      </th> */}
        <th
              onClick={() => {
                // handleNavigate(`/v/${magicId}/attack-vectors`);
                props.changeTab('third-parties');
                setState('third-parties');
              }}
              className={`${
                state === "third-parties"
                  ? "w-full h-full px-4 py-2 cursor-pointer flex justify-center items-center bg-[#1F0048] sm:px-0 whitespace-nowrap dark:bg-[#F9F6FB]  dark:text-[#2B2B2B] text-white border-[#D9D4DF] font-semibold md:text-base text-xs border"
                  : "w-full h-full px-4 py-2 cursor-pointer flex justify-center items-center bg-[#F1EEF4] sm:px-0 whitespace-nowrap dark:bg-[#252229] dark:text-[#E6E0E9] border-[#D9D4DF] font-semibold md:text-base text-xs border"
              } `}
            >
            Third Parties {"("+props.tp_cnt+")"}
            </th>


            <th
              onClick={() => {
                // handleNavigate(`/v/${magicId}/attack-vectors`);
                props.changeTab('attack-vectors');
                setState('attack-vectors');
              }}
              className={`${
                state === "attack-vectors"
                  ? "w-full h-full px-4 py-2 cursor-pointer flex justify-center items-center bg-[#1F0048] sm:px-0 whitespace-nowrap dark:bg-[#F9F6FB]  dark:text-[#2B2B2B] text-white border-[#D9D4DF] font-semibold md:text-base text-xs border"
                  : "w-full h-full px-4 py-2 cursor-pointer flex justify-center items-center bg-[#F1EEF4] sm:px-0 whitespace-nowrap dark:bg-[#252229] dark:text-[#E6E0E9] border-[#D9D4DF] font-semibold md:text-base text-xs border"
              } `}
            >
              Threats {"("+props.th_cnt+")"}
            </th>

           

            
                </tr>

              </thead>
            </table>
          </div>
          {/* <button className="md:h-6 md:w-14 w-10 h-5 md:text-sm text-xs rounded-3xl absolute right-0 -top-4 text-white bg-[#843ECA]">
            +43
          </button> */}
          
        </div>
      </div>
    </Container>
  );
}


// add more info to 