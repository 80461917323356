import React from "react";

export default function TrustCard({ data }) {
  return (
    <div className="h-full w-full dark:bg-[#322E38] space-y-3 shadow-lg rounded-[32px] md:px-10 px-7 py-7">
      <div className="md:h-12 w-8 h-8 md:w-12">
        <img src={data.trust} alt="trust-center" />
      </div>
      <h1 className="md:text-[28px] text-xl dark:text-white font-bold">
        {data.heading}
      </h1>
      <p className="md:text-lg text-sm dark:text-white font-normal">
        {data.para}
      </p>
    </div>
  );
}
