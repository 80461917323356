import React, { useEffect } from "react";
import Container from "../globals/Container";
import Sun from "../../assets/sun.svg";
import Share from "../../assets/share.svg";
import Moon from "../../assets/moon.svg";
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Logo } from "../icons/icons";
import GlobalSearchBox from "../GloabSearchBox";
import {useParams,useNavigate} from 'react-router-dom'
import GoogleAuthLogout from "../GoogleAuthLogout";
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import useGlobalContext from "../../hooks/useGlobalContext";
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';

import Stack from '@mui/material/Stack';


export default function Navbar({ darkMode,startScan,reportOpts, toggleDarkMode,fr,hideSearch,setErrorScreen,onCompanySelect }) {

  const [open, setOpen] = React.useState(false);
  const [creds,setCreds]=React.useState(null)
  const [disabled,setDisable]=React.useState(false)
  const navigate = useNavigate();

  const {credits,getCredits}=useGlobalContext();

  const {logout,isAuthenticated,isLoading,user}=useKindeAuth();
  const handleClick = () => {
    setOpen(true);
  };



  const callGetCredits=async()=>{
   let result= await getCredits();
   setCreds(result);
  }
  useEffect(()=>{

    if(isLoading){
      return;
    }
    callGetCredits();

 
  },[isLoading])

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const action = (
    <React.Fragment>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      >
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const handleButtonClick = () => {
    // Show the toast notification
    var webLink = window.location.href; 

           var tempInput = document.createElement("input");
           tempInput.setAttribute("value", webLink);
           document.body.appendChild(tempInput);

           tempInput.select();
           tempInput.setSelectionRange(0, 99999); 
           document.execCommand("copy");
           document.body.removeChild(tempInput);
          
   setOpen(true)
  };

  const getAppropriateText = ( )=>{
    if(reportOpts?.partial_report) return "Order full report"
    else return "Order partial report"
  }
  return (
    <div className="dark:bg-[#141218] bg-white shadow w-full">
    <Container>
      <div className="flex lg:h-[72px] justify-between items-center py-2">
        <div
          title="X80"
          className="lg:ml-4 cursor-pointer text-[#2B2B2B] dark:text-white"
        >
          <Logo onClick={()=>{navigate('/search-companies')
          setErrorScreen()}
        } className="lg:h-[34px] lg:w-[88px] h-12 w-12" />
        </div>
        {/* {!hideSearch && <div className="flex-1 ml-4"> 
          <GlobalSearchBox 
           onCompanySelect={(company)=>{
            onCompanySelect(company);
           }}
          
          className="w-full lg:max-w-md" />
        </div>} */}
        <div className="flex space-x-3 items-center">
          {/* <div>
            <img
              src={darkMode ? Moon : Sun}
              alt="light-mode"
              className="lg:h-auto lg:w-auto h-5 w-5"
            />
          </div>
          <div className="flex w-auto items-center">
            <input
              checked={darkMode}
              onChange={toggleDarkMode}
              type="checkbox"
              id="toggle"
              className="hidden"
            />
            <label htmlFor="toggle" className="cursor-pointer relative w-12 h-7">
              <div className="w-full h-full bg-[#E5E1EA] dark:bg-[#D0BCFF] border-[#AEA8B5] rounded-full border-2 shadow-inner p-1"></div>
              <div className="absolute w-4 h-4 bg-[#AEA8B5] dark:bg-[#381E72] rounded-full top-1.5 shadow left-2 transition-transform"></div>
            </label>
          </div> */}
          {/* <a href="#unlock">
            {!fr && !reportOpts?.full_report && (
              <button 
          
              onClick={()=>{
                console.log('clicked')
                setDisable(true)
                startScan()
              }}  
              
            disabled={disabled}
              
              className="w-60 text-sm lg:block hidden font-semibold text-white rounded-3xl h-10 dark:bg-white dark:text-black bg-[#1F0048]">
                {getAppropriateText()}
              </button>
            )}
          </a> */}
           {isAuthenticated &&
          <div>
          
          <Stack direction="row" spacing={2}>
          <h2 style={{margin:'auto',fontSize:'2rem',fontWeight:'bold',color:'#843eca'}} >{credits?.remaining_credits?credits?.remaining_credits:0}</h2>  
          <Stack direction="column" spacing={0}>
           <h3 style={{fontWeight:'bold',color:'grey',fontWeight:'530',fontSize:'0.85rem'}} >Free Credits Left</h3> 
           <u><h2 onClick={() => {
               let b64_email=btoa(user.email)
               let url=`https://buy.stripe.com/test_14k4gh9oK7ZhczmbII?client_reference_id=${b64_email}&prefilled_email=${user.email}`
            
                      window.open(
                        url,
                        "_blank",
                      );
                    }} style={{fontWeight:'bold',color:'#843eca',cursor:'pointer'}}>Upgrade Now</h2></u>
          </Stack>  
         <Tooltip title={creds?.email_address}>
         <Avatar sx={{ bgcolor: 'black' ,width:45,height:45}}>{credits?.email_address?.substring(0,1).toUpperCase()}</Avatar>
         </Tooltip>
      </Stack>
          </div> 

          } 

          {!fr && <button
            onClick={handleButtonClick}
            className="lg:w-11 lg:h-11 w-10 h-10 md:bg-[#843ECA] bg-[#1F0048] flex justify-center items-center rounded-full"
          >
            <img
              src={Share}
              alt="share"
              className="lg:h-auto lg:w-auto h-6 w-6"
            />
          </button>}
         
          {isAuthenticated &&
            <Button
            // type="submit"
            // fullWidth
            // variant="contained"
            // sx={{ mt: 3, mb: 2 }}
            onClick={logout}
          >
            Logout 
          </Button>
          } 
        </div>
      </div>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        message="URL Copied!"
        action={action}
        key={'bottomright'}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      />
    </Container>
  </div>
  );
}
