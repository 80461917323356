import React from 'react';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';

const RoundedTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    borderRadius: '50px',
    '& fieldset': {
      borderRadius: '50px',
    },
  },
});
const SearchBar = (props) => {
  return (
    <RoundedTextField
      variant="outlined"
      placeholder={props.searchText}
      fullWidth
      value={props.searchValue}
      style={{borderRadius:'100%',width:'100%'}}
      onChange={props.handleOnChange}
      InputProps={{
        endAdornment:  (
          <InputAdornment position="end">
            {props.searchValue && (
              <IconButton edge="end" onClick={props.handleClear}>
                <CloseIcon style={{ backgroundColor: 'white', color: 'lightgray', fontSize: '1.5rem' }} />
              </IconButton>
            )}
            <IconButton edge="end">
              <ArrowCircleRightIcon style={{ backgroundColor: 'white', color: 'lightgray', fontSize: '2.5rem' }} />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
    
  );
};

export default SearchBar;
