import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px 20px;
`;

const Label = styled.span`
  flex: 1;
  text-align: center;
  font-size: 14px;
`;

const StyledSlider = styled(Slider)`
  flex: 5;

  .rc-slider-rail {
    background-color: #e9ecef;
  }

  .rc-slider-track {
    background-color: #7e3af2;
  }

  .rc-slider-handle {
    border: solid 2px #7e3af2;
    background: #7e3af2;
  }

  .rc-slider-handle:focus {
    box-shadow: none;
  }

  .rc-slider-dot {
    border: 1px solid gray;
    background: #fff;
    bottom: -2px;
  }

  .rc-slider-mark-text-active {
    color: #7e3af2;
    font-weight: bold;
  }

  .rc-slider-mark-text {
    white-space: nowrap;
  }
`;

const marks = {
  0: "Shallow",
  1: "Standard",
  2: "Custom",
//   3: "Very Deep",
};

const SliderComponent = ({ value, onChange }) => (
  <Wrapper>
    <StyledSlider
      min={0}
      max={2}
      marks={marks}
      step={null}
      value={value}
      onChange={onChange}
    />
  </Wrapper>
);

export default SliderComponent;
