import * as React from 'react';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

export default function CardRadioGrp(props) {
  
    const handleChange = (event) => {
        props.setValue(event.target.value)
      };
  return (
    <FormControl>
      <FormLabel   sx={{color:'#843ECA'}} id="demo-row-radio-buttons-group-label">Card Status</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={props.value}
        onChange={handleChange}
        sx={{color:'#843ECA'}}
      >
        <FormControlLabel value="0" control={<Radio />} label="Visible" />
        <FormControlLabel value="1" control={<Radio />} label="Locked" />
        <FormControlLabel value="2" control={<Radio />} label="Report Locked" />
        <FormControlLabel value="3" control={<Radio />} label="Hidden" />
      </RadioGroup>
    </FormControl>
  );
}
