import { useNavigate } from 'react-router-dom';
import {KindeProvider} from "@kinde-oss/kinde-auth-react"; // Replace with the actual package import

const KindeProviderWrapper = ({ children }) => {
  const navigate = useNavigate();

  return (
    <KindeProvider
      clientId="72d6d9f04bd7418c8257ddd024b3277c"
      domain="https://x80security.kinde.com"
      logoutUri={window.location.origin}
      redirectUri={window.location.origin}
      onRedirectCallback={(user, app_state) => {
        console.log('redirect url', app_state);
        if (app_state?.redirectTo) {
          navigate(app_state.redirectTo);
        }
      }}
    >
      {children}
    </KindeProvider>
  );
};

export default KindeProviderWrapper;