import React from 'react';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

export default function MultiSelect({ values, options, title,updateValues }) {
  const [selectedValues, setSelectedValues] = React.useState(values);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    console.log(value,'logging values')
    setSelectedValues(value);
    updateValues(value);
  };

  return (
    <div>
      <FormControl
        sx={{
          m: 1,
          width: '90%',
          '& .MuiInputLabel-root': {
            color: '#843ECA', // Font color for the label
          },
          '& .MuiSelect-root': {
            borderColor: '#843ECA', // Border color for the Select component
            '&:focus': {
              borderColor: '#843ECA', // Border color on focus
            },
            '&.MuiSelect-filled.MuiSelect-filled': {
              '& .MuiSelect-icon': {
                color: '#843ECA', // Color for the dropdown icon when selected
              },
            },
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#843ECA', // Border color for the OutlinedInput component
            },
          },
          '& .MuiMenuItem-root.Mui-selected': {
            color: '#843ECA !important', // Font color for the selected items inside the dropdown
          },
        }}
      >
        <InputLabel id="demo-multiple-checkbox-label">{title}</InputLabel>
        <Select
          labelId="demo-multiple-checkbox-label"
          id="demo-multiple-checkbox"
          multiple
          value={selectedValues}
          onChange={handleChange}
          style={{color:'#843ECA'}}
          input={
            <OutlinedInput
              label="Tag"
              sx={{
                '&.MuiOutlinedInput-notchedOutline': {
                  borderColor: '#843ECA', // Border color for the OutlinedInput component
                },
              }}
            />
          }
          renderValue={(selected) =>
            selected
              .map((value) =>
                options.find((opt) => opt.id === value)?.name || ''
              )
              .join(', ')
          }
        >
          {options.map((opt) => (
            <MenuItem key={opt.id} value={opt.id}>
              <Checkbox checked={selectedValues.indexOf(opt.id) > -1} />
              <ListItemText primary={opt.name} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
}
