import React from "react";
import LinkedIn from "../../assets/linkedin2.svg";
import { Logo } from "../icons/icons";
import {useParams,useNavigate} from 'react-router-dom'

export default function Footer() {

  const navigate=useNavigate();
  return (
    <div className="bg-[#26282B] flex flex-col justify-center items-center lg:h-[267px] h-[120px] mt-5 py-5 lg:py-10">
      <div className="container dark:bg-[#26282B] max-w-xxl mx-auto px-4 sm:px-8 lg:px-14 xl:px-16 xxl:px-24">
        <div className="grid w-full justify-center items-center grid-cols-3 lg:grid-cols-5">
          <div className="md:-mt-3">
            {/* <h1 className={`md:text-[44px] text-white text-2xl font-black`}>
              X80
            </h1> */}
            <div
              title="X80"
              className="lg:ml-4 cursor-pointer text-[#2B2B2B] dark:text-white"
            >
              <Logo className="lg:h-[34px] lg:w-[88px] h-12 w-12" />
            </div>
          </div>
          <div className="lg:block hidden">
            <h1 className="font-bold text-lg md:text-xl text-[#F9F6FB]">
              About Us
            </h1>
            <ul className="mt-3 space-y-2">
              <li className="text-[#FFFFFF] text-base">Privacy policy</li>
              <li className="text-[#FFFFFF] text-base">Terms & conditions</li>
              <li className="text-[#FFFFFF] text-base" onClick={()=>navigate("/trust-center")}>Trust Center</li>
            </ul>
          </div>
          <div className="lg:col-span-2 lg:block hidden">
            <h1 className="font-bold text-lg md:text-xl text-[#F9F6FB]">
              Addresses
            </h1>
            <ul className="mt-3 space-y-2">
              <li className="text-[#FFFFFF] text-base">
                Singapore: X80 Pte. Ltd
              </li>
              <li className="text-[#FFFFFF] text-base">
                France: X80 Security SAS
              </li>
              <li className="text-[#FFFFFF] text-base">
                USA: 501E Kennedy Blvd, Tampa, Florida
              </li>
            </ul>
          </div>
          <div className="lg:block hidden">
            <h1 className="font-bold text-lg md:text-xl text-[#F9F6FB]">
              Contact Us
            </h1>
            <ul className="mt-3 space-y-2">
              <li className="text-[#FFFFFF] text-base">
                support@x80security.com
              </li>
              <li className="flex space-x-2">
                <img src={LinkedIn} alt="linked-in" />
                <a href="https://www.linkedin.com/company/x80-security"><p className="text-[#FFFFFF] underline text-base">LinkedIn</p></a>
              </li>
            </ul>
          </div>
          <div className="block lg:hidden">
            <p className="font-normal underline text-sm text-[#FFFFFF]">
              Privacy policy
            </p>
          </div>
          <div className="block lg:hidden">
            <p className="font-normal underline whitespace-nowrap text-sm text-[#FFFFFF]">
              Terms & conditions
            </p>
          </div>
        </div>
        <div className="flex justify-center items-center mt-5 lg:mt-10">
          <p className="text-[#FFFFFF] text-sm md:text-base">
            © Copyright X80 2024. All rights reserved.
          </p>
        </div>
      </div>
    </div>
  );
}
