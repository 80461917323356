import React,{useState,useEffect} from "react";
import Container from "../globals/Container";
import { Arrow } from "../icons/icons";
import AssetCard from "../globals/AssetCard";

export default function Assets(props) {

  const [editable,setEditable]=useState(false);


  function getCookie(name) {
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      const cookieParts = cookie.split('=');
      if (cookieParts[0] === name) {
        return cookieParts[1];
      }
    }
    return null;
  }
  useEffect(() => {
    const cookieValue = getCookie('editorAuth');
    if (cookieValue==='febf192c-8ff8-40ba-b18f-ca01bf119e7c') {
      setEditable(true);
    }
  
  }, []);
  // console.log(props.data,'loggging data');
  const data = [
    {
      id: 1,
      title: "GEP SMART AI-First Source-to-Pay Procurement Software",
      department: "Software Solutions",
      desc: "Contains valuable corporate procurement data, potentially including financial transactions, vendor lists, pricing data, and trade secrets.",
      status: "Very High",
      bg: "bg-[#FA372E]",
      text: "text-[#FA372E]",
      cardStatus: "unlocked",
    },
    {
      id: 2,
      title: "GEP NEXXE Supply Chain Management Platform",
      department: "Software Solutions",
      desc: "Contains valuable corporate procurement data, potentially including financial transactions, vendor lists, pricing data, and trade secrets.Includes supply chain visibility and execution data that could be leveraged for interception or disruption of  physical goals.",
      status: "Low",
      bg: "bg-[#08D176]",
      text: "text-[#08D176]",
      cardStatus: "unlocked",
    },
    {
      id: 3,
      title: "GEP NEXXE Supply Chain Management Platform",
      department: "Software Solutions",
      desc: "Contains valuable corporate procurement data, potentially including financial transactions, vendor lists, pricing data, and trade secrets.Includes supply chain visibility and execution data that could be leveraged for interception or disruption of  physical goals.",
      status: "Low",
      bg: "bg-[#08D176]",
      text: "text-[#08D176]",
      cardStatus: "locked",
    },
  ];
  return (
    <Container>
      <div className="w-full py-5">
        <h1 className="lg:text-[36px] text-xl font-bold text-[#2B2B2B] dark:text-[#F9F6FB]">
          Targets
        </h1>
        <p className="xl:text-lg pt-2 md:text-base text-sm dark:text-white text-[#2B2B2B]">
          Catalog valuable digital and physical assets for targeted theft or
          ransom. This includes sensitive data 
          <br className="lg:block hidden" /> customer information, proprietary
          research, financial assets, and critical infrastructure components.
        </p>
      </div>
      <div className="grid lg:grid-cols-3 grid-cols-1 py-5 gap-5">
        {props.data.map((obj, _) => {
          if(!editable && obj.cardStatus==="3") return null;
          return (
          <div key={obj.uid} className="">
            <AssetCard fr={props.fr} changeTab={props.changeTab} attack_vectors={props.attack_vectors} updateAsset={props.updateAsset} data={obj} />
          </div>
        )})}
      </div>
      {!props.fr && <div className="py-7 flex justify-center flex-col items-center">
        <button className="md:w-[256px] w-[217px] space-x-3 font-semibold md:text-lg text-base dark:bg-[#F9F6FB] dark:text-[#2B2B2B] h-[49px] flex justify-center items-center rounded-[40px] bg-[#1F0048] text-white">
        <a href="#unlock">
          <p> Unlock All Assets</p>
          </a>
          <div className="mt-1">
            <Arrow />
          </div>
        </button>
        <hr className="w-full lg:mt-10 mt-5" />
      </div>}
    </Container>
  );
}
