import React,{useEffect,useState} from "react";
import { Arrow, Cancel } from "../icons/icons";
import lock from "../../assets/lock.svg";
import less from "../../assets/arrow-up-left.svg";
import CardRadioGrp from "./CardRadioGrp";
import MultiSelect from "./MultiSelect";
import Slider from '@mui/material/Slider';

import { styled } from '@mui/system';



const CustomSliderComponent = styled(Slider)(({ colorVal }) => ({
  color: colorVal,
  '& .MuiSlider-thumb': {
    display: 'none',
  }
}));

const CustomSlider = ({ value, onChange, color,max ,min}) => {
  return (
    <CustomSliderComponent
      value={value}
      // onChange={onChange}
      valueLabelDisplay="auto"
      aria-label="Custom color slider"
      colorVal={color} 
      max={max}
      min={min}
    />
  );
};

export default function AttackVectorCard({ data, match, setMatch ,updateAttackVector,systems,assets,people,fr}) {
  const [editable,setEditable]=useState(false);
  const [value,setValue]=useState(data?.cardStatus?data?.cardStatus:0)
  const [peopleVals, setPeopleVals] = React.useState((data?.people)?data.people:[]);
  const [systemsVals, setSystemsVals] = React.useState((data?.systems)?data.systems:[]);
  const [assetsVals, setAssetsVals] = React.useState((data?.assets)?data.assets:[]);
  const [roi,setRoi]=useState((data?.roi)?(data.roi):0)
  const [bi,setBI]=useState((data.business_impact))
  const [intensity,setIntensity]=useState(data.intensity)
  const [probVal,setProbVal]=useState(parseFloat(data?.probability).toFixed(2))

  // console.log(data?.cardStatus,'attack vector',data.attackVector_name)
  const getAppropriateBg=(val)=>{
    if(val==="Low") return "bg-[#08D176]"
    else if(val==="Medium") return "bg-[#F4C65C]"
    else if(val==="High")  return "bg-[#F46A16]"
    else if(val==="Very High") return "bg-[#FA372E]"
    return "text-[#C10303]"
  }
  const getAppropriateTxt=(val)=>{
    if(val==="Low") return "text-[#08D176]"
    else if(val==="Medium") return "text-[#F4C65C]"
    else if(val==="High") return "text-[#F46A16]"
    else if(val==="Very High") return "text-[#FA372E]"
    return "text-[#C10303]"
  }



const getStaus=(value)=>{
  let val=value;
  console.log(value,'intensity')
  if(val<=20) return "Low"
  else if(val<=40) return "Medium"
  else if(val<=60) return "High"
  else if(val<=80) return "Very High"
  else if(val<=100) return "Extremely High"
  return "Low"
}
const getAssetValueLabel=(val)=>{
 
  let i=parseInt(val);
  if(i>=0 && i<=5) return "Low"
  else if(i>=6 && i<=10) return "Medium"
  else if(i>=11 && i<=15) return "High"
  else if(i>=16 && i<=20) return "Very High"
  return "Extremely High"
}
const getAttackerCostLabel=(val)=>{
 
  let i=parseInt(val);
  if(i>=0 && i<=2) return "Low"
  else if(i>=3 && i<=4) return "Medium"
  else if(i>=5 && i<=6) return "High"
  else if(i>=7 && i<=8) return "Very High"
  return "Extremely High"
}

const getProbLabel=(val)=>{
 
  let i=parseInt(val);
  if(i>=0 && i<=20) return "Low"
  else if(i>=21 && i<=40) return "Medium"
  else if(i>=41 && i<=60) return "High"
  else if(i>=61 && i<=80) return "Very High"
  return "Extremely High"
}

const getRoiLabel = (val)=>{
  let i=parseFloat(val)
  // console.log(i,'roi val')
  if(i<=1) return "Low"
  else if(i>1 && i<=2) return "Medium"
  else if(i>2 && i<=5) return "High"
  else if(i>5 && i<=10) return "Very High"
  return "Extremely High"
}

const getAppropriateBI = (val)=>{
  if(!val) return "Low"
  let i=parseFloat(val)
  if(i<=5) return "Low"
  else if(i<=15) return "Medium"
  else if(i<=30) return "High"
  else if(i<=50) return "Very High"
  return "Extremely High"
}
function getCookie(name) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    const cookieParts = cookie.split('=');
    if (cookieParts[0] === name) {
      return cookieParts[1];
    }
  }
  return null;
}




const roiCalculator=(attack_vector)=>{
  if(!attack_vector) return;
  let prob=probVal?parseFloat(probVal).toFixed
  (2):0;
  let cost=attack_vector.attacker_cost?parseFloat(attack_vector.attacker_cost):0;
  let profit=0;
  for(let i=0;i<attack_vector.assets_values.length;i++){
    profit=profit+parseInt(attack_vector.assets_values[i]);
  }
  // console.log(prob,cost,profit,'roiii')
  if(cost===0 || prob===0 || profit===0) 
  {       
      setRoi("0")
      return;
  }
  let roi=(prob*profit-cost)/cost;
  setRoi(roi.toFixed(2).toString());
  // console.log(roi,'roi calc')
  return roi.toFixed(2).toString()
}
const handleSliderChangeProb =(event, newValue) => {
  let updatedAttackVector = { ...data, probability: (newValue/100.0).toFixed(2) };
  let roi=roiCalculator(updatedAttackVector)
  
  setProbVal(newValue/100.0);
};

const handleSliderChangeCommittedProb = (event, newValue) => {
  //    console.log('commited')
      let newVal=newValue/100.0;
      setProbValNew(newVal)
      setProbVal(newVal)
    };


    const handleSliderChangeBI =(event, newValue) => {
      let updatedAttackVector = { ...data, business_impact: newValue };
      setBI(newValue);
    };
    
    const handleSliderChangeCommittedBI= (event, newValue) => {
          setBINew(newValue)
          setBI(newValue)
        };    

useEffect(() => {
  const cookieValue = getCookie('editorAuth');
  if (cookieValue==='febf192c-8ff8-40ba-b18f-ca01bf119e7c') {
    setEditable(true);
  }
}, []);

const setProbValNew=(value)=>{
  let newAttackVector=JSON.parse(JSON.stringify(data));
  newAttackVector.probability=value;
  let roii=roiCalculator(newAttackVector);;
  newAttackVector.roi=roii;
  let x=calculateResult(roii,bi)
  newAttackVector.intensity=x
  setIntensity(x);
  updateAttackVector(newAttackVector,false);
}



useEffect(()=>{
  let newAttackVector=JSON.parse(JSON.stringify(data));
  newAttackVector.cardStatus=value;
  newAttackVector.probability=probVal;
  let roii=roiCalculator(newAttackVector);;
  newAttackVector.roi=roii;
  let x=calculateResult(roii,bi)
  newAttackVector.intensity=x
  setIntensity(x);
  updateAttackVector(newAttackVector,false);
  },[value])

  const handleValuesSave=()=>{
    let newAttackVector=JSON.parse(JSON.stringify(data));
    newAttackVector.people=peopleVals;
    newAttackVector.systems=systemsVals;
    newAttackVector.assets=assetsVals;
    updateAttackVector(newAttackVector,true);
  }

  function calculateResult(roi, bi) {
    const result = Math.min(Math.max(Math.sqrt(Math.max(0.01, roi) * Math.pow(bi, 1.5)), 0), 100);
    return result.toString();
  }

 const setBINew=(bi)=>{
  let newAttackVector=JSON.parse(JSON.stringify(data));
  newAttackVector.business_impact=bi;
  let x=calculateResult(roi,bi)
  newAttackVector.intensity=x
  setIntensity(x);
 updateAttackVector(newAttackVector,false);
 }




  return (
    <div className="relative h-full">
      <div className="w-full relative dark:bg-[#252229] space-y-3 px-4 pt-1 h-full overflow-hidden pb-6 shadow-lg rounded-[16px]">
        <div
          className={`flex mt-2 relative w-full ${
            data.uid === match ? "md:justify-center justify-end" : "justify-end"
          }`}
        >
         <button
              className={`rounded-lg md:text-sm ${
                data.uid === match ? "px-16 py-3" : "md:px-4 py-1 px-2 md:py-2"
              } text-xs text-white ${getAppropriateBg(getStaus(intensity))}`}
          >
            {editable?parseInt(intensity):getStaus(intensity)}
          </button>
          <button
            onClick={() => setMatch(-1)}
            className={`h-11 text-[#2B2B2B] ${
              data.uid === match ? "md:flex hidden" : "hidden"
            } dark:bg-[#322E38] dark:text-white cursor-pointer absolute right-0 flex justify-center items-center bg-[#F1EEF4] rounded-full w-11`}
          >
            <Cancel />
          </button>
        </div>
        {/* {editable && <div>
            <CardRadioGrp value={value} setValue={setValue} />
          </div>} */}
        <div className="grid xxl:grid-cols-3 lg:grid-cols-2 grid-cols-1 mt-4 gap-3 xxl:gap-5">
          <div className={`space-y-3 ${data.uid !== match ? "col-span-3" : ""}`}>
            <div className="mt-3 space-y-3">
              <h1 className="font-semibold text-lg md:text-xl dark:text-[#F9F6FB]">
                {data.name}
              </h1>
            </div>
            {/* <div className="space-y-1">
              <p className="text-[13px] text-[#888888]">Description</p>
              <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-normal lg:text-base text-sm">
                {data.description}
              </p>
            </div> */}
           

            {/* <div className="space-y-1">
              <p className="text-[13px] text-[#888888]">Source Node</p>
              <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-normal lg:text-base text-sm">
                {data.source_node}
              </p>
            </div>
            <div className="space-y-1">
              <p className="text-[13px] text-[#888888]">Destination Node</p>
              <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-normal lg:text-base text-sm">
                {data.destination_node}
              </p>
            </div> */}
        
            <div className="space-y-1">
              <p className="text-[13px] text-[#888888]">ROI</p>
              <div style={{display:'flex'}}>
              <div style={{flex:1.5}}>
              <CustomSlider value={roi} min={0} max={25} color={getAppropriateTxt(getRoiLabel(roi)).substring(getAppropriateTxt(getRoiLabel(roi)).indexOf('#'), getAppropriateTxt(getRoiLabel(roi)).indexOf('#') + 7)} />
              </div>
              <div style={{flex:1}}>
              <p style={{paddingLeft:'20px'}} className={`font-semibold text-lg ${getAppropriateTxt(getRoiLabel(roi))}`}>
                {editable?roi:getRoiLabel(roi)}
              </p>
              </div>
              </div>
            </div>
            <div className="space-y-1">
              <p className="text-[13px] text-[#888888]">Business Impact</p>
             <div style={{display:'flex'}}>
             <div style={{flex:1.5}}>
              <CustomSlider value={bi?bi.toString():"0"} min={0} max={100} color={getAppropriateTxt(getAppropriateBI(bi)).substring(getAppropriateTxt(getAppropriateBI(bi)).indexOf('#'), getAppropriateTxt(getAppropriateBI(bi)).indexOf('#') + 7)} />
              </div>
              <div style={{flex:1}}>
              <p style={{paddingLeft:'20px'}}  className={`font-semibold text-lg ${getAppropriateTxt(getAppropriateBI(bi))}`}>
                {editable?bi:getAppropriateBI(bi)}
              </p>
              </div>

              </div>
              {editable && <Slider value={parseInt(bi)} 
                style={{width:'70%'}}
                max={100}
                onChangeCommitted={handleSliderChangeCommittedBI}
                onChange={handleSliderChangeBI} aria-labelledby="continuous-slider" />}
              {/* {editable && <Slider value={probVal} 
                style={{width:'70%'}}
                step={20}
                 onChangeCommitted={handleSliderChangeCommittedProb}
                onChange={handleSliderChangeProb} aria-labelledby="continuous-slider" />} */}
            </div>
            <div className="space-y-1">
              <p className="text-[13px] text-[#888888]">Probability</p>
              <p className={`font-semibold text-lg dark:text-[#E6E0E9]`}>
                {editable?probVal*100:getProbLabel(probVal*100)}
              </p>
              {editable && <Slider value={probVal*100} 
                style={{width:'70%'}}
                max={100}
                steps={10}
                onChangeCommitted={handleSliderChangeCommittedProb}
                onChange={handleSliderChangeProb} aria-labelledby="continuous-slider" />}
            </div>
            <div className="space-y-1">
              <p className="text-[13px] text-[#888888]">Attacker Cost</p>
              <p className={`font-semibold text-lg dark:text-[#E6E0E9]`}>
                {editable?data.attacker_cost:getAttackerCostLabel(data.attacker_cost)}
              </p>
            </div>
            <div className="space-y-1`">
              <p className="text-[13px] text-[#888888]">Asset Value</p>
              <p className={`font-semibold text-lg dark:text-[#E6E0E9]`}>
                {editable?data.assets_values[0]:getAssetValueLabel(data.assets_values[0])}
              </p>
            </div>
            {/* <div
            className={`${
              data.uid === match ? "block" : "hidden"
            }`}
          >
            
            </div> */}
            <div
              onClick={() => setMatch(data.uid)}
              className={`text-[#843ECA] cursor-pointer flex space-x-2 items-center ${
                data.uid !== match ? "" : "hidden"
              }`}
            >
              <p className="font-semibold text-lg underline">Read more</p>
              <div className="mt-1">
                <Arrow />
              </div>
            </div>
          </div>
         
          <div
            className={`${
              data.uid === match ? "block" : "hidden"
            } mt-2 space-y-3`}
          >
             <div className="space-y-1">
              <p className="text-[13px] text-[#888888]">Description</p>
              <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-normal lg:text-base text-sm">
                {data.description}
              </p>
          </div>
          <p className="text-[13px] text-[#888888]">Facts</p>
        <div className={`${data.uid === match ? "block" : "hidden"}`}>
          <ul className="list-disc text-[#2B2B2B] dark:text-[#E6E0E9] font-normal text-base pl-[20px]">
            {data?.steps.map((obj, index) => {
              if (index > 1) return null; 
              return (
                <li key={index}>
                  {obj}
                </li>
              );
            })}
          </ul>
        </div>
          </div>

          <div
            className={`${
              data.uid === match ? "block" : "hidden"
            } space-y-3 mt-2`}
          >
            
            <div className={`${data.uid === match ? "block" : "hidden"}`}>
            <ul className="list-disc text-[#2B2B2B] dark:text-[#E6E0E9] font-normal text-base pl-[20px]">
             {data?.steps.map((obj,_)=>{
              if(_<=1)return null
              return (<li>
                {obj}
              </li>)
             })}
            </ul>
          {editable && <>
          <div className="text-[#2B2B2B] dark:text-[#E6E0E9]">
            <MultiSelect options={systems} title={'Systems'} values={systemsVals} updateValues={setSystemsVals}/>
          </div> 
          <div className="text-[#2B2B2B] dark:text-[#E6E0E9]">
            <MultiSelect options={people} title={'People'} values={peopleVals} updateValues={setPeopleVals}/>
          </div> 
          <div className="text-[#2B2B2B] dark:text-[#E6E0E9]">
            <MultiSelect options={assets} title={'Assets'} values={assetsVals} updateValues={setAssetsVals}/>
          </div> 
          </>}
          </div> 
          {editable && <div style={{textAlign:'center'}}>        
        <button  onClick={()=>handleValuesSave()} className="w-40 text-sm font-semibold text-white rounded-3xl h-10 dark:bg-white dark:text-black bg-[#1F0048]">
                Save 
        </button>
        </div>}
         
          </div>
         
        </div>
       
       
        <div
          onClick={() => setMatch(-1)}
          className={`text-[#843ECA] cursor-pointer flex space-x-2 items-center ${
            data.uid === match ? "lg:hidden block" : "hidden"
          }`}
        >
          <p className="font-semibold text-lg underline">Read Less</p>
          <img src={less} alt="" />
        </div>
      </div>
      

      <div
        className={`w-full absolute rounded-[16px] flex justify-center flex-col items-center h-full backdrop-blur-md bg-white/40 top-0 ${
          (value===0 || value===1) ? "" : "hidden"
        }`}
      >
        <img
        
        style={{cursor:'pointer'}}
        src={lock} alt="unlock" />
        {/* <p className="text-[#843ECA] font-semibold text-xl">+3 Systems</p> */}
        {/* <h1 className="text-[#843ECA] underline font-bold text-[22px]">
          Unlock
        </h1> */}
         <a 
        style={{cursor:'pointer'}}
         
         onClick={(e)=>{

          //make unlock call
        }} >
        <h1 className="text-[#843ECA] underline font-bold text-[22px]">
        {(value===0)?"Unlock Report":"Unlocking in Progress"}
        </h1>
        </a>
      </div>
    </div>
  );
}
