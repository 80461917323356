import React,{useEffect,useState} from "react";
import { Arrow, Cancel } from "../icons/icons";
import lock from "../../assets/lock.svg";
import less from "../../assets/arrow-up-left.svg";
import CardRadioGrp from "./CardRadioGrp";
import MultiSelect from "./MultiSelect";
import Slider from '@mui/material/Slider';
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import { styled } from '@mui/system';



const CustomSliderComponent = styled(Slider)(({ colorVal }) => ({
  color: colorVal,
  '& .MuiSlider-thumb': {
    display: 'none',
  }
}));

const CustomSlider = ({ value, onChange, color,max ,min}) => {
  return (
    <CustomSliderComponent
      value={value}
      // onChange={onChange}
      valueLabelDisplay="auto"
      aria-label="Custom color slider"
      colorVal={color} 
      max={max}
      min={min}
    />
  );
};

export default function ThreatCard({ unlockCard,data,isSelected, match, setMatch ,updateAttackVector,systems,assets,people,fr}) {
  const [editable,setEditable]=useState(false);
  const [value,setValue]=useState(data?.cardStatus?data?.cardStatus:0)
  const [threat,setThreat]=useState(data);
  const [peopleVals, setPeopleVals] = React.useState((data?.people)?data.people:[]);
  const [systemsVals, setSystemsVals] = React.useState((data?.systems)?data.systems:[]);
  const [assetsVals, setAssetsVals] = React.useState((data?.assets)?data.assets:[]);
  const [cof,setCof]=useState((data?.cost_to_fix)?(data.cost_to_fix):0)
  const [bi,setBI]=useState((data.business_impact))
  const [intensity,setIntensity]=useState(data.intensity)
  const [statusCheck,setStatusCheck]=useState(0);
  const [att,setAtt]=useState(data?.attractiveness_to_attacker?data?.attractiveness_to_attacker:0)


  const {getToken}=useKindeAuth();
  // console.log(data?.cardStatus,'attack vector',data.attackVector_name)
  const getAppropriateBg=()=>{
    if(!threat?.risk_level || threat?.risk_level==="0") return "bg-[#D9D9D9]"
    else if(threat?.risk_level==="1") return "bg-[#08D176]"
    else if(threat?.risk_level==="2") return "bg-[#F4C65C]"
    else if(threat?.risk_level==="3") return "bg-[#F46A16]"
    return "bg-[#C10303]"
  }
  const getAppropriateTxt=(val)=>{
    if(val==="Low") return "text-[#08D176]"
    else if(val==="Medium") return "text-[#F4C65C]"
    else if(val==="High") return "text-[#F46A16]"
    else if(val==="Very High") return "text-[#FA372E]"
    return "text-[#C10303]"
  }



  const getStaus=()=>{
    // let val=parseInt(intensity);
    if(!threat?.risk_level || threat?.risk_level==="0") return "Ready-to-Unlock"
    else if(threat?.risk_level==="1") return "Low"
    else if(threat?.risk_level==="2") return "Medium"
    else if(threat?.risk_level==="3") return "High"
    return "Extremely High"
  }
const getAssetValueLabel=(val)=>{
 
    if(val<=15) return "Low"
    else if(val<=65) return "Medium"
    else if(val<=85) return "High"
    else if(val<=95) return "Very High"
    else if(val<=100) return "Extremely High"
    return "Low"
}
const getAttackerCostLabel=(val)=>{
 
    if(val<=15) return "Low"
    else if(val<=65) return "Medium"
    else if(val<=85) return "High"
    else if(val<=95) return "Very High"
    else if(val<=100) return "Extremely High"
    return "Low"
}
const extractHexColor = (str) => {
  const startIndex = str.indexOf('#');
  if (startIndex === -1) return null; // '#' not found in the string

  return str.substring(startIndex, startIndex + 7);
};

const getProbLabel=(val)=>{
 
  let i=parseInt(val);
  if(i>=0 && i<=20) return "Low"
  else if(i>=21 && i<=40) return "Medium"
  else if(i>=41 && i<=60) return "High"
  else if(i>=61 && i<=80) return "Very High"
  return "Extremely High"
}

const getRoiLabel = (val)=>{
  let i=parseFloat(val)
  // console.log(i,'roi val')
  if(i<=1) return "Low"
  else if(i>1 && i<=2) return "Medium"
  else if(i>2 && i<=5) return "High"
  else if(i>5 && i<=10) return "Very High"
  return "Extremely High"
}

const getAppropriateBI = (val)=>{
  if(!val) return "Low"
  let i=parseFloat(val)
  if(i<=15) return "Low"
  else if(i<=65) return "Medium"
  else if(i<=85) return "High"
  else if(i<=95) return "Very High"
  return "Extremely High"
}
function getCookie(name) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    const cookieParts = cookie.split('=');
    if (cookieParts[0] === name) {
      return cookieParts[1];
    }
  }
  return null;
}




// const roiCalculator=(attack_vector)=>{
//   if(!attack_vector) return;
//   let prob=probVal?parseFloat(probVal).toFixed
//   (2):0;
//   let cost=attack_vector.attacker_cost?parseFloat(attack_vector.attacker_cost):0;
//   let profit=0;
//   for(let i=0;i<attack_vector.assets_values.length;i++){
//     profit=profit+parseInt(attack_vector.assets_values[i]);
//   }
//   // console.log(prob,cost,profit,'roiii')
//   if(cost===0 || prob===0 || profit===0) 
//   {       
//       setRoi("0")
//       return;
//   }
//   let roi=(prob*profit-cost)/cost;
//   setRoi(roi.toFixed(2).toString());
//   // console.log(roi,'roi calc')
//   return roi.toFixed(2).toString()
// }
const handleSliderChangeProb =(event, newValue) => {
//   let updatedAttackVector = { ...data, probability: (newValue/100.0).toFixed(2) };
//   let roi=roiCalculator(updatedAttackVector)
  
//   setProbVal(newValue/100.0);
setAtt(newValue);
};

const handleSliderChangeCommittedProb = (event, newValue) => {
  //    console.log('commited')
    //   let newVal=newValue/100.0;
    //   setProbValNew(newVal)
    //   setProbVal(newVal)
    setAtt(newValue);
    setATTNew(newValue)
    };

    useEffect(()=>{
        let newAttackVector=JSON.parse(JSON.stringify(data));
        newAttackVector.cardStatus=value;
        
        newAttackVector.intensity=intensity;
        updateAttackVector(newAttackVector,false);
        },[value])


    const handleSliderChangeBI =(event, newValue) => {
      let updatedAttackVector = { ...data, business_impact: newValue };
      setBI(newValue);
    };
    
    const handleSliderChangeCommittedBI= (event, newValue) => {
          setBINew(newValue)
          setBI(newValue)
        };    
        const handleSliderChangeCOF =(event, newValue) => {
            let updatedAttackVector = { ...data, business_impact: newValue };
            setCof(newValue);
          };
          
          const handleSliderChangeCommittedCOF= (event, newValue) => {
                setCof(newValue)
                setCofNew(newValue)
              };    
useEffect(() => {
  const cookieValue = getCookie('editorAuth');
  if (cookieValue==='febf192c-8ff8-40ba-b18f-ca01bf119e7c') {
    setEditable(true);
  }
}, []);







  const handleValuesSave=()=>{
    let newAttackVector=JSON.parse(JSON.stringify(data));
    newAttackVector.people=peopleVals;
    newAttackVector.systems=systemsVals;
    newAttackVector.assets=assetsVals;
    updateAttackVector(newAttackVector,true);
  }

//   function calculateResult(roi, bi) {
//     const result = Math.min(Math.max(Math.sqrt(Math.max(0.01, roi) * Math.pow(bi, 1.5)), 0), 100);
//     return result.toString();
//   }

 const setBINew=(bi)=>{
  let newAttackVector=JSON.parse(JSON.stringify(data));
  newAttackVector.business_impact=bi;
 updateAttackVector(newAttackVector,false);
 }

 const setATTNew=(val)=>{
    let newAttackVector=JSON.parse(JSON.stringify(data));
    newAttackVector.attractiveness_to_attacker=val;
   updateAttackVector(newAttackVector,false);
   }

 const handleSliderChangeIntensity =(event, newValue) => {
    console.log(newValue,'intensity change')
    setIntensity(newValue);
  };
  
  const handleSliderChangeIntensityCommited= (event, newValue) => {
    console.log(newValue,'intensity commited')
        setIntensity(newValue);
    setIntensityNew(newValue)

      };    


      const setIntensityNew=(val)=>{
        let newAttackVector=JSON.parse(JSON.stringify(data));
  newAttackVector.intensity=parseInt(val);
 updateAttackVector(newAttackVector,false);
       }    

       const setCofNew=(val)=>{
        let newAttackVector=JSON.parse(JSON.stringify(data));
  newAttackVector.cost_to_fix=val;
 updateAttackVector(newAttackVector,false);
       }    


       const getCardData = async ()=>{
 
        let token= await getToken()
        try {
          let bdy=[threat.uid];
          const response = await fetch(`https://scanner.x80security.com/reports/fetchCardsDetails`,
          {
            method: 'POST',
            headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
              // Add CORS headers
              "Access-Control-Allow-Origin": "*", // Allow requests from any origin
              "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS", // Allow specific HTTP methods
              "Access-Control-Allow-Headers": "Content-Type, Authorization", // Allow specific headers
              "Access-Control-Allow-Credentials": true // Allow credentials (cookies, authorization headers, etc.)
          },
           body:JSON.stringify({card_ids:bdy})
          });
    
          const responseData = await response.json();
          
          if (!response.ok) {
    
            throw new Error('Network response was not ok ' + response.statusText);
          }
          let data=responseData?.result[threat.uid];
          if(data.cardStatus!==1){
            setThreat(data)
            setValue(data.cardStatus);
          }else{
            setStatusCheck(!statusCheck)
          }
          
          
        } catch (error) {
          console.log(error);
        }
      }
       useEffect(()=>{ 

        if(threat.cardStatus===1 || value===1){
          setTimeout(()=>{
            getCardData();
          },50000)
        }
      },[statusCheck,value])



  return (
    <div className="relative h-full">
      <div style={{borderWidth:(isSelected)?'2.5px':'0.1px',borderColor:(isSelected)?extractHexColor(getAppropriateBg()):'lightgray',borderStyle:'solid'}} className="w-full relative dark:bg-[#252229] space-y-3 px-4 pt-1 h-full overflow-hidden pb-6 shadow-lg rounded-[16px]">
        <div
          className={`flex mt-2 relative w-full ${
            threat?.uid === match ? "md:justify-center justify-end" : "justify-end"
          }`}
        >
         <button
              className={`rounded-lg md:text-sm ${
                threat?.uid === match ? "px-16 py-3" : "md:px-4 py-1 px-2 md:py-2"
              } text-xs text-white ${getAppropriateBg(getStaus(parseInt(intensity)))}`}
          >
            {editable?parseInt(intensity):getStaus(parseInt(intensity))}
          </button>
          <button
            onClick={() => setMatch(-1)}
            className={`h-11 text-[#2B2B2B] ${
              threat?.uid === match ? "md:flex hidden" : "hidden"
            } dark:bg-[#322E38] dark:text-white cursor-pointer absolute right-0 flex justify-center items-center bg-[#F1EEF4] rounded-full w-11`}
          >
            <Cancel />
          </button>
        </div>
        {editable && <div>
            <CardRadioGrp value={value} setValue={setValue} />
          </div>}
        <div className="grid xxl:grid-cols-3 lg:grid-cols-2 grid-cols-1 mt-4 gap-3 xxl:gap-5">
          <div className={`space-y-3 ${threat?.uid !== match ? "col-span-3" : ""}`}>
            <div style={{visibility:!(value === 0 || value === 1)?"":"hidden"}}  className="mt-3 space-y-3">
              <h1 className="font-semibold text-lg md:text-xl dark:text-[#F9F6FB]">
                {threat?.name}
              </h1>
            </div>
            {/* <div className="space-y-1">
              <p className="text-[13px] text-[#888888]">Description</p>
              <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-normal lg:text-base text-sm">
                {threat?.description}
              </p>
            </div> */}
           

            {/* <div className="space-y-1">
              <p className="text-[13px] text-[#888888]">Source Node</p>
              <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-normal lg:text-base text-sm">
                {threat?.source_node}
              </p>
            </div>
            <div className="space-y-1">
              <p className="text-[13px] text-[#888888]">Destination Node</p>
              <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-normal lg:text-base text-sm">
                {threat?.destination_node}
              </p>
            </div> */}
         <div className="space-y-1">
              <p className="text-[13px] text-[#888888]">Business Impact</p>
             <div style={{display:'flex'}}>
             <div style={{flex:1.5}}>
              <CustomSlider value={bi?bi.toString():"0"} min={0} max={100} color={getAppropriateTxt(getAppropriateBI(bi)).substring(getAppropriateTxt(getAppropriateBI(bi)).indexOf('#'), getAppropriateTxt(getAppropriateBI(bi)).indexOf('#') + 7)} />
              </div>
              <div style={{flex:1}}>
              <p style={{paddingLeft:'20px'}}  className={`font-semibold text-lg ${getAppropriateTxt(getAppropriateBI(bi))}`}>
                {editable?bi:getAppropriateBI(bi)}
              </p>
              </div>

              </div>
              {editable && <Slider value={parseInt(bi)} 
                style={{width:'70%'}}
                max={100}
                onChangeCommitted={handleSliderChangeCommittedBI}
                onChange={handleSliderChangeBI} aria-labelledby="continuous-slider" />}
              {/* {editable && <Slider value={probVal} 
                style={{width:'70%'}}
                step={20}
                 onChangeCommitted={handleSliderChangeCommittedProb}
                onChange={handleSliderChangeProb} aria-labelledby="continuous-slider" />} */}
            </div>
            <div className="space-y-1">
              <p className="text-[13px] text-[#888888]">Cost to fix</p>
              <div style={{display:'flex'}}>
              <div style={{flex:1.5}}>
              <CustomSlider value={cof} min={0} max={100} color={getAppropriateTxt(getAppropriateBI(cof)).substring(getAppropriateTxt(getAppropriateBI(cof)).indexOf('#'), getAppropriateTxt(getAppropriateBI(cof)).indexOf('#') + 7)} />
              </div>
              <div style={{flex:1}}>
              <p style={{paddingLeft:'20px'}} className={`font-semibold text-lg ${getAppropriateTxt(getAppropriateBI(cof))}`}>
                {editable?cof:getAppropriateBI(cof)}
              </p>
              </div>
              </div>
              {editable && <Slider value={parseInt(cof)} 
                style={{width:'70%'}}
                max={100}
                onChangeCommitted={handleSliderChangeCommittedCOF}
                onChange={handleSliderChangeCOF} aria-labelledby="continuous-slider" />}
            </div>
           
            <div className="space-y-1">
              <p className="text-[13px] text-[#888888]">Attractiveness to Attacker</p>
              <div style={{display:'flex'}}>
              <div style={{flex:1.5}}>
              <CustomSlider value={att} min={0} max={100} color={getAppropriateTxt(getAppropriateBI(att)).substring(getAppropriateTxt(getAppropriateBI(att)).indexOf('#'), getAppropriateTxt(getAppropriateBI(att)).indexOf('#') + 7)} />
              </div>
              <div style={{flex:1}}>
              <p style={{paddingLeft:'20px'}} className={`font-semibold text-lg ${getAppropriateTxt(getAppropriateBI(att))}`}>
                {editable?att:getAppropriateBI(att)}
              </p>
              </div>
              </div>
              {editable && <Slider value={att} 
                style={{width:'70%'}}
                max={100}
                steps={10}
                onChangeCommitted={handleSliderChangeCommittedProb}
                onChange={handleSliderChangeProb} aria-labelledby="continuous-slider" />}
            </div>
            <div className="space-y-1">
              <p className="text-[13px] text-[#888888]">Ease of Attack</p>
              <p className={`font-semibold text-lg dark:text-[#E6E0E9]`}>
                {editable?threat?.attacker_cost:getAttackerCostLabel(threat?.attacker_cost)}
              </p>
            </div>
            <div className="space-y-1`">
              <p className="text-[13px] text-[#888888]">Value of Attack</p>
              <p className={`font-semibold text-lg dark:text-[#E6E0E9]`}>
                {editable?threat?.assets_values[0]:getAssetValueLabel(threat?.assets_values[0])}
              </p>
            </div>
            {/* <div
            className={`${
              threat?.uid === match ? "block" : "hidden"
            }`}
          >
            
            </div> */}
            <div
              onClick={() => setMatch(threat?.uid)}
              className={`text-[#843ECA] cursor-pointer flex space-x-2 items-center ${
                threat?.uid !== match ? "" : "hidden"
              }`}
            >
              <p className="font-semibold text-lg underline">Read more</p>
              <div className="mt-1">
                <Arrow />
              </div>
            </div>
          </div>
         
          <div
            className={`${
              threat?.uid === match ? "block" : "hidden"
            } mt-2 space-y-3`}
          >
             <div className="space-y-1">
              <p className="text-[13px] text-[#888888]">Description</p>
              <p className="text-[#2B2B2B] dark:text-[#E6E0E9] font-normal lg:text-base text-sm">
                {threat?.description}
              </p>
          </div>
          <p className="text-[13px] text-[#888888]">Facts</p>
        <div className={`${threat?.uid === match ? "block" : "hidden"}`}>
          <ul className="list-disc text-[#2B2B2B] dark:text-[#E6E0E9] font-normal text-base pl-[20px]">
            {threat?.steps.map((obj, index) => {
              if (index > 1) return null; 
              return (
                <li key={index}>
                  {obj}
                </li>
              );
            })}
          </ul>
        </div>
          </div>

          <div
            className={`${
              threat?.uid === match ? "block" : "hidden"
            } space-y-3 mt-2`}
          >
            
            <div className={`${threat?.uid === match ? "block" : "hidden"}`}>
            <ul className="list-disc text-[#2B2B2B] dark:text-[#E6E0E9] font-normal text-base pl-[20px]">
             {threat?.steps.map((obj,_)=>{
              if(_<=1)return null
              return (<li>
                {obj}
              </li>)
             })}
            </ul>
          {editable && <>
          <div className="text-[#2B2B2B] dark:text-[#E6E0E9]">
            <MultiSelect options={systems} title={'Systems'} values={systemsVals} updateValues={setSystemsVals}/>
          </div> 
          <div className="text-[#2B2B2B] dark:text-[#E6E0E9]">
            <MultiSelect options={people} title={'People'} values={peopleVals} updateValues={setPeopleVals}/>
          </div> 
          <div className="text-[#2B2B2B] dark:text-[#E6E0E9]">
            <MultiSelect options={assets} title={'Assets'} values={assetsVals} updateValues={setAssetsVals}/>
          </div> 
          </>}
          </div> 
          {editable && <div style={{textAlign:'center'}}>        
        <button  onClick={()=>handleValuesSave()} className="w-40 text-sm font-semibold text-white rounded-3xl h-10 dark:bg-white dark:text-black bg-[#1F0048]">
                Save 
        </button>
        </div>}
         
          </div>
         
        </div>
       
       
        <div
          onClick={() => setMatch(-1)}
          className={`text-[#843ECA] cursor-pointer flex space-x-2 items-center ${
            threat?.uid === match ? "lg:hidden block" : "hidden"
          }`}
        >
          <p className="font-semibold text-lg underline">Read Less</p>
          <img src={less} alt="" />
        </div>
        {editable && <Slider value={parseInt(intensity)} 
                style={{width:'80%'}}
                max={100}
                onChangeCommitted={handleSliderChangeIntensityCommited}
                onChange={handleSliderChangeIntensity} aria-labelledby="continuous-slider" />}
      </div>
      

      <div
  className={`w-full absolute rounded-[16px] h-full backdrop-blur-md bg-white/40 top-0 ${
    (value === 0 || value === 1) ? "" : "hidden"
  }`}
>
<div

style={{paddingRight:'20px'}}
          className={`flex mt-2 relative w-full ${
            threat?.uid === match ? "md:justify-center justify-end" : "justify-end"
          }`}
        >
         <button
              className={`rounded-lg md:text-sm ${
                threat?.uid === match ? "px-16 py-3" : "md:px-4 py-1 px-2 md:py-2"
              } text-xs text-white ${getAppropriateBg(getStaus(parseInt(intensity)))}`}
          >
            {editable?parseInt(intensity):getStaus(parseInt(intensity))}
          </button>
          <button
            onClick={() => setMatch(-1)}
            className={`h-11 text-[#2B2B2B] ${
              threat?.uid === match ? "md:flex hidden" : "hidden"
            } dark:bg-[#322E38] dark:text-white cursor-pointer absolute right-0 flex justify-center items-center bg-[#F1EEF4] rounded-full w-11`}
          >
            <Cancel />
          </button>
        </div>
  <div className="flex justify-center items-start w-full h-full">
    <div className="text-left w-full p-4">
    <div className="flex space-x-3">
    <div className="mt-3 space-y-3">
              <h1 className="font-semibold text-lg md:text-xl dark:text-[#F9F6FB]">
                {threat?.name}
              </h1>
            </div>
            </div>
            <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center">
      <img
        style={{ cursor: 'pointer', width: '38px', height: '38px' }}
        src={lock}
        alt="unlock"
      />
      <a
        onClick={async(e) => {
          if (value === 0) {
            let x = await unlockCard([threat?.uid], 'attacks');
            if(x){
              setValue(1);
              setStatusCheck(!statusCheck)
            }
          
          }
        }}
      >
        <h1
          style={{ cursor: 'pointer' }}
          className="text-[#843ECA] underline font-bold text-[20px]"
        >
          {value === 0 ? "Unlock" : "Unlocking in Progress"}
        </h1>
      </a>
    </div>
    </div>
  </div>
</div>
    </div>
  );
}


// Business impact section
// Cost to fix
// Attractiveness to attacker