import React, { useState, useEffect} from "react";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
  useParams} from "react-router-dom";
import CustomReportViewer from "./CustomReportViewer";
import TrustCenter from '../components/TrustCenter/TrustCenter'
import SearchScreen from "./SearchScreen";

import PublicReportViewer from "./PublicReportViewer";
import { Outlet,Navigate } from "react-router";
import LoginPageKinde from "./LoginPageKinde";
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import CompanyPg from "./CompanyPg";
import KindeProviderWrapper from './KindeProviderWrapper'; // Import the wrapper
import GlobalData from "../components/GlobalData";




// const RedirectComponend = () => {
//   const { user, login, isAuthenticated, isLoading } = useKindeAuth();
//   const navigate = useNavigate();




//   useEffect(() => {
//     console.log('Redirect component called', { isLoading, isAuthenticated, user });

//     if (!isLoading) {
//       if (isAuthenticated) {
//         navigate('/search-companies', { state: { email: user?.email }, replace: true });
//       } else {
//         if(isLoading)
//         login();
//       }
//     }
//   }, [isLoading, isAuthenticated, user]);

//   return null;
// };


const MiddleWare = () =>{
  const navigate = useNavigate();
  const { n,company } = useParams();
  React.useEffect(() => {
    // Remove email from URL and redirect to /search-companies

    if(n==="search"){
      navigate('/search-companies');
    }
    else{
      navigate('/v/'+company);
    }
  }, []);
  return (<></>)
}

function HideEmailRoute() {
  const navigate = useNavigate();
  const { email } = useParams();
  console.log(email,'emaill')

  React.useEffect(() => {
    // Remove email from URL and redirect to /search-companies
    
    console.log(email,'email')
    navigate('/search-companies', { state: { email }, replace: true });
  }, [email, navigate]);

  return (<></>);
}


function AppRouter() {
  
  const { user, login, isAuthenticated, isLoading } = useKindeAuth();
  const [status,setStatus]=useState(undefined)
 


  useEffect(() => {
    console.log('Redirect component called', { isLoading, isAuthenticated, user });

    if (!isLoading) {
      if (isAuthenticated) {
        setStatus(true);
        // navigate('/search-companies', { state: { email: user?.email }, replace: true });
      } else {
        
        // login({
        //   app_state: {
        //     redirectTo: "search"
        //   }}
        // );
      }
    }
  }, [isLoading,isAuthenticated,user]);

  return (
    <Router>
      <div id="page-container">
        <div className="App">
             
        <Routes>
          <Route
            path="*"
            element={
              <KindeProviderWrapper>
              <GlobalData>
                <Routes>
                  <Route path="/trust-center" element={<TrustCenter />} />
                  <Route path="/reports/:email" element={<HideEmailRoute />} />
                  <Route path="/middleware/:n/:company" element={<MiddleWare />} />
                  <Route path="/search-companies" element={<SearchScreen email={user?.email} />} />
                  <Route path="/v/:magicId" element={<CustomReportViewer />} />
                </Routes>
                </GlobalData>
              </KindeProviderWrapper>
            }
          />
        </Routes>
        </div>
      </div>
    </Router>
  );
}

export default AppRouter;

// clientId="72d6d9f04bd7418c8257ddd024b3277c"
// domain="https://x80security.kinde.com"


// clientId="246374565bad47fcb97bec061be284d1"
// domain="https://x80test.kinde.com"