import React ,{ useState ,useEffect}  from 'react'
import GlobalSearchBox from '../components/GloabSearchBox';
import GoogleAuth from '../components/GoogleAuth';
import Navbar from '../components/Navbar/Navbar';
import LoginComponent from "../components/LoginComponent";
import DataNotAvailableScreen from "../components/DataNotAvailable";
import {useParams,useNavigate,useLocation} from 'react-router-dom'
import {gapi} from 'gapi-script'
import Backdrop from '@mui/material/Backdrop';
import SearchBar from '../components/SearchBar';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import CompanyTable from './CompanyPg';
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import Tooltip from '@mui/material/Tooltip';
import Tabs from '@mui/material/Tabs';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import MyReports from './MyReports';
import useGlobalContext from '../hooks/useGlobalContext';


const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .MuiTooltip-tooltip`]: {
    backgroundColor: 'white',
    color: 'black',
    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
  },
});

const SearchScreen = (props) => {

    const [isLoggedIn,setIsLoggedIn]=useState(false);
    const [email,setEmail]=useState()
    const [isGlogin,setIsGlogin]=useState(false);
    const [companyName,setCompanyName]=useState('');
    const [errorScreen,setErrorScreen]=useState(false);
    const [open, setOpen] = React.useState(true);
    const [options,setOptions]=useState([])
    const [reportOpts,setReportOpts]=useState([]);
    const [searchTerm,setSearchTerm]=useState('');
    const [msg,setMsg]=useState('Checking your session...');
    const {user,login,register,isAuthenticated,isLoading,getToken}=useKindeAuth()
    const location = useLocation();
    const {getCredits}=useGlobalContext()
    const [tab,setTab]=useState("reports")
    const emailId = location.state?.email?location.state?.email:props.email;


    console.log(emailId,'email id')
    const handleClose = () => {
      setOpen(false);
    };
   

    const navigate=useNavigate();
   


    const startDomainScan=async (email,domains)=>{
    
      // try {
      //   let token= await getToken();
      //   const response = await fetch(
      //     "https://scanner.x80security.com/reports/unlockDomains" ,
      //     {
      //       method: "POST",
      //       headers: {
      //         "Content-Type": "application/json",
      //         "Authorization": `Bearer ${token}`,
      //         // Add CORS headers
      //         "Access-Control-Allow-Origin": "*", // Allow requests from any origin
      //         "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS", // Allow specific HTTP methods
      //         "Access-Control-Allow-Headers": "Content-Type, Authorization", // Allow specific headers
      //         "Access-Control-Allow-Credentials": true // Allow credentials (cookies, authorization headers, etc.)
      //     },
      //       body:JSON.stringify({
        
      //         domain_names:domains
      //       })
      //     },
      //   );
      //   let data = await response.json();
      //   console.log(data,'data');
      //   setMsg('Checking your session...')
      // } catch (error) {
      //  console.log(error); 
      // }

    }

    const checkIfCompanyExists=async ()=>{
      if(!companyName) return;
      let token= await getToken();
      const response = await fetch(
        "https://scanner.x80security.com/reports/getDomainReport" ,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
            // Add CORS headers
            "Access-Control-Allow-Origin": "*", // Allow requests from any origin
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS", // Allow specific HTTP methods
            "Access-Control-Allow-Headers": "Content-Type, Authorization", // Allow specific headers
            "Access-Control-Allow-Credentials": true // Allow credentials (cookies, authorization headers, etc.)
        },
          body:JSON.stringify({
            domain_name:companyName
          })
        },
      );
    
      let data = await response.json();
      console.log(data,'response');
      if(!data.result?.available){
        setOpen(false)
        setErrorScreen(true);
      }
      else{
        setOpen(false)
        navigate('/v/'+data.result.magic_id);
      }
      setMsg('Checking your session...')
    }
const checkStatus=async ()=>{
      let token=localStorage.getItem('token')
      try {
        const response = await fetch('https://scanner.x80security.com/auth/checkSession', {
          method: 'GET',
          headers: {
              "Content-Type": "application/json",
              "Authorization": `Bearer ${token}`,
              // Add CORS headers
              "Access-Control-Allow-Origin": "*", // Allow requests from any origin
              "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS", // Allow specific HTTP methods
              "Access-Control-Allow-Headers": "Content-Type, Authorization", // Allow specific headers
              "Access-Control-Allow-Credentials": true // Allow credentials (cookies, authorization headers, etc.)
          }
        });
        // Handle response from the backend
        const responseData = await response.json();
        console.log(responseData,'logging response data')
        let value=responseData.success;
        if(!value){
          localStorage.removeItem('email')
          localStorage.removeItem('token')
        }
        return value;
      } catch (error) {
        localStorage.removeItem('email')
        localStorage.removeItem('token')
        return false;
      }

    }
    useEffect(()=>{
      if(isLoading) return;
     console.log(isLoading,user,isAuthenticated)
      if(isAuthenticated){
        setIsLoggedIn(true);
        startDomainScan(emailId,[]);
        setEmail(user.email);
        
        checkIfCompanyExists();
        if(msg==='Checking your session...')
        setOpen(false)
      }else{
        login({
      authUrlParams: {
        login_hint: emailId,
        lang: "en"
      },
      app_state: {
        redirectTo: "search-companies"
      }
    })
      }
    },[isLoading,companyName])
    
    const isValidDomainName = (domain) => {
      const regex = new RegExp(
        '^(https:\\/\\/www\\.|http:\\/\\/www\\.|https:\\/\\/|http:\\/\\/)?[a-zA-Z]{2,}(\\.[a-zA-Z]{2,})(\\.[a-zA-Z]{2,})?\\/[a-zA-Z0-9]{2,}|((https:\\/\\/www\\.|http:\\/\\/www\\.|https:\\/\\/|http:\\/\\/)?[a-zA-Z]{2,}(\\.[a-zA-Z]{2,})(\\.[a-zA-Z]{2,})?)|(https:\\/\\/www\\.|http:\\/\\/www\\.|https:\\/\\/|http:\\/\\/)?[a-zA-Z0-9]{2,}\\.[a-zA-Z0-9]{2,}\\.[a-zA-Z0-9]{2,}(\\.[a-zA-Z0-9]{2,})?$'
      );
      // return regex.test(url);
      return regex.test(domain);
    };

    const fetchSearchResults=async (prefix)=>{
      let token= await getToken();
      let reportsApi='https://scanner.x80security.com/reports/getSearchResults/'+prefix+'?only_reports=true';
      let searchApi='https://scanner.x80security.com/reports/getSearchResults/'+prefix;
      let url=searchApi;
      if(tab==='reports'){
       url=reportsApi;
      }
      const response = await fetch(url,
      {
        method: 'GET',
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${token}`,
          // Add CORS headers
          "Access-Control-Allow-Origin": "*", // Allow requests from any origin
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS", // Allow specific HTTP methods
          "Access-Control-Allow-Headers": "Content-Type, Authorization", // Allow specific headers
          "Access-Control-Allow-Credentials": true // Allow credentials (cookies, authorization headers, etc.)
        }
      });
      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }
      let data = await response.json();
      if(data?.result)
      {
        if(tab==='reports'){
          setReportOpts(data.result)
        }
        else{
          setOptions(data.result);
        }
      }

      console.log(data.matches,'matches');

  }


    
    useEffect(() => {
   
        const debounce = (func, delay) => {
          let timeoutId;
          return function (...args) {
            clearTimeout(timeoutId);
            timeoutId = setTimeout(() => func.apply(this, args), delay);
          };
        };
    
        
    
        // Call fetchData function with debounced input value
        const debouncedFetchData = debounce(fetchSearchResults, 300);
        debouncedFetchData(searchTerm);
    
        // Cleanup function


        
        return () => clearTimeout(debouncedFetchData);

       
      }, [searchTerm,tab]);

      useEffect(()=>{
        if(!isLoading)
        fetchSearchResults('')
      },[isLoading])

  
   
  

  const onFailure=(res)=>{
  
    console.log("LOGIN FAILURE! Current user: ",res)
}
    


  return (
    <>
      <Navbar setErrorScreen={()=>{setErrorScreen(false)}} hideSearch={true} fr={true} />
     
    {isLoggedIn && !errorScreen && !open && <div className=' pt-[100px] flex flex-col items-center justify-center'>
    <div>
    <CustomTooltip title="X80 (pronounced X-Eighty) finds risks in your systems, people and those of your suppliers. Try enter one of your domain names or one of your suppliers' domain name to try it out" placement="right-start">  
    <img className='h-[65px] lg:h-[127px]' src='/images/xd.svg' alt=''/>
    </CustomTooltip>
      </div>
<div className='relative w-full lg:w-[900px] text-center mt-11 lg:mt-6'>
  {/* <img className='absolute top-3 left-5 lg:left-[12rem] lg:top-4 md:left-16' src='/images/search.svg' alt=''/> */}
  {/* <input  type='text' placeholder='Domain Name, Company Name, URL...'></input> */}
  <div className='pt-[10px] flex flex-col items-center justify-center'>

  {/* <GlobalSearchBox onCompanySelect={(companyName)=>{
    setCompanyName(companyName)
    setOpen(true)
    setMsg('Fetching details for '+companyName+' ...')
  }} /> */}
 <div style={{ width: "65%" }}>
      <SearchBar
      searchValue={searchTerm}
      handleClear={(()=>{
        setSearchTerm('');
      })}
      searchText={"Company Domain (your-domain.com)"}
      handleOnChange={(e) => {
        if(e.target.value===''){
          setOptions([])
        }
        setSearchTerm(e.target.value)}} />
    </div>
  </div>
  
 

  {/* <img className='absolute top-[12px] right-[49px] lg:top-4 lg:right-[15rem] h-[30px]' src='/images/pdf.svg' alt=''/>
  <img className='absolute top-[12px] right-[16px] lg:top-4 lg:right-[12rem] h-[30px]' src='/images/arrow.svg' alt=''/> */}
</div>

    </div>}

    {/* <Tabs
        value={tab}
        onChange={(e,newVal)=>{
          setTab(newVal);
        }}
        textColor="secondary"
        indicatorColor="secondary"
        aria-label="secondary tabs example"
      >
        <Tab value={"search"} label="Search Results">
        <div style={{paddingLeft:'8%',paddingRight:'8%'}}>
          <CompanyTable unlockReport={(domain)=>{
          startDomainScan(user.email,[domain]);
          }} dataa={options}/>
        </div>  
        </Tab>
        <Tab value={"reports"} label="My Reports">
       
        </Tab>
      </Tabs> */}
      <TabContext value={tab}>
        <Box>
          <TabList 
            textColor="secondary"
            indicatorColor="secondary"
            sx={{'.MuiTab-root': {
              fontWeight: 'bold',
              textTransform: 'none', 
              color: 'grey', 
              fontSize:'1.1rem    '
            },
            '.Mui-selected': {
              color: '#4B0082', // Ensure selected tab is also dark purple
            } }}
          style={{paddingLeft:'2%',paddingRight:'2%'}}  onChange={(e,newVal)=>{
          setTab(newVal);
        }} aria-label="lab API tabs example">
          <Tab value={"search"} label={"Search Results "+((options?.length && tab==="search")?`( ${options?.length} )`:'')} />
          <Tab value={"reports"} label={"My Reports "+((reportOpts?.length && tab==="reports")?`( ${reportOpts?.length} )`:'')} />
          </TabList>
        </Box>
        <TabPanel value="search">
        <div style={{paddingLeft:'2%',paddingRight:'2%'}}>
          <CompanyTable
          
          unlockReport={(domain)=>{
          startDomainScan(user.email,[domain]);
          }} dataa={options}/>
        </div>  
        </TabPanel>
        <TabPanel value="reports">
        <div style={{paddingLeft:'2%',paddingRight:'2%'}}>
          <MyReports unlockReport={(domain)=>{
          startDomainScan(user.email,[domain]);
          }} dataa={reportOpts}/>
        </div>  
        </TabPanel>
      </TabContext>


  

   </>
  )
}

export default SearchScreen




