import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import bcrypt from 'bcryptjs';
import {useKindeAuth} from '@kinde-oss/kinde-auth-react';



const defaultTheme = createTheme();

export default function LoginComponent(props) {
  const { login, register,logout } = useKindeAuth();

  React.useEffect(()=>{
    // login({
    //   authUrlParams: {
    //     login_hint: "t.ghone@x80security.com",
    //     lang: "en"
    //   }
    // })
  })
  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email= data.get('email');
    const password=data.get('password');

    

    // const encryptedPassword = await bcrypt.hash(password, 10);
    const response = await fetch('https://scanner.x80security.com/auth/checkLogin', {
        method: 'POST',
        headers: {
            "Content-Type": "application/json",
            "Authorization": "c175c76ad0acdb75360a38c3e4d3f04c45874b1b00b7b855",
            // Add CORS headers
            "Access-Control-Allow-Origin": "*", // Allow requests from any origin
            "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS", // Allow specific HTTP methods
            "Access-Control-Allow-Headers": "Content-Type, Authorization", // Allow specific headers
            "Access-Control-Allow-Credentials": true // Allow credentials (cookies, authorization headers, etc.)
        },
        body: JSON.stringify({ email_address:email, password: password }),
      });

     
  
      // Handle response from the backend
      const responseData = await response.json();
      localStorage.setItem("email",email)
      localStorage.setItem("token",responseData.token);
      props.setIsLoggedIn(responseData.success,email);
  };

  return (
    <div style={{margin:'auto'}}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in for report
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
           
            <Button
              // type="submit"
              // fullWidth
              // variant="contained"
              // sx={{ mt: 3, mb: 2 }}
              onClick={register}
            >
              Sign Up 
            </Button>
            <Button
              // type="submit"
              // fullWidth
              // variant="contained"
              // sx={{ mt: 3, mb: 2 }}
              onClick={login}
            >
              Sign In 
            </Button>

            <button onClick={logout} type="button">Sign out</button>
        
          </Box>
        </Box>
    </div>    
  );
}
