import { IoMdClose } from "react-icons/io";
import CircularProgressBar from "./ProgressBar";
import { useState,useEffect } from "react";
import SliderComponent from "./Slider";
import useGlobalContext from "../hooks/useGlobalContext";
import { orderReport } from '../api';
import {useKindeAuth} from "@kinde-oss/kinde-auth-react";
import Snackbar from '@mui/material/Snackbar';

const CustomModal = ({ isOpen, onClose,company ,onSuccess}) => {
  const [value, setValue] = useState(0);
  const [open,setOpen]=useState(false)
  const [msg,setMsg]=useState('')
  const [disable,setDisable]=useState(false);
  const [customPPl,setCustomPPl]=useState(0);
  const [customSys,setCustomSys]=useState(0);
  const [customSc,setCustomSc]=useState(0);
  const [totalCreds,setTotalCreds]=useState(0);
  const [displayCreds,setDisplayCreds]=useState({
    0:{
      people:3,
      systems:3,
      third_parties:1
    },
    1:{
      people:3,
      systems:3,
      third_parties:1
    },
    2:{
      people:3,
      systems:3,
      third_parties:1
    },
    3:{
      people:3,
      systems:3,
      third_parties:1
    }
  });

  const array = ["People Scans", "System Scans", "Supply Chain Scans"];
  const {credits,getCredits}=useGlobalContext();
  const {getToken,user}=useKindeAuth()

  const handleClose=()=>{
    setOpen(false);
  }
  const unlockVals=[
    {
      ppl:"3",
      sys:"3",
      sc:"1"
    },
    {
      ppl:"20",
      sys:"10",
      sc:"5"
    }
  ]
  const callGetCredits=async()=>{
    let result= await getCredits();
    
   }

   useEffect(()=>{

    let total=0;
    
      total=displayCreds[value].people*credits?.pricing?.people + displayCreds[value].systems*credits?.pricing?.systems+ displayCreds[value].third_parties*credits?.pricing?.third_parties + credits?.pricing?.domain_access;
   
    if(value===2){
      total=Math.min(customPPl,displayCreds[value].people)*credits?.pricing?.people +  Math.min(customSys,displayCreds[value]?.systems)*credits?.pricing?.systems+ Math.min(customSys,displayCreds[value]?.third_parties)*credits?.pricing?.third_parties + credits?.pricing?.domain_access;
    }
    setTotalCreds(total);
    if(total>credits?.remaining_credits){
      setDisable(true)
    }
    else
    setDisable(false)

   },[value,customPPl,customSc,customSys])

   const orderFullReport= async(bdy)=>{
    let token=await getToken();
    let response=await orderReport(company,value,token,bdy);
    if(response?.success){
      onSuccess();
      setMsg("Order Report Successfull!");
      setOpen(true)
      setDisable(false);
      setTimeout(()=>{
        setOpen(false);
        onClose();
      },[5000])
      callGetCredits();
    }
    else{
      setMsg(response.result);
      setDisable(false);
      setOpen(true);
    }
    setDisable(false);


    // onClose();

   }

   const getCreditCost = async ()=>{
    let token=await getToken();

    try {
      const response = await fetch(
        "https://scanner.x80security.com/reports/getOrderReportCost" ,
        {
          method: "POST",
              headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`,
                // Add CORS headers
                "Access-Control-Allow-Origin": "*", // Allow requests from any origin
                "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS", // Allow specific HTTP methods
                "Access-Control-Allow-Headers": "Content-Type, Authorization", // Allow specific headers
                "Access-Control-Allow-Credentials": true // Allow credentials (cookies, authorization headers, etc.)
            },
          body:JSON.stringify({
            domain_name:company
          })
        },
      );
    
      let data = await response.json();
      console.log(data,'dataaaa');
      setDisplayCreds(data.result);
    } catch (error) {
      console.log(error);
    }
   }

   useEffect(()=>{
    

     getCreditCost(); 
     callGetCredits();
 
    
   },[company])

   const getApprValue=(index)=>{
    if(value===2){
      if(index===0) return Math.min(customPPl,displayCreds[value]?.people);
      else if(index==1) return Math.min(customSys,displayCreds[value]?.systems)
      return Math.min(customSc,displayCreds[value]?.third_parties);
    }
    console.log(displayCreds,'disp')
    if(displayCreds){
      if(index===0) return displayCreds[value]?.people;
      else if(index==1) return displayCreds[value]?.systems
      return displayCreds[value]?.third_parties;
    }
  
   }

  const number = 50;
  if (!isOpen) return null;
  return (
    <div className="w-full h-screen backdrop-blur-md fixed top-0 left-0 flex items-center justify-center">
    <div className="w-[700px] h-[500px] bg-white shadow-md px-6 py-4 rounded-xl space-y-6">
      <div className="flex justify-between relative">
        <div className="flex gap-2 items-center text-xs font-semibold">
          <CircularProgressBar value={credits?.remaining_credits ? credits.remaining_credits : 0} />
          <p>Available Credits</p>
        </div>
        <IoMdClose
          className="cursor-pointer text-lg transition-colors hover:text-red-600 absolute top-0 right-[-.5rem]"
          onClick={onClose}
        />
      </div>
      <p className="text-3xl font-bold text-center">
        Order Report for {company}
      </p>
      <div className="px-3 pt-2">
        <h4 className="text-lg font-bold">Select the depth of the report</h4>
        <p className="text-gray-500 text-xs w-[90%]">
          The depth you choose determines the number of scans for people,
          systems, and supply chain parameters included in the report.
        </p>
      </div>
      <div>
        <SliderComponent value={value} onChange={setValue} />
      </div>
      <div className="px-3 flex gap-1 py-4">
        {array.map((item, index) => (
          <div
            key={index}
            className="w-[33%] flex justify-between p-1 text-sm text-gray-500 border border-gray-200 rounded-md"
          >
            <p className="text-xs">{item}:</p>
            <input
              className="font-bold text-xl text-accent"
              style={{
                border: 'none',
                outline: 'none',
                padding: '0',
                backgroundColor: 'transparent',
                width: '100px',
                textAlign: 'right',
              }}
              value={getApprValue(index)}
              // value={inputValues[index]}
              onChange={(e) => {
                let val=e.target.value;
                if(index===0){
                  setCustomPPl(val)
                }
                else if(index===1){
                  setCustomSys(val)
                }
                else setCustomSc(val);
              }}
              readOnly={value===2?false:true}
            />
          </div>
        ))}
      </div>
      <div className="flex justify-center items-center">
        <button 
          disabled={disable}
          onClick={() => {
            let ppl=customPPl
            let sys=customSys
            let sc=customSc;
            
            if(value===0){
              ppl=displayCreds["0"].people
              sys=displayCreds["0"].systems
              sc=displayCreds["0"].third_parties;
            }
            else if(value===1){
              ppl=displayCreds["1"].people
              sys=displayCreds["1"].systems
              sc=displayCreds["1"].third_parties;
            }
            let bdy={
              requested_people: ppl,
              requested_systems: sys,
              requested_third_parties: sc,
              domain_name: company
            }
            orderFullReport(bdy);
            setDisable(true);
          }}
          className="md:w-[256px] w-[217px] space-x-3 font-semibold md:text-lg text-base dark:bg-[#F9F6FB] dark:text-[#2B2B2B] h-[49px] flex justify-center items-center rounded-[40px] bg-[#1F0048] text-white"
        >
          <a href="#unlock">
            <p>{(totalCreds>credits?.remaining_credits)?`You need ${totalCreds-credits?.remaining_credits} more credits`:`Unlock for ${totalCreds} credits`}</p>

          </a>
        </button>
      </div>
      <p className="text-center text-sm">
        You have <span className="font-bold">{credits?.remaining_credits ? credits.remaining_credits : 0}</span> available credits.
        <span
          onClick={() => {
            let b64_email=btoa(user.email)
           let url=`https://buy.stripe.com/test_14k4gh9oK7ZhczmbII?client_reference_id=${b64_email}&prefilled_email=${user.email}`
            window.open(url, "_blank");
          }}
          className="text-accent underline cursor-pointer font-semibold"
          style={{marginLeft:'5px'}}
        >
          Upgrade now
        </span>
      </p>
    </div>
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={open}
      onClose={handleClose}
      message={msg}
    />
  </div>
  );
};

export default CustomModal;
